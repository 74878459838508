import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import TopNav from './topNav'
import RefreshWarning from './refreshWarning'
import ListView from './listView'
import ItemView from './itemView'
import NewView from './newView'
import ActionView from './actionView'
import DeletionView from './deletionView'
import BulkDeleteView from './bulkDeleteView'
import MergeView from './mergeView'
import LeftNav from './leftNav'
import Dashboard from './dashboard'
import Docs from './docs'
import { ConfigContext } from '../contexts/ConfigContext'
import { UIContext } from '../contexts/UIContext'
import { LoginContext } from '../contexts/LoginContext'
import Error from '../containers/Error'
import Login from '../containers/login'

const AppRouter = () => {

    const { 0: config } = useContext(ConfigContext)
    const { menuOpen } = useContext(UIContext)
    const { 0: login } = useContext(LoginContext)
    
    if(config.niceName) document.title = config.niceName

    return <Router>
        {/* bad style tag for dynamic styles */}
        
        <style dangerouslySetInnerHTML={{__html: `

        .tabs > .menu .active.item, .tabs > .menu .active.item::after {
            background:${config.secondaryColour} !important;
        }

        `}}></style>

        <div className="App">
            {!Object.keys(config.models).length ? <div>No config loaded</div> :
                <>
                    {config.theme?<link rel="stylesheet" type="text/css" href={`http${config.env=='production'?'s':''}://${config.apiHostName}/themes/${config.theme}.css`} />:null}
                    <Error />

                    {!login ? <Login />
                        :
                        <>
                            {config.clientVersion < config.serverExpectedClientVersion?<RefreshWarning />:null}
                            <TopNav />
                            <LeftNav />
                            <NewView />
                            <ActionView />
                            <DeletionView />
                            <BulkDeleteView />
                            <MergeView />
                            <div className={`mainContent ${menuOpen ? 'menuOpen' : 'menuClosed'}`}>

                                <Switch>
                                    <Route path="/docs" exact>
                                        <Docs />
                                    </Route>

                                    <Route path="/dashboards/:dashboardName" exact render={routeProps => {
                                        return <Dashboard key={routeProps.match.params.dashboardName}/>
                                    }} />

                                    <Route path={["/:modelName","/:modelName/filter/:filterFromUrl"]} exact render={routeProps => {
                                        return <ListView key={routeProps.match.params.modelName}/>
                                    }} />
                                        
                                    <Route path="/:modelName/:itemId" render={routeProps => {
                                        return <ItemView key={routeProps.match.params.modelName}/>
                                    }} />

                                    <Route path="/" render={routeProps => {
                                        //find the first dash this user can see and should default to
                                        if(config.dashboards)
                                            for(let dashboardKey of Object.keys(config.dashboards)){
                                                let d = config.dashboards[dashboardKey]
                                                if(!d.clientPermission || d.clientPermission({login})===true){
                                                    if(d.default && d.default({login})===true){
                                                        return <Redirect to={`/dashboards/${dashboardKey}`} />
                                                    }
                                                }
                                            }
                                    }} />
                                </Switch>
                            </div>
                        </>
                    }

                </>
            }
        </div>
    </Router>
}

export default AppRouter