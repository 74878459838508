import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Icon, Button, Search, Label } from 'semantic-ui-react'
import { ConfigContext } from '../../contexts/ConfigContext'
import { LoginContext } from '../../contexts/LoginContext'
import useFetch from '../../hooks/useFetch'
import { useParams, Link, useHistory } from "react-router-dom"
import { mode } from 'crypto-js'

const GlobalSearch = () => {

    const [config] = useContext(ConfigContext)
    let [searchValue, setSearchValue] = useState()
    let [results, setResults] = useState()
    let [loading, setLoading] = useState(false)
    let [searchDebounce, setSearchDebounce] = useState()
    let { runFetch } = useFetch()

    let history = useHistory()

    const searchChangeHandler = (e) => {

        setResults(null)

        let value = e.target.value

        setSearchValue(value)

        clearTimeout(searchDebounce)

        if(value){
            setSearchDebounce(setTimeout(() => {
                onSearch(value)
            }, 600))
        }
    }


    
    const onSearch = async value => {
        setLoading(true)

        let fetchOptions = {
            queryString: {
                search:value
            }
        }
    
        let apiPath = `/global-search`
    
        let res = await runFetch(apiPath, fetchOptions)

        let resultsToRender = {}

        for(let modelName of Object.keys(res)){
            let model = config.models[modelName]

            resultsToRender[modelName] = {
                results: res[modelName].results.map(r=>({
                    title: model.referToByString(r),
                    modelname: modelName,
                    objectId: r.id
                })),
                icon: model.icon,
                name: model.niceNamePlural
            }
        }

        setResults(resultsToRender)
        setLoading(false)
    }

    const onResultSelect = (e, data)=>{
        setSearchValue('')
        setResults(null)
        history.push(`/${data.result.modelname}/${data.result.objectId}`)
    }

    //get all searchable models
    let searchableModels = []
    for(let modelKey of Object.keys(config.models)){
        let model = config.models[modelKey]
        if(model.globalSearch) searchableModels.push(model)
    }

    if(searchableModels.length == 0) return null

    return <div>
        <Search 
            placeholder='Search XView'
            category
            size='mini'
            onSearchChange={searchChangeHandler}
            value={searchValue}
            results={results}
            onResultSelect={onResultSelect}
            re
            loading={loading}
            open={results?true:false}
            className='globalSearch'
            onBlur={(e,data)=>{setResults(null); setSearchValue('');}}
            //showNoResults={false}
            selectFirstResult={true}
            //categoryLayoutRenderer={categoryLayoutRenderer}
            categoryRenderer={({ name, icon }) => <span><Icon name={icon} />{name}</span>}
            resultRenderer={(result) => {
                return (<div>{result.title} <a target='_blank' onClick={(e)=>{e.stopPropagation()}} href={`/${result.modelname}/${result.objectId}`}><Icon name='linkify' /></a></div>)      
            }}
        />
     </div>
}

export default GlobalSearch