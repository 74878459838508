import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import { Dropdown, Modal, Message, Header, Icon, Table as STable, Button, Label, Popup, Checkbox } from 'semantic-ui-react'
import { useParams, Link, useHistory } from "react-router-dom"
import { useForm } from "react-form";
import { ConfigContext } from "../../contexts/ConfigContext"
import { CodeArea, DurationField, TimeField, InputField, DateField, DateTimeField, LookupField, RemoteSelectField, TextArea, HtmlField, CheckBoxField, MultiSelectLookupField, SelectField, RemoteMultiSelectField } from "../../components/formFields"
import { UIContext } from '../../contexts/UIContext'
import { LoginContext } from '../../contexts/LoginContext'
import useFetch from '../../hooks/useFetch'
import useTransformValues from '../../hooks/useTransformValues'

const GetInputField = ({ formLoading, everSubmitted, field, model, validation, transformValues, editRecord, parentRecord, parentModel}) => {

    let defaultMeta = { fieldConfig: field, model, isBlured: false, hidden: false, editRecord, parentRecord, parentModel }

    if(!field.required&&!field.clientValidation&&!field.serverValidation) validation = null
    
    switch (field.fieldType) {
        case 'lookup':
             return <LookupField formLoading={formLoading} defaultMeta={defaultMeta} defaultValue={null} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
        case 'remoteSelect':
            return <RemoteSelectField formLoading={formLoading} defaultMeta={defaultMeta} defaultValue={null} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
        case 'remoteMultiSelect':
            return <RemoteMultiSelectField formLoading={formLoading} defaultMeta={defaultMeta} defaultValue={null} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
        case 'multiLookup':
            return <MultiSelectLookupField formLoading={formLoading} defaultMeta={defaultMeta} defaultValue={[]} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
        case 'date':
            return <DateField formLoading={formLoading} defaultMeta={defaultMeta} defaultValue={null} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
        case 'time':
            return <TimeField formLoading={formLoading} defaultMeta={defaultMeta} defaultValue={null} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
        case 'datetime':
            return <DateTimeField formLoading={formLoading} defaultMeta={defaultMeta} defaultValue={null} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted} />
        case 'duration':
            return <DurationField formLoading={formLoading} defaultMeta={defaultMeta} defaultValue={null} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted} />
        case 'text':
            return <TextArea formLoading={formLoading} defaultMeta={defaultMeta} defaultValue='' field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
        case 'code':
            return <CodeArea formLoading={formLoading} defaultMeta={defaultMeta} defaultValue='' field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>      
        case 'html':
            return <HtmlField formLoading={formLoading} defaultMeta={defaultMeta} defaultValue='' field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
        case 'checkbox':
            return <CheckBoxField formLoading={formLoading} defaultMeta={defaultMeta} defaultValue={false} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
        case 'select':
            return <SelectField formLoading={formLoading} defaultMeta={defaultMeta} defaultValue={null} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
        case 'number':
            return <InputField formLoading={formLoading} type='number' defaultMeta={defaultMeta} defaultValue={null} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
        default:
            // return null
            return <InputField defaultMeta={defaultMeta} defaultValue={null} field={field.apiName} validate={validation} transformValues={transformValues} everSubmitted={everSubmitted}/>
    }

}

const EditForm = ({ model, editRecord, onSuccess, parentModel, parentRecord, createFromFormModel, createFromFormRecord, setData}) => {

    const { refreshData, addToEditModalQueue } = useContext(UIContext)
    const { 0: login } = useContext(LoginContext)
    const { 0: config } = useContext(ConfigContext)

    let history = useHistory()

    //list of fields we want hidden to not be visible but stay in the form only edited by useTransformValues
    let [hiddenFields, setHiddenFields] = useState([])

    let [everSubmitted, setEverSubmitted] = useState(false)

    let [loading, setLoading] = useState(false)

    let [afterSave, setAfterSave] = useState('stay')

    

    
    //Save the last used afterSave option
    //if no afterSave Object in local storage, create one...
    if(!localStorage.afterSave) localStorage.afterSave = "{}"
    let afterSaveIndexes = JSON.parse(localStorage.afterSave)

    //save the most recent tab in local storage
    const saveAfterSave = (afterSaveOption)=>{
        afterSaveIndexes[model.name] = afterSaveOption
        localStorage.afterSave = JSON.stringify(afterSaveIndexes)
    }

    useEffect(()=>{
        if(afterSaveIndexes[model.name]){
            setAfterSave(afterSaveIndexes[model.name])
        }
    },[])

    

    //is there a relationship from a parentModel?
    let relationship
    if (parentModel && parentModel.relationships && parentModel.relationships[model.name])
        relationship = parentModel.relationships[model.name]

    let { runFetch } = useFetch()

    //if editing filter out values that aren't 'puttable' 
    //This section was a big problem, if you pass default values to a form, where the object you pass was created in this component, 
    //it creates a horrible loop, this was the only solution I could find
    const initialFormValues = useMemo(() => {

        let initialFormValues = {}
        if(setData) initialFormValues = Object.assign({},setData)

        if (editRecord) {
            for (let fieldKey of Object.keys(editRecord)) {

                let field = model.fields[fieldKey]

                //evaluate put
                let computedPut
                if(field && field.put) 
                computedPut = typeof field.put === 'function'?field.put({login}):field.put

                if (field && computedPut){

                    //if remote multi select, change the array of objects to ids
                    if(field.fieldType == 'remoteMultiSelect'){
                        initialFormValues[fieldKey] = editRecord[fieldKey].map(datum=>{
                            return datum.id || datum
                        })   
                    }
                    //all checkboxes should always be bool
                    else if(field.fieldType == 'checkbox'){
                        initialFormValues[fieldKey] = editRecord[fieldKey]===true?true:false
                    }
                    else initialFormValues[fieldKey] = editRecord[fieldKey]
                }

                
            }
        }

        //If a new object, created from a relationship view (at the bottom of an item), prefill the parent object
        if (!editRecord && relationship && model.fields[relationship.foreignKeyAPIName]){
            let computedForeignKeyAPINamePost = typeof model.fields[relationship.foreignKeyAPIName].post === 'function'?model.fields[relationship.foreignKeyAPIName].post({login}):model.fields[relationship.foreignKeyAPIName].post
            if(computedForeignKeyAPINamePost){
                if(model.fields[relationship.foreignKeyAPIName].type=='relatedList'){
                    initialFormValues[relationship.foreignKeyAPIName] = [relationship.localKeyAPIName?parentRecord[relationship.localKeyAPIName]:parentRecord.id]
                }
                else initialFormValues[relationship.foreignKeyAPIName] = relationship.localKeyAPIName?parentRecord[relationship.localKeyAPIName]:parentRecord.id
            }
        }

        return initialFormValues
    }, [editRecord]);



    const save = async (values) => {

        let valuesToSave = Object.assign({},values) 

        //remove null values
        // for (let valueKey of Object.keys(values)) {
        //     if (values[valueKey] === null) values[valueKey] = undefined
        //     //if numeric, check for empty string 
        //     if (model.fields[valueKey].fieldType == 'number' && values[valueKey] === '') values[valueKey] = undefined
        // }

        //remove UIOnly values && convert related lists
        for (let valueKey of Object.keys(valuesToSave)) { 

            if(!model.fields[valueKey] || model.fields[valueKey].UIOnly === true) delete valuesToSave[valueKey]

            else if(model.fields[valueKey].type=='relatedList'){
                //if we have a value, and it has at least 1 item, and it's an bject (not an id)
                if(valuesToSave[valueKey]&&valuesToSave[valueKey][0]&&valuesToSave[valueKey][0].id){
                    //convert to ids
                    valuesToSave[valueKey] = valuesToSave[valueKey].map(v=>v.id)
                }
            }
        }

        

        const options = {
            method: editRecord ? 'PUT' : 'POST',
            body: JSON.stringify(valuesToSave)
        }

        let path = `/${model.name}`
        if (editRecord) path = `/${model.name}/${editRecord.id}`
        let fetchRes = await runFetch(path, options)

        if (fetchRes) {
            //if editing, always just stay where we are...
            if(editRecord) {
                refreshData()
                if (onSuccess) onSuccess({newObject:fetchRes})
            }

            else if(afterSave == 'stay' || createFromFormModel){
                refreshData()
                if (onSuccess) onSuccess({newObject:fetchRes})
            }
            else if(afterSave == 'goToNew'){
                //if already on page... edi modal bug?)
                if(history.location.pathname == `/${model.name}/${fetchRes.id}`){
                    refreshData()
                    if (onSuccess) onSuccess({newObject:fetchRes})
                }
                else {
                    history.push(`/${model.name}/${fetchRes.id}`)
                    if (onSuccess) onSuccess({newObject:fetchRes})
                }
            }
            else if(afterSave == 'addAnother'){
                if (onSuccess) onSuccess({newObject:fetchRes, addAnother:true})
            }
            else if(afterSave.indexOf('addNew:')>=0){
                //if already on page... edi modal bug?)
                if(history.location.pathname == `/${model.name}/${fetchRes.id}`){
                    refreshData()
                }
                else {
                    history.push(`/${model.name}/${fetchRes.id}`)
                }


                let addAnotherModel = afterSave.split(':')[1]
                addAnotherModel = config.models[addAnotherModel]
                if(onSuccess) onSuccess({newObject:fetchRes, addAnother:true, addAnotherModel:addAnotherModel})
            }
        }

    }

    // Use the useForm hook to create a form instance
    const form = useForm({
        onSubmit: async (values, instance) => {
            return save(values)
        },
        defaultValues: initialFormValues,
        //debugForm: process.env.NODE_ENV == 'development'
    });

    const {
        Form,
        handleSubmit,
        setMeta,
        values,
        setValues,
        meta: { isSubmitting, canSubmit, isValid, fieldsAreValidating }
    } = form

    let { transform: transformValues, transformLoading } = useTransformValues({ model, parentModel, originalRecord: editRecord, form, parentRecord, createFromFormModel, createFromFormRecord, setHiddenFields })

    useEffect(() => {

        setLoading(true)
        let valuesToTransform = Object.assign({}, values)

        transformValues({ values: valuesToTransform })
        .then(()=>{
            setLoading(false)
        })
    }, [])

    const validation = async (value, field) => {

        //first check if the field is required
        if (field.meta.fieldConfig.required && (value === '' || value === null || value === 'undefined' || value.length === 0)) {
            return `${field.meta.fieldConfig.niceName} is required`
        }

        //if we have an array
        if (field.meta.fieldConfig.type === 'relatedList') {
            value = JSON.stringify(value)
        }
        
        // //if it's a string is it under 255 chars?
        if((!field.meta.fieldConfig.type || field.meta.fieldConfig.type == 'string') && field.meta.fieldConfig.fieldType != 'text' && field.meta.fieldConfig.fieldType != 'html' && value?.length > 255){            
            return `Value of '${field.meta.fieldConfig.apiName}' is longer than the maximum allowed size`
        }

        //then do client side, if there is any
        if (field.meta.fieldConfig.clientValidation) {
            let clientSideResult = field.meta.fieldConfig.clientValidation({ value, values: field.form.values, login, originalRecord: editRecord })

            if (clientSideResult){
                return clientSideResult
            }
        }

        //then do server side, if there is any
        if (field.meta.fieldConfig.serverValidation) {

            let serverSideResult

            let allValues = Object.assign({},values)
            
            await field.debounce(async () => {
                try {
                    //used to use get but now uses post
                    // let fetchRes = await runFetch(`/${field.meta.model.name}/${editRecord ? editRecord.id + '/' : ''}validate/${field.fieldName}`, { queryString: { value: value, values: JSON.stringify(allValues) } })
                    let fetchRes = await runFetch(`/${field.meta.model.name}/${editRecord ? editRecord.id + '/' : ''}validate/${field.fieldName}`, { method:"POST", body: JSON.stringify({ value: value, values: allValues }) })
                    serverSideResult = fetchRes.error
                }
                catch (err) {

                }
            }, 300)

            if (serverSideResult){
                return serverSideResult
            }
            else return false
        }

        return false

    }

    const interceptSubmit = async e => {
        setEverSubmitted(true)
        
        e.preventDefault()
        
        return handleSubmit()
    }
    

    //store save which groups are open
    //if no groupClosedIndexes Object in local storage, create one...
    if(!localStorage.groupClosedIndexes) localStorage.groupClosedIndexes = "{}"
    let groupClosedIndexesFromLS = JSON.parse(localStorage.groupClosedIndexes)
    
    //do we have a index for this model?
    if(!groupClosedIndexesFromLS[model.name]) groupClosedIndexesFromLS[model.name] = {}

    const [groupClosedIndexes, setGroupClosedIndexes] = useState(groupClosedIndexesFromLS)
    

    //save the most recent tab in local storage
    const saveGroupClosedIndexes = (group, state)=>{
        let newGroupClosedIndexes = Object.assign({},groupClosedIndexes)
        newGroupClosedIndexes[model.name][group] = state
        setGroupClosedIndexes(newGroupClosedIndexes)
        localStorage.groupClosedIndexes = JSON.stringify(newGroupClosedIndexes)
    }

    //toggleGroupClosed
    const toggleGroupClosed = (group)=>{
        if(groupClosedIndexes[model.name][group] === true) saveGroupClosedIndexes(group, false)
        else saveGroupClosedIndexes(group, true)
    }

    let afterSaveOptions = [
        { key: 'stay', text:'Stay where I am', value: 'stay' },
        { key: 'goToNew', text: `Go to the new ${model.niceName}`, value: 'goToNew' },
        { key: 'addAnother', text: `Add another ${model.niceName}`, value: 'addAnother' },
    ]

    if(model.afterSaveCreateNewOptions){
        afterSaveOptions = afterSaveOptions.concat(model.afterSaveCreateNewOptions.map(o=>{
            let nextModel = config.models[o]

            return { key: `addNew:${nextModel.name}`, text:`Add ${nextModel.niceName}`, value: `addNew:${nextModel.name}` }
        }))
    }

    return (
        <>
            <Modal.Content scrolling  style={{paddingBottom:200}}>
                <Modal.Description>
                    <Form className='ui form' autoComplete='on'>
                        {Object.keys(model.fieldGroups).map(group => {

                            group = model.fieldGroups[group]
                            let groupFields = []
                            for (let fieldKey of Object.keys(model.fields)) {
                                let field = model.fields[fieldKey]

                                if (group.name !== field.groupName) continue

                                //if field is not part of this group, or shouldn't be displayed
                                let computedPutPost = typeof field[editRecord ? 'put' : 'post'] === 'function'?field[editRecord ? 'put' : 'post']({login:login}):field[editRecord ? 'put' : 'post']

                                if (
                                    computedPutPost === false ||
                                    //check this field's lookupmodel or relationship model are the same as the parent model, this prvents cyclical loops when creating record
                                    //e.g. new org => individuals field => new individual => organisations field => etc
                                    ((field.lookupModel && createFromFormModel && field.lookupModel == createFromFormModel.name) || (field.relationship && createFromFormModel && field.relationship == createFromFormModel.name))   
                                ) continue;

                                groupFields.push(field)
                            }

                            //calculate how many are not hidden
                            let notHidden = 0
                            for(let gf of groupFields){
                                if(hiddenFields.indexOf(gf.apiName)==-1) notHidden++
                            }

                            let collapsed = groupClosedIndexes[model.name] && groupClosedIndexes[model.name][group.name] === true?true:false

                            if (groupFields.length && notHidden>0)
                                return <div key={group.name} className={`editGroupHolder ${collapsed?'collapsed':''}`}>
                                    <Header onClick={()=>toggleGroupClosed(group.name)} as='h3' attached='top' style={{ borderTop: `2px solid ${config.primaryColour}` }}>
                                        <Icon 
                                            name={groupClosedIndexes[model.name] && groupClosedIndexes[model.name][group.name] === true?'angle down':'angle up'}
                                            style={{float:'right', position:'relative', top:'2px', cursor:'pointer'}} 
                                        />
                                        <Icon name={group.icon} />
                                        <Header.Content>{group.niceName}</Header.Content>
                                        {group.description && !collapsed ? <Header.Subheader>{group.description}</Header.Subheader> : null}
                                    </Header>
                                    
                                    <STable definition attached>
                                        <STable.Body>
                                            {groupFields.map(field => {
                                                //check to see if the field is provided by the parent model/id and then don't render it
                                                return <STable.Row key={field.apiName} style={{ display: hiddenFields.indexOf(field.apiName) > -1 ? 'none' : 'table-row' }}>
                                                    <STable.Cell verticalAlign='top' width={6}>
                                                        {field.niceName}
                                                        {field.description ?
                                                            <Popup
                                                                offset={[-8,0]}
                                                                trigger={<Icon style={{ marginLeft: 5}} name='help circle' />}
                                                                content={<span dangerouslySetInnerHTML={{ __html: field.description }} />}
                                                                size='mini'
                                                                popperModifiers={[
                                                                    {
                                                                    name: 'zIndex',
                                                                    enabled: true,
                                                                    phase: 'write',
                                                                    fn: ({state}) => {
                                                                        state.styles.popper.zIndex = 100000
                                                                    },
                                                                    },
                                                                ]}
                                                            />
                                                        : null}
                                                        {field.required ? <Label className='requiredLabel' size='mini' color='red'>Required</Label> : null}
                                                    </STable.Cell>
                                                    <STable.Cell width={10}>
                                                        <>
                                                        {
                                                            1==2 && parentModel && relationship && relationship.foreignKeyAPIName == field.apiName
                                                                ? parentModel.referToByString(parentRecord)
                                                                : <GetInputField formLoading={loading} everSubmitted={everSubmitted} field={field} model={model} validation={validation} transformValues={transformValues} editRecord={editRecord} parentRecord={parentRecord} parentModel={parentModel} />
                                                        }
                                                        </>
                                                    </STable.Cell>
                                                </STable.Row>
                                            })}
                                        </STable.Body>
                                    </STable>
                                </div>

                            else return null
                        })}
                        
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {canSubmit?
                    <>
                        {!editRecord&&model.mainMenu!==false&&!createFromFormModel?
                            <>
                                After saving: &nbsp;&nbsp;
                                <Dropdown
                                    value={afterSave}
                                    options={afterSaveOptions}
                                    // text={()=>{
                                    //     if(afterSave=='stay') return 'Stay where I am'
                                    //     else if(afterSave=='goToNew') return `Go to the new ${model.niceName}`
                                    //     else if(afterSave=='addAnother') return `Add another ${model.niceName}`
                                    // }}
                                    onChange={(e, data)=>{
                                        saveAfterSave(data.value)
                                        setAfterSave(data.value)
                                    }}
                                />
                                &nbsp;&nbsp;
                            </>
                        :null}
                        <Button onClick={interceptSubmit} color='green'>Save {model.niceName}</Button>
                    </>
                    :null
                    
                }
                {!canSubmit && !isSubmitting && !fieldsAreValidating?
                    <Message negative >
                        <p>There are validation errors on this form...&nbsp;&nbsp;
                        <Button size='tiny' onClick={interceptSubmit} color='red'>Show all form errors</Button>
                        </p>
                    </Message>
                    :null  
                }
                {isSubmitting? 
                    <Button onClick={null} loading>Submitting... {model.niceName}</Button>
                : null}
            </Modal.Actions>
        </>
            
    );
}

export default EditForm