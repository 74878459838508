// import PropTypes from 'prop-types'
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { navigate } from 'react-big-calendar/lib/utils/constants';
import * as dates from 'react-big-calendar/lib/utils/dates';

function createCalendar(currentDate) {
  if (!currentDate) {
    currentDate = moment()
  } else {
    currentDate = moment(currentDate)
  }

  const first = currentDate.clone().startOf('month')
  const last = currentDate.clone().endOf('month')
  const weeksCount = Math.ceil((first.day() + last.date()) / 7)
  const calendar = Object.assign([], { currentDate, first, last })

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = []
    calendar.push(week)
    calendar.year = currentDate.year()
    calendar.month = currentDate.month()

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set('date', day + 1 - first.day())
      date.calendar = calendar
      week.push(date)
    }
  }

  return calendar
}

function CalendarDate(props) {
  const { dateToRender, dateOfMonth } = props
  const today =
    dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      ? 'today'
      : ''

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    )
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    )
  }

  return (
    <button
      className={`date in-month ${today}`}
      onClick={() => props.onClick(dateToRender)}>
      {dateToRender.date()}
    </button>
  )
}

function Calendar  ({ date, onClick }) {
  const [calendar, setCalender] = useState(undefined);

  useEffect(() => {
    setCalender(createCalendar(date));
  }, [date]);
 
  if (!calendar) {
    return null
  }

  return (
    <div className="month">
      <div className="month-name">
        {calendar.currentDate.format('MMMM').toUpperCase()}
      </div>

      {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
        <span key={index} className="day">
          {day}
        </span>
      ))}

      {calendar.map((week, index) => (
        <div key={index}>
          {week.map((date) => (
            <CalendarDate
              key={date.date()}
              dateToRender={date}
              dateOfMonth={calendar.currentDate}
              onClick={date => {
                onClick(date);
              }}
            />
          ))}
        </div>
      ))}
    </div>
  );
  
}

function Year ({ date, ...props }) {
  const months = []
  const firstMonth = dates.startOf(date, 'year')

  for (let i = 0; i < 12; i++) {
    months.push(
      <Calendar key={i + 1} date={dates.add(firstMonth, i, 'month')} onClick={(date) => {
        props.onNavigate(Year.navigate.DATE, date);
        props.onView('day');
      }} />
    )
  }

  return <div className="year">{months.map(month => month)}</div>
}

Year.range = date => {
  return [dates.startOf(date, 'year')]
}

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -1, 'year')

    case navigate.NEXT:
      return dates.add(date, 1, 'year')

    default:
      return date
  }
}

Year.title = (date, { localizer }) => localizer.format(date, 'yearHeaderFormat')

export default Year
