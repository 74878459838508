import React, { useState } from 'react'

export const ErrorsContext = React.createContext();

export const ErrorsProvider = (props) => {
    const [errors, setErrors] = useState([])

    const addError = message=>{
        let newErrors = errors.slice()
        newErrors.push(message)
        setErrors(newErrors)
    }

    const removeError = ()=>{
        
        let newErrors = errors.slice()
        newErrors.splice(-1,1)
        
        setErrors(newErrors)
    }

    return (
        <ErrorsContext.Provider value={[errors, addError, removeError]} >
            {props.children}
        </ErrorsContext.Provider>
    )

}