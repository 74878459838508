import React, {useState } from 'react'
import { useField, splitFormProps } from "react-form"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { Input, Label, Icon } from 'semantic-ui-react'
import Moment from 'moment'
import useTransformValues from '../../hooks/useTransformValues'

const DateField = (props) => {
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    let [loading, setLoading] = useState(false)

    // Use the useField hook with a field and field options
    // to access field state
    const {
        form,
        meta: { error, isTouched, isValidating, fieldConfig, model },
        getInputProps,
        value,
        setValue
    } = useField(field, fieldOptions);

    const handleChange = (e) => {

        // let newValueAsString = newValue?Moment(newValue).format('YYYY-MM-DD'):null

        // console.log('hi')
        let newValue = e.target.value

        setValue(newValue)

        // console.log(newValue)

        if(props.transformValues){
            setLoading(true)
            props.transformValues({values:{...form.values, [field]:newValue}, fieldName:field})
            .then(()=>{
                setLoading(false)
            })
        }
    }

    //get rid of the time part (if there is one)
    let datePickerValue = value?.split('T')[0]|| value


    //value might be a string (probably from database)
    // if(value){
    //     let valueAsMoment = Moment(value)
    //     inputValue = valueAsMoment.format('DD/MM/YYYY')
    //     datePickerValue = valueAsMoment.toDate()
    // }
    

    //Don't enable this
    // const handleOnBlur = ({target}) => {
    //     let date = Moment(target.value, 'DD/MM/YYYY')
        
    //     if(date.isValid()){
    //         setValue(date.toDate())
    //     }
    //     else {
    //         setValue(null)
    //     }
    // };

    // Build the field
    return (
        <>
            <div style={{float:'left', clear:'both'}}>
                {/* <DatePicker 
                    showYearDropdown 
                    showMonthDropdown 
                    isClearable 
                    dateFormat="dd/MM/yyyy" 
                    disabled={props.formLoading}
                    selected={datePickerValue} 
                    onChange={handleChange} 
                    customInput={<CustomInput />}
                />{" "} */}
                <input
                    type="date"
                    value={datePickerValue}
                    onChange={handleChange}
                />
            </div>
            <div style={{clear:'both'}}>
                {isTouched && error ? (
                    <Label color='red' pointing='above'>
                        {error}
                    </Label>
                ) : null}
            </div>
        </>
    );
}

export default DateField