import React, { useState, useEffect } from 'react'
import { useField, splitFormProps } from "react-form"
import { Checkbox, Label, Icon } from 'semantic-ui-react'
import useTransformValues from '../../hooks/useTransformValues'

const CheckBoxField = (props) => {
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);
    let [loading, setLoading] = useState(false)

    // Use the useField hook with a field and field options
    // to access field state
    let {
        form,
        meta: { error, isTouched, isBlured, isValidating, model, fieldconfig },
        getInputProps,
        value,
        setValue,
        setMeta
    } = useField(field, fieldOptions);

    //let { transform: transformValues, loading: transformLoading } = useTransformValues({ fieldName: field, model, form })

    //prevent null or undefined being passed (creating and uncontrolled component)
    //and default to false
    // useEffect(()=>{
    //     if(value===null||value===undefined){
    //         setValue(false)
    //     }
    // },[])


    const onBlur = () => {

    }

    let showError = (isTouched && error)


    const handleChange = async (e, data) => {

        let inputValue = data.checked

        await setValue(inputValue)

        //run any form transformations
        if(props.transformValues){
            setLoading(true)
            props.transformValues({values: { ...form.values, [field]: inputValue },fieldName:field})
            .then(()=>{
                setLoading(false)
            })
        }

        // setMeta(oldMeta => {
        //     let newMeta = Object.assign({}, oldMeta)
        //     newMeta.isBlured = true
        //     return newMeta
        // })
    }
    
    // Build the field
    return (
        <>
            <div style={{ float: 'left', width: 60 }}>
                <Checkbox 
                    toggle 
                    style={{ width: 300 }} 
                    onChange={handleChange} 
                    checked={value ? true : false} 
                    disabled={props.formLoading}
                    onKeyPress={(e)=>{
                        if(e.key == 'Enter')e.preventDefault()
                    }}
                />

            </div>
            {isValidating || loading || props.formLoading ?
                <div style={{ float: 'left', width: 30, position: 'relative', top: 2 }}>
                    <Icon loading color='grey' name='circle notched' />
                </div>
                : null}
            <div style={{ clear: 'both' }}>
                {showError ? (
                    <Label pointing='above' color='red'>
                        {error}
                    </Label>
                ) : null}
            </div>

        </>
    );
}

export default CheckBoxField