
import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Modal, Button, Segment, Table, Icon, Progress } from 'semantic-ui-react'
import useFetch from '../../hooks/useFetch'
import FileIcon, { defaultStyles } from 'react-file-icon'
import CryptoJS from 'crypto-js';
import Jimp from 'jimp/es';
import axios from 'axios';
import { prettyDOM } from '@testing-library/react'
import Exif from 'exif-js'

const upload = async ({acceptedFiles, setFileProgressHelper, parentModel, parentRecord, runFetch, model, setUploading})=>{

    let i = 0

    for(let file of acceptedFiles){
        setFileProgressHelper(i, 0)

        await new Promise((resolve, reject)=>{

            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async () => {

                let file_wordArr = CryptoJS.lib.WordArray.create(reader.result); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
                let sha1_hash = CryptoJS.SHA1(file_wordArr) //calculate SHA1 hash
                let imageHash = sha1_hash.toString() //output result

                let thumb1280Buff
                let thumb640Buff

                //rotate and thumbs if image
                if (file.type.indexOf('image') > -1 && file.type.indexOf('svg') == -1) {

                    
                    //get the exif rotation
                    let rotate = 0
                    try{
                        let exif = Exif.readFromBinaryFile(reader.result);

                        if (exif.Orientation == 7 || exif.Orientation == 8) rotate = -90
                        if (exif.Orientation == 5 || exif.Orientation == 6) rotate = 90
                    }
                    catch(e){
                        console.log('rotation check failed')
                    }

                    let thumb1280 = await Jimp.read(reader.result)
                    thumb1280.resize(1280, Jimp.AUTO) // resize
                    thumb1280.quality(80) // set JPEG quality
                    thumb1280.rotate(rotate||0)
                    thumb1280Buff = await thumb1280.getBufferAsync(file.type)

                    let thumb640 = thumb1280
                    thumb640.resize(640, Jimp.AUTO) // resize
                    thumb640Buff = await thumb640.getBufferAsync(file.type)


                }

                const options = {
                    method: 'GET',
                    queryString: {
                        name: file.name,
                        type: file.type,
                        hash: imageHash,
                        //data: reader.result,
                        parentModel: parentModel.name,
                        parentId: parentRecord.id
                    }
                }

                let { signedUrl } = await runFetch(`/${model.name}/signUpload`, options)

                // Put the fileType in the headers for the upload

                let headers = {'Content-Type': file.type}
                if(model.uploadFilesAsPublic === true) headers["x-amz-acl"] = "public-read"

                let uploadOptions = {
                    headers,
                    onUploadProgress: async progressEvent=>{
                        
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                        setFileProgressHelper(i, percentCompleted)

                        //confirm upload with database
                        if(percentCompleted == 100){
                            const options = {
                                method: 'POST',
                                body: JSON.stringify({
                                    name: file.name,
                                    type: file.type,
                                    hash: imageHash,
                                    size: file.size,
                                    parentModel: parentModel.name,
                                    parentId: parentRecord.id
                                })
                            }
            
                            await runFetch(`/${model.name}/confirmUpload`, options)
                        }

                    }
                }

                await axios.put(signedUrl, file, uploadOptions)    
                .catch(e=>{console.log(e)})

                //thumbs if image...
                if (file.type.indexOf('image') > -1 && file.type.indexOf('svg') == -1) {

                    const optionsThumb1280 = {
                        method: 'GET',
                        queryString: {
                            name: file.name,
                            type: file.type,
                            hash: imageHash+'_1280',
                            //data: reader.result,
                            parentModel: parentModel.name,
                            parentId: parentRecord.id
                        }
                    }
    
                    const optionsThumb640 = {
                        method: 'GET',
                        queryString: {
                            name: file.name,
                            type: file.type,
                            hash: imageHash+'_640',
                            //data: reader.result,
                            parentModel: parentModel.name,
                            parentId: parentRecord.id
                        }
                    }
                    
                    let { signedUrl:signedUrlThumb1280 } = await runFetch(`/${model.name}/signUpload`, optionsThumb1280)
                    let { signedUrl:signedUrlThumb640 } = await runFetch(`/${model.name}/signUpload`, optionsThumb640)
    
                    let headers = {'Content-Type': file.type}
                    if(model.uploadFilesAsPublic === true) headers["x-amz-acl"] = "public-read"

                    let uploadOptionsThumb1280 = {
                        headers
                    }
    
                    let uploadOptionsThumb640 = {
                        headers
                    }
    
                    await axios.put(signedUrlThumb1280, thumb1280Buff, uploadOptionsThumb1280)    
                    .catch(e=>{console.log(e)})
    
                    await axios.put(signedUrlThumb640, thumb640Buff, uploadOptionsThumb640)    
                    .catch(e=>{console.log(e)})
                }

                resolve()


            }
            reader.readAsArrayBuffer(file)
        })

        i++
    }

    setUploading(false)
}

const FileUploader = ({ model, parentModel, parentRecord, onComplete }) => {

    const [files, setFiles] = useState([])
    const [fileProgress, setFileProgress] = useState([0])
    const [uploading, setUploading] = useState(false)

    let fileProgressStore = []

    let setFileProgressHelper = (i, percentCompleted)=>{
        fileProgressStore[i] = percentCompleted

        setFileProgress([...fileProgressStore])
    }

    let { runFetch } = useFetch()

    //handle files
    const onDrop = useCallback(acceptedFiles => {  
        if(!uploading){
            setUploading(true)
            setFiles(acceptedFiles)
            upload({acceptedFiles, setFileProgressHelper, parentModel, parentRecord, runFetch, model, setUploading})
        }
    },[])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return <>

        {
        <Segment inverted className='dropzone' style={{ padding: 0 }}>
            <div {...getRootProps()} style={{ height: '100%', width: '100%', padding: 20 }}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
                {
                    model.uploadFilesAsPublic === true ? <p style={{color:"red"}}><span style={{fontWeight:'bold'}}>Warning:</span> These files will be publically accessible via the internet, do not upload any sensitive information.</p> : null
                }
            </div>

        </Segment>
        }

        {files.length ?
            <Modal open={true} color='red'>
                <Modal.Header>
                    Uploading {model.niceNamePlural}
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Table celled striped>
                            <Table.Body>
                                {files.map((file, index) => {
                                    let ext = file.name.split('.').pop()
                                    return <Table.Row key={index}>
                                        <Table.Cell collapsing><FileIcon size={40} extension={ext} {...defaultStyles[ext]} /></Table.Cell>
                                        <Table.Cell collapsing>{file.name}</Table.Cell>
                                        <Table.Cell collapsing >{(Math.round(file.size / 1024)).toLocaleString()}KB</Table.Cell>
                                <Table.Cell width={4} ><Progress progress percent={fileProgress[index]||0} success={fileProgress[index]==100} warning={fileProgress[index]!==undefined&&fileProgress[index]<100}/>
                                    {fileProgress[index]===undefined?'Queued...':''}
                                    {fileProgress[index]===0?'Processing...':''}
                                    {fileProgress[index]&&fileProgress[index]!=100?'Uploading...':''}
                                    {fileProgress[index]&&fileProgress[index]==100?'Done':''}
                                </Table.Cell>
                                    </Table.Row>
                                }
                                )}
                            </Table.Body>
                        </Table>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={(e) => {
                        e.stopPropagation()
                        setFiles([])
                        onComplete()
                    }}>Close</Button>
                </Modal.Actions>
            </Modal>
            : null}

    </>

}

export default FileUploader