import React, { useContext, useState } from 'react';
import { Modal, Button, Label } from 'semantic-ui-react'
import { UIContext } from "../../contexts/UIContext"
import { ErrorsContext } from "../../contexts/ErrorsContext"
import useFetch from '../../hooks/useFetch'
import EditForm from "../editForm"
import FiltersLookupField from '../../components/formFields/FiltersLookupField'

const MergeView = () => {

    const { currentMerge, completeMerge, refreshData } = useContext(UIContext)
    const [mergeState, setMergeState] = useState('selectSecondary')
    //const [selectedValue, setSelectedValue] = useState(null)
    const [secondaryRecord, setSecondaryRecord] = useState(null)

    let { runFetch } = useFetch()

    const clearMerge = ()=>{
        completeMerge()
        setMergeState('selectSecondary')
        setSecondaryRecord(null)
        //setSelectedValue(null)
        refreshData()
    }

    const runMerge = async () => {
        setMergeState('running')

        //console.log(currentMerge)
        
        const options = {
            method: 'PUT',
            body: JSON.stringify({
                primaryId:currentMerge.record.id,
                secondaryId:secondaryRecord.id
            })
        }
        
        let fetchRes = await runFetch(`/${currentMerge.model.name}/merge`, options)
        
        //run callback
        if(fetchRes && currentMerge.onMerge) currentMerge.onMerge()

        clearMerge()
        return

    }
    return <>
        {currentMerge ?
            <Modal open={true} color='blue'>
                {mergeState == 'selectSecondary' ?
                    <>
                        <Modal.Header>
                            Merge {currentMerge.model.niceName} records
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <p>This action will merge {currentMerge.model.referToByString(currentMerge.record)} with another record.</p>
                                <ul>
                                    <li>{currentMerge.model.referToByString(currentMerge.record)} is the <b>primary</b> record, all data on this record will be preserved.</li>
                                    <li>The record you choose below will be the <b>secondary</b> record, this record will be destroyed</li>
                                    <li>Any empty fields on the <b>primary</b> record will be populated with data from the <b>secondary</b> record.</li>
                                    <li>Any fields on the <b>primary</b> record that already have data will be preserved, however the corresponding field on the <b>secondary</b> record will be permanently deleted.</li>
                                    <li>After merging, any related records that relate to the <b>secondary</b> record will be moved to the <b>primary</b> record.</li>
                                </ul>
                                <div>Secondary Record: &nbsp; &nbsp; &nbsp; 
                                    <FiltersLookupField 
                                        value={secondaryRecord?secondaryRecord.id:null} 
                                        //onChange={record=>{setSelectedValue(record)}} 
                                        onChangeFullRecord={record=>{setSecondaryRecord(record?record.record:null)}} 
                                        field={{lookupModel:currentMerge.model.name, niceName:`secondary ${currentMerge.model.niceName}`}}
                                        JSONFilters = {{
                                            rules: [
                                                { "field": "id", "operator": "$ne", value: currentMerge.record.id || 0 },
                                            ],
                                            combinator: "and"
                                                	
                                        }}
                                    />
                                </div>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={()=>{completeMerge(); setMergeState('selectSecondary'); setSecondaryRecord(null);}}>Cancel</Button>
                            <Button disabled={!secondaryRecord||!secondaryRecord.id} negative onClick={()=>{setMergeState('confirmation')}}>Next</Button>
                        </Modal.Actions>
                    </>
                    : null}
                {mergeState == 'confirmation' ?
                    <>
                        <Modal.Header>
                        Merge {currentMerge.model.niceName} records
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <p>This action will merge <b>{currentMerge.model.referToByString(currentMerge.record)}</b> with <b>{currentMerge.model.referToByString(secondaryRecord)}</b>.</p>
                                <ul>
                                    <li><b>{currentMerge.model.referToByString(currentMerge.record)}</b> is the <b>primary</b> record, all data on this record will be preserved.</li>
                                    <li><b>{currentMerge.model.referToByString(secondaryRecord)}</b> is the <b>secondary</b> record, this record will be destroyed</li>
                                    <li>Any empty fields on the <b>primary</b> record will be populated with data from the <b>secondary</b> record.</li>
                                    <li>Any fields on the <b>primary</b> record that already have data will be preserved, however the data in the corresponding field on the <b>secondary</b> record will be permanently deleted.</li>
                                    <li>After merging, any related records that relate to the <b>secondary</b> record will be moved to the <b>primary</b> record.</li>
                                </ul>
                                <Label color='red' size='large'><b>{currentMerge.model.referToByString(secondaryRecord)} will be permanently deleted and all references to it will be changed, this action is irreversable.</b></Label>
                                <p><br />Are you sure you want to continue?</p>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={()=>{completeMerge(); setMergeState('selectSecondary'); setSecondaryRecord(null);}}>Cancel</Button>
                            <Button negative onClick={runMerge}>I'm sure</Button>
                        </Modal.Actions>
                    </>
                    : null}
                {mergeState == 'running' ?
                    <>
                        <Modal.Header>
                            Merging {currentMerge.model.niceName}...
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                Processing...
                            </Modal.Description>
                        </Modal.Content>
                    </>
                    : null}
                
            </Modal>
            : null}
    </>
}

export default MergeView