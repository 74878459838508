import { useContext, useEffect, useState } from 'react'
import useFetch from '../hooks/useFetch'
import { ConfigContext } from '../contexts/ConfigContext'
import crypto from 'crypto'

const evalFunctions = (obj) => {

    Object.keys(obj).forEach(key => {

        if (typeof obj[key] === 'object' && obj[key] !== null){
            evalFunctions(obj[key])
        }

        else if(typeof obj[key] == 'string' && (obj[key].indexOf('=>') > -1 || obj[key].indexOf('function') > -1)){
			obj[key] = eval(obj[key])
		}
    })

    return obj
}

const Header = () => {

    const { 0: config, 1: setConfig } = useContext(ConfigContext)

    const { runFetch } = useFetch()

    const [configTimeout, setConfigTimeout] = useState()

    const getConfig = async ()=>{
        let result =  await runFetch('/client-config')

        if(result){
            result.clientVersion = 83
            localStorage.config = JSON.stringify(result)

            console.log('Config has changed... reloading')
            setConfig(evalFunctions(result))
        }
    }

    const getConfigHash = async ()=>{
        try{
            let hashResult = await runFetch('/client-config/hash')
            if(hashResult){
                return hashResult.hash
            }
        }
        catch(e){
            return null
        }
    }

    useEffect(()=>{
        let go = async ()=>{
            await getConfig()
        }

        go()
    },[])

    useEffect(()=>{
        let timeConfig = ()=>{
            clearTimeout(configTimeout)

            let timer = setTimeout(async ()=>{
                let newConfigHash = await getConfigHash()

                console.log(newConfigHash, config.hash)

                if(!newConfigHash){
                    console.log('Could not get config hash from server')
                    timeConfig()
                }
                else if(newConfigHash != config.hash) await getConfig()
                else {
                    console.log('Config has not changed since last reload - all good')
                    timeConfig()
                }

            },60*1000)

            setConfigTimeout(timer)
        }

        timeConfig()
    },[config])


    return null
}

export default Header