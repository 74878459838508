import React, { useContext } from 'react';
import { UIContext } from '../../contexts/UIContext'
import { ConfigContext } from '../../contexts/ConfigContext'
import { LoginContext } from '../../contexts/LoginContext'
import { Link } from "react-router-dom"
import formatValue from '../../utils/formatFieldValue.js'
import { Table as STable, Dropdown, Icon, Segment, Checkbox, Popup, Button } from 'semantic-ui-react'


const OptionsCell = ({ record, model, relationship, parentModel, parentRecord }) => {
    const { 0: login } = useContext(LoginContext)
    const { 0: config } = useContext(ConfigContext)
    const { addToEditModalQueue, refreshDataKey, refreshData, runAction, runDeletion } = useContext(UIContext)

    //build delete buttons
    let deleteButtons = []

    //add the standard delete button
    if(
        (!config.globalClientPermission || config.globalClientPermission({ login, method:'DELETE', model:model.name }) === true)
        && (!relationship || relationship.deletable)
        && (!model.clientDeletePermission || model.clientDeletePermission({ login, currentRecord: record, config }) === true) 
    ){
        deleteButtons.push(<Dropdown.Item
            key={`${model.name}-delete`}
            color='red'
            text={relationship&&relationship.deleteText?relationship.deleteText:`Delete ${model.niceName}`}
            icon={<Icon color='red' name='trash alternate' />}
            onClick={() => {
                runDeletion({ model, id: record.id })
            }}
        />)
    }

    //add the alternative delete buttons
    if(relationship){
        Object.keys(relationship.editModels).map(editModelKey=>{
            let editModel = relationship.editModels[editModelKey]
            let model = config.models[editModel.model]
            let editRecord = editModel.editRecord({parentRecord, parentModel, record})

            //alt model delete button
            if(
                (!config.globalClientPermission || config.globalClientPermission({ login, method:'DELETE', model:model.name }) === true)
                && (editModel.deletable)
                && (!model.clientDeletePermission || model.clientDeletePermission({ login, currentRecord: editRecord, config }) === true) 
            ){
            
                deleteButtons.push(<Dropdown.Item
                    key={`${model.name}_delete`}
                    color='red'
                    text={editModel.deleteText?editModel.deleteText:`Delete ${model.niceName}`}
                    icon={<Icon color='red' name='trash alternate' />}
                    onClick={() => {
                        runDeletion({ model, id: editRecord.id })
                    }}
                />)
            }
            
        })
    } 


    let displayedActionsButtons = []
    
    if(model.actions){
        for(let actionKey of Object.keys(model.actions)){
            let action = model.actions[actionKey]

            let computedActionDisplay = typeof action.display === 'function'?action.display({ login, records: [record], config }):action.display
            //default to true
            if(computedActionDisplay !== false) computedActionDisplay = true

            if(!action.global && computedActionDisplay){

                displayedActionsButtons.push(<Dropdown.Item
                    key={actionKey}
                    text={action.niceName}
                    icon={<Icon color={action.colour||null} name={action.icon} />}
                    onClick={() => {
                        runAction({ model, action, inputs: new Set([record.id]), parentModelName:parentModel?.name, parentRecordId:parentRecord?.id  })
                    }}
                    disabled={action.clientPermission && action.clientPermission({ login, records: [record], config }) !== true}
                />)

            }
        }
    }

    let displayedLinkButtons = []
    
    if(model.links){
        for(let link of model.links){
            if(!link.global && (!link.display || link.display({ login, record: record, config }) === true)){
                displayedLinkButtons.push(<Dropdown.Item
                    key={link}
                    text={link.text(record)}
                    icon={link.icon}
                    as='a'
                    target='_blank'
                    href={link.to(record, config, login.token, login)}
                />)
            }
        }
    }
    

    return <STable.Cell collapsing>
        {!relationship||(relationship&&relationship.viewable !== false)?
            <Popup
                content={relationship&&relationship.viewText?relationship.viewText(record):`View ${model.niceName}`}
                position='top center'
                trigger={
                    <Button size='mini' as={Link} to={relationship ? relationship.linkTo(record) : model.linkTo(record)} icon>
                        <Icon name='search' />
                    </Button>
                }
            />
        :null}

        {
            //standard edit button
            (!config.globalClientPermission || config.globalClientPermission({ login, method:'PUT', model:model.name }) === true)
            && (!relationship || relationship.editable)
            && (!model.clientPutPermission || model.clientPutPermission({ login, currentRecord: record, config }) === true)
            ?
            <Popup
                content={relationship&&relationship.editText?relationship.editText:`Edit ${model.niceName}`}
                position='top center'
                trigger={
                    <Button size='mini' icon onClick={() => {

                        addToEditModalQueue({
                            model,
                            editRecord:record,
                            parentModel: parentModel,
                            parentRecord: parentRecord
                        })
                    }}>
                        <Icon name='pencil' />
                    </Button>
                }
            />
        : null}

        {
            relationship?
                Object.keys(relationship.editModels).map(editModelKey=>{
                    let editModel = relationship.editModels[editModelKey]
                    let model = config.models[editModel.model]
                    let editRecord = editModel.editRecord({parentRecord, parentModel, record})

                    //alt model edit button
                    return (!config.globalClientPermission || config.globalClientPermission({ login, method:'PUT', model:model.name }) === true)
                    && editModel.editable
                    && (!model.clientPutPermission || model.clientPutPermission({ login, currentRecord: editRecord, config }) === true)
                    ?
                    <Popup
                        content={editModel.editText?editModel.editText:`Edit ${model.niceName}`}
                        position='top center'
                        trigger={
                            <Button size='mini' icon onClick={() => {
        
                                addToEditModalQueue({
                                    model,
                                    editRecord:editRecord,
                                    parentModel: parentModel,
                                    parentRecord: parentRecord
                                })
                            }}>
                                <Icon name='pencil' />
                            </Button>
                        }
                    />
                    : null
                })
            :null
        }

        {model.actions ? Object.keys(model.actions).map(actionKey => {
            let action = model.actions[actionKey]

            let computedActionDisplay = typeof action.display === 'function'?action.display({ login, records: [record], config }):action.display
            //default to true
            if(computedActionDisplay !== false) computedActionDisplay = true

            if(action.pin === true && computedActionDisplay){

                return <Popup
                    key={actionKey}
                    content={action.niceName}
                    position='top center'
                    trigger={
                        <Button 
                            size='mini' 
                            icon
                            color={action.colour||null}
                            onClick={() => {
                                runAction({ model, action, inputs: new Set([record.id]), parentModelName:parentModel?.name, parentRecordId:parentRecord?.id  }) 
                            }}
                            disabled={action.clientPermission && action.clientPermission({ login, records: [record], config }) !== true}
                        >
                            <Icon name={action.icon} />
                        </Button>
                    }
                />

            }

            else return null

            
        }) : null}


        {
            (displayedActionsButtons.length > 0) ||
            (displayedLinkButtons.length > 0) ||
            (deleteButtons.length>0)
            
            ?
            <Dropdown
                icon='ellipsis horizontal'
                size='mini'
                button
                className='icon'
                upward
            >
                <Dropdown.Menu>

                    {displayedActionsButtons}

                    {displayedLinkButtons}

                    {deleteButtons}
                    
                </Dropdown.Menu>
            </Dropdown>
            : null}

    </STable.Cell>
}


const Table = ({
    tableColumnGroups,
    stats,
    setSortField,
    setSortDirection,
    sortDirection,
    records,
    selected,
    handleSelection,
    model,
    relationship,
    parentModel,
    parentRecord
}) => {

    const { 0: config } = useContext(ConfigContext)
    const { 0: login } = useContext(LoginContext)

    //is it posible we have a default image?
    let defaultImageColumn = false
    
    for(let relationshipKey of Object.keys(model.relationships||{})){
        if(model.relationships[relationshipKey].defaultImageQuery){
            defaultImageColumn = true
            break;
        }
    }

    return <Segment style={{ padding: 0, borderTop: `2px solid ${config.primaryColour}` }}>
        <div className='tableHolder'>

            <STable celled striped sortable singleLine={!model.multiline} compact='very'>
                <STable.Header>
                    <STable.Row>
                        <STable.HeaderCell className='notSortable' rowSpan={1} />
                        {defaultImageColumn?<STable.HeaderCell className='notSortable' rowSpan={2} ></STable.HeaderCell>:null}
                        <STable.HeaderCell className='notSortable' rowSpan={2} ></STable.HeaderCell>
                        {tableColumnGroups.map(group => {
                            if (group.tableColumns.length) return <STable.HeaderCell key={group.name} textAlign='center' colSpan={group.tableColumns.length} className='notSortable'>
                                {group.niceName}
                                {group.description ?
                                    <Popup
                                        position='top center'
                                        trigger={<Icon style={{ marginLeft: 5, position: 'relative', top: 1 }} name='help circle' />}
                                        content={<span dangerouslySetInnerHTML={{ __html: group.description }} />}
                                        size='mini'
                                        // popperModifiers={{
                                        //     "preventOverflow": {
                                        //         "boundariesElement": "viewport",
                                        //         //"enabled": true,
                                        //         //"escapeWithReference": true,
                                        //     },}
                                        // }

                                    />
                                    : null}
                            </STable.HeaderCell>
                            else return null
                        })}
                    </STable.Row>
                    <STable.Row>
                        <STable.HeaderCell className='notSortable' rowSpan={1} />
                        {tableColumnGroups.map(group => {
                            return group.tableColumns.map(field => {
                                //has this field been sorted?
                                let sorted = null
                                if (stats.sortField === field.apiName) { //not working!
                                    sorted = stats.sortDirection ? 'descending' : 'ascending'
                                }

                                return <STable.HeaderCell
                                    className={`${!field.sortable ? 'notSortable' : null}`}
                                    sorted={sorted}
                                    key={field.apiName}
                                    onClick={() => {
                                        if (field.sortable) {
                                            setSortField(field.apiName)
                                            setSortDirection(sortDirection ? 0 : 1)
                                        }
                                    }}
                                >
                                    {field.niceName}
                                    {field.description ?
                                        <Popup
                                            position='top center'
                                            trigger={<Icon style={{ marginLeft: 5, position: 'relative', top: 1 }} name='help circle' />}
                                            content={<span dangerouslySetInnerHTML={{ __html: field.description }} />}
                                            size='mini'
                                            // popperModifiers={{
                                            //     "preventOverflow": {
                                            //         "boundariesElement": "viewport",
                                            //         //"enabled": true,
                                            //         //"escapeWithReference": true,
                                            //     },}
                                            // }

                                        />
                                        : null}
                                </STable.HeaderCell>
                            })
                        })}
                    </STable.Row>
                </STable.Header>
                <STable.Body>
                    {records.map((record, i) => {
                        return <STable.Row style={{ background: selected.has(record.id) ? '#CFC' : '' }} key={i}>
                            <STable.Cell collapsing>
                                <Checkbox onClick={handleSelection(record.id)} checked={selected.has(record.id)} />
                            </STable.Cell>
                            {defaultImageColumn?
                                <STable.Cell collapsing>
                                    {record.defaultImage?
                                        <Link to={relationship ? relationship.linkTo(record) : model.linkTo(record)}>
                                            <span style={{
                                                display:'inline-block',
                                                backgroundImage:`url(${record.defaultImage.thumbUrl})`,
                                                width:50, height:50,
                                                backgroundPosition:'50% 50%',
                                                backgroundSize:'cover',
                                                backgroundRepeat:'no-repeat',
                                                borderRadius:25,
                                                border:`2px solid ${config.primaryColour}`,
                                                position:'relative',
                                                top:2
                                            }}
                                            />
                                        </Link>
                                    :null}
                                </STable.Cell>
                            :null}
                            <OptionsCell
                                record={record} 
                                model={model} 
                                relationship={relationship}
                                parentModel={parentModel}
                                parentRecord={parentRecord}
                            />
                            {tableColumnGroups.map(group => {
                                return group.tableColumns.map(field => {

                                    let style = {}
                                    if(field.conditionalStyle) style = field.conditionalStyle(record)
                                    
                                    //get the lookup model if this is a related list
                                    let lookupModel
                                    if (field.lookupModel) lookupModel = config.models[field.lookupModel]

                                    return <STable.Cell key={field.apiName} style={style} >
                                        {formatValue({ model, lookupModel, field, item: record, config, login })}
                                    </STable.Cell>
                                })
                            })}
                        </STable.Row>
                    })}
                </STable.Body>
            </STable>
        </div>
    </Segment>
}

export default Table