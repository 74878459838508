import React, { useContext } from 'react';
import { Icon, Image, Label, Message, Table, Header, Divider } from 'semantic-ui-react'
import MenuItem from '../../components/menuItem'
import { ConfigContext } from '../../contexts/ConfigContext'
import { UIContext } from '../../contexts/UIContext'
import { Link } from "react-router-dom"
import { LoginContext } from '../../contexts/LoginContext'

const Docs = () => {
    const [config] = useContext(ConfigContext)
    const [login] = useContext(LoginContext)

    let path = `http${config.env=='production'?'s':''}://${config.apiHostName}${config.env=='production'?'':'.local:3333'}`

    let orderedModelKeys = Object.keys(config.models).sort((a,b)=>{
        if(config.models[a].niceName < config.models[b].niceName) return -1
        else return 1
    })

    //build permissions matrix
    let permissions = {}

    for(let modelKey of orderedModelKeys){
        let model = config.models[modelKey]

        permissions[modelKey] = {}

        //can we get?
        let getPermission = true
        if (config.globalClientPermission) getPermission = config.globalClientPermission({ login, model:modelKey, method:'GET' })
        if(getPermission === true){
            if (model.clientGetPermission) getPermission = model.clientGetPermission({ login, config })
        }

        //can we post?
        let postPermission = true
        if (config.globalClientPermission) postPermission = config.globalClientPermission({ login, model:modelKey, method:'POST' })
        if(postPermission === true){
            if (model.clientPostPermission) postPermission = model.clientPostPermission({ login, config })
        }

        //can we put?
        let putPermission = true
        if (config.globalClientPermission) putPermission = config.globalClientPermission({ login, model:modelKey, method:'PUT' })
        if(putPermission === true){
            if (model.clientPutPermission) putPermission = model.clientPutPermission({ login, currentRecord:{}, config })
        }

        permissions[modelKey].get = getPermission===true
        permissions[modelKey].post = postPermission===true
        permissions[modelKey].put = putPermission===true

        permissions[modelKey].any = false
        if(permissions[modelKey].get || permissions[modelKey].put || permissions[modelKey].post) permissions[modelKey].any = true

    }

    return <div>
        <Header as='h1'>{config.niceName} - API Documentation</Header>

        <p>The production API hostname of this app is:</p>

        <code className='ui message'>{path}</code>

        <Header as='h2'>Requests</Header>

        <p>All requests are received over HTTP, different methods are used depending on the desired interaction with the data:</p>
            <ul>
                <li><b>GET</b>: Querying and viewing records</li>
                <li><b>PUT</b>: Updating Records</li>
                <li><b>POST</b>: Creating Records</li>
                <li><b>DELETE</b>: Deleting Records</li>
            </ul>
        
        <p>Most requests will require <a href='#authentication'>authentication</a>, see the relevant <a href='#models'>model specification</a> below. </p>
        <p>Errors return consistent error codes and meaningful messages, see the <a href='#errors'>errors</a> section below for me information.</p>


        <Message info>Don't forget to include the correct <b>Content-Type: application/json</b> HTTP header in each request.</Message>

        <Header as='h3'>GET Requests</Header>

        <code className='ui message'>GET {path}/[MODEL NAME]</code>

        <p>All GET request can include the following query string parameters:</p>

        <Table celled compact='very' size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Parameter</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Required</Table.HeaderCell>
                    <Table.HeaderCell>Notes</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>page</Table.Cell>
                    <Table.Cell>integer</Table.Cell>
                    <Table.Cell><Label>No</Label></Table.Cell>
                    <Table.Cell>The page to return. Will default to 1.</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>perPage</Table.Cell>
                    <Table.Cell>integer</Table.Cell>
                    <Table.Cell><Label>No</Label></Table.Cell>
                    <Table.Cell>The number of results to return per page. This defaults to the value specified in the config for each <a href='#models'>model</a> (documented below), it can be changed within sensible bounds on request.</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>q</Table.Cell>
                    <Table.Cell>JSON Object</Table.Cell>
                    <Table.Cell><Label>No</Label></Table.Cell>
                    <Table.Cell><a href='#q-parameter'>See below</a> for query format</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>search</Table.Cell>
                    <Table.Cell>string</Table.Cell>
                    <Table.Cell><Label>No</Label></Table.Cell>
                    <Table.Cell>Intelligent text search string. This search has been tailored to each <a href='#models'>model</a> and may search multiple fields.</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>sortField</Table.Cell>
                    <Table.Cell>string</Table.Cell>
                    <Table.Cell><Label>No</Label></Table.Cell>
                    <Table.Cell>Field to sort results by</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>sortDirection</Table.Cell>
                    <Table.Cell>integer</Table.Cell>
                    <Table.Cell><Label>No</Label></Table.Cell>
                    <Table.Cell>Direction to sort results by; 0 is ascending, 1 is decending.</Table.Cell>
                </Table.Row>
            </Table.Body>

        </Table>

        <a name='q-parameter'/>
        <Header as='h4'>The 'q' (query) parameter</Header>

        <p>When supplied, this parameter allow you to query the data through a set of nested rules. This parameter must be a <b>base 64 encoded</b> JSON object of the following schema:</p>
        
        <code className='ui message'>
{`{
    "rules":[{
        field":"id",
        "value":5,
        "operator":"$gt"
    },{
        "rules":[{
            "field":"id",
            "value":5,
            "operator":"$lte"
        },{
            "field":"target",
            "value":"Test",
            "operator":"contains"
        }],
        "combinator":"and"
    }],
    "combinator":"or"
}`}
        </code>

        <p>Each rule must contain either:</p>
        <ul>
            <li><b>A Rule with the following parameters:</b>
                <ul>
                    <li>field: The name of the field</li>
                    <li>value: The value you want to be used in the comparison</li>
                    <li>operator: The operator you want to be use to make the comparison:</li>
                    <ul>
                        <li>$e: equals</li>
                        <li>$ne: does not equal</li>
                        <li>$gt: greater than</li>
                        <li>$gte: greater than or equal to</li>
                        <li>$lt: less than</li>
                        <li>$lte: less than or equal to</li>
                        <li>contains: same as an SQL %LIKE% <b>(string fields only)</b></li>
                        <li>doesNotContain: opposite of contains <b>(string fields only)</b></li>
                        <li>null: is null</li>
                        <li>notNull: is not null</li>
                    </ul>
                </ul>
            </li>
            <li><b>A nested array of rules and a combinator:</b>
                <ul>
                    <li>rules: an array of more rules</li>
                    <li>combinator: either 'and' or 'or', all rules in the rules parameter array will be joined with this combinator</li>
                </ul>
            </li>
        </ul>

        <Message positive>There is no limit to the depth of nested rules.</Message>

        <Header as='h4'>GET Response</Header>

        <p>A successful get request will respond with a JSON object with two parameters:</p>
        <ul>
            <li><b>stats</b>
                <ul>
                    <li><b>count</b>: the number of records returned</li>
                    <li><b>totalavailable</b>: the number of records available for this query if there was no paging</li>
                    <li><b>sortField</b>: the field the response has been sorted by</li>
                    <li><b>sortFieldDirection</b>: the direction the response has been sorted in (0 = Ascending, 1 = Descending)</li>
                    <li><b>perPage</b>: the number of results that are returned per page (may not match count on the last page of a set of results)</li>
                    <li><b>page</b>: the page number that has been returned</li>
                    <li><b>maxPage</b>: the maximum page number available based on the total available records and the current page size</li>
                    <li><b>search</b>: the search query that was applied to the results</li>
                </ul>
            </li>
            <li><b>records</b>: an array of the response records</li>
        </ul>

    

        <Header as='h5'>Example GET Response</Header>
        <code className='message ui'>
{`{
    "stats":{
        "count":3,
        "totalAvailable":3,
        "sortField":"dateRequested",
        "sortDirection":0,
        "perPage":100,
        "page":1,
        "maxPage":1,
        "search":""
    },
    "records":[
        {"id":1, "name":"test object", ...},
        {"id":2, "name":"another object", ...},
        ...
    ]
}`}
        </code>

        <Header as='h3'>PUT Requests</Header>

        <p>Use PUT request to edit records. PUT request should be sent to:</p>

        <code className='ui message'>PUT {path}/[MODEL NAME]/[ID OF RECORD TO EDIT]</code>

        <p>The body of a PUT request should be a JSON object containing the fields that you which to change on the record.</p>

        <p>Please see the relevant <a href='#models'>model specification</a> for which fields should be in the request for each model.</p>

        <p>A successful PUT request will respond with a full (all fields) updated JSON object of that record.</p>


        <Header as='h3'>POST Requests</Header>

        <p>Use POST request to create records. POST request should be sent to:</p>

        <code className='ui message'>POST {path}/[MODEL NAME]</code>

        <p>The body of a POST request should be a JSON object containing the fields that you which to set when creating the record.</p>

        <p>Please see the relevant <a href='#models'>model specification</a> for which fields should be in the request for each model.</p>

        <p>A successful POST request will respond with a full (all fields) JSON object of the newely created record.</p>

        
        <Header as='h3'>DELETE Requests</Header>

        <p>Use DELETE request to delete records. DELETE request should be sent to:</p>

        <code className='ui message'>DELETE {path}/[MODEL NAME]/[ID OF RECORD TO DELETE]</code>

        <p>Delete request should not have a body.</p>

        <p>A successful DELETE request will respond with a full (all fields) updated JSON object of that record.</p>

        <br />
        <Divider />

        <a name='authentication' />
        <Header as='h2'>Authentication</Header>

        <p>Depending on the configuration of each model in the system, you may not need to authenticate every request. Check the model definitions below for more details.</p>

        <p>Authentication is via a user token, generated by an authenticated login (email and password) or a long-life machine token.</p>

        <p>The token must be passed in an HTTP header called "Token". The token can also be accepted as part of the query string, however this is advised against.</p>

        <Header as='h3'>Option 1: User Token</Header>

        <p>To get a user token, you'll need to authenticate with an email address and password.</p>

        <Message as='code'>POST {`http${config.env=='production'?'s':''}://${config.apiHostName}`}/auth/login </Message>

        <Header as='h4'>POST Body Parameters</Header>

        <Table celled compact='very' size='small' singleLine>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Parameter</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Required</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>email</Table.Cell>
                    <Table.Cell>string</Table.Cell>
                    <Table.Cell><Label color='red'>Yes</Label></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>password</Table.Cell>
                    <Table.Cell>string</Table.Cell>
                    <Table.Cell><Label color='red'>Yes</Label></Table.Cell>
                </Table.Row>
            </Table.Body>

        </Table>

        <Header as='h4'>Example Login Response</Header>

        <p>On a succesful login, a user token will be returned as part of the response.</p>
        <code className='message ui'>
            {`
                {
                    "id":1,
                    "user_role_id":1,
                    "first_name":"Joe",
                    "last_name":"Blogs",
                    "email":"user@gmail.co.uk",
                    "active":true,
                    "roleName":"Manager",
                    "token":"b537d297da5....",
                    "tokenExpiry":"2020-05-08T16:47:54.768Z"
                }
            `}
        </code>

        <Header as='h4'>User Roles</Header>

        <p>Users are divided in to groups called roles. Access permissions in the system use these roles (along with the users ID) to determine which users have access to a certain resource.</p>

        <p>Depending on the configuration of you system, you may be able to change these roles in UI. Otherwise you'll need to submit a change request. </p>

        <Message info>User and role access permissions for each model have been tailored to the systems exact requirements. These can be changed on request.</Message>

        <Header as='h3'>Option 2: Long-life Machine Token</Header>

        <p>Machine tokens expire at a set date, normally a long time into the future. These tokens allow access to the API without needing to login.</p>

        <Message error>It's crucial that you keep you machine token secure. It should only reside on the server in an environment variable and never be commited to source or client applications.</Message>

        <p>Depending on the configuration of you system, you may be able to manage machine tokens in the UI. Otherwise you will need to submit a change request.</p>

        <p>Machine token's permissions are derive from user roles, in the same way that user's are.</p>

        <br />
        <Divider />

        <a name='errors' />
        <Header as='h2'>Errors</Header>

        <p>Errors in the system aim to give you an understanding of what went wrong.</p>

        <Table celled compact='very' size='small' singleLine>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Status Code</Table.HeaderCell>
                    <Table.HeaderCell>Meaning</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>200</Table.Cell>
                    <Table.Cell>No Error, all is well</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>400</Table.Cell>
                    <Table.Cell>There's something wrong with your request.</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>401</Table.Cell>
                    <Table.Cell>You're not logged in.</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>403</Table.Cell>
                    <Table.Cell>You're logged in, but not authorised to do this.</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>404</Table.Cell>
                    <Table.Cell>What you've asked for doesn't exist.</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>500</Table.Cell>
                    <Table.Cell>Something that's not your fault went wrong, please report this fault.</Table.Cell>
                </Table.Row>
            </Table.Body>

        </Table>

        <Header as='h4'>Error Response Body</Header>

        <p>Any returned error will include a plain english(ish) description of the error in the message parameter of the returned JSON object.</p>

        <code className='message ui'>
            {`{"message":"You are not authorised to access this resource."}`}
        </code>

        <br />
        <Divider />

        <a name='models'></a>
        <Header as='h2'>Models</Header>

        <ul>
            {orderedModelKeys.map(modelKey => {
                let model = config.models[modelKey]
                if(model.apiDocs !== false && permissions[modelKey].any === true) return <li key={modelKey}><a href={`#${modelKey}`}>{model.niceNamePlural} ({model.name})</a></li>
            })}
        </ul>

        {orderedModelKeys.map(modelKey => {
            let model = config.models[modelKey]
            if(model.apiDocs !== false && permissions[modelKey].any === true) 

            return (
                <React.Fragment key={modelKey}>
                    <a name={modelKey} />
                    <br /><Divider />
                    <Header as='h3' key={model.name}>{model.niceNamePlural} ({model.name})</Header>

                    {permissions[modelKey].get === true ?
                        <>

                            <Header as='h4'>Model Settings</Header>

                            <Table celled compact='very' size='small' definition singleLine>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell />
                                        <Table.HeaderCell>Value</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Model Name</Table.Cell>
                                        <Table.Cell>{model.name}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Default Sort Field</Table.Cell>
                                        <Table.Cell>{model.defaultSortField}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Default Sort Direction</Table.Cell>
                                        <Table.Cell>{model.defaultSortDirection?'Descending':'Ascending'}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Default Results Per Page</Table.Cell>
                                        <Table.Cell>{model.resultsPerPage}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>GET</Table.Cell>
                                        <Table.Cell>{model.getPublic === true?<Label color='red'>Public Route - No Authentication!</Label>:<Label color='green'>Authentication Required</Label>}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>

                            </Table>

                    
                            <Header as='h4'>GET Query Parameters</Header>
                            <p>These should be placed inside the (query string 'q' parameter).</p>

                            <Table celled compact='very' size='small' singleLine>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Parameter</Table.HeaderCell>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                        <Table.HeaderCell>Sortable?</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {Object.keys(model.fields).map(fieldKey=>{
                                        let field = model.fields[fieldKey]

                                        if(field.get === true)
                                            return <Table.Row key={fieldKey}>
                                                <Table.Cell>{field.apiName}</Table.Cell>
                                                <Table.Cell>{field.type}</Table.Cell>
                                                <Table.Cell>{field.sortable?<Label color='green'>Yes</Label>:<Label color='red'>No</Label>}</Table.Cell>
                                            </Table.Row>
                                    })}
                                </Table.Body>

                            </Table>

                            <Header as='h4'>GET Response Parameters</Header>

                            <Table celled compact='very' size='small' singleLine>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Parameter</Table.HeaderCell>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {Object.keys(model.fields).map(fieldKey=>{
                                        let field = model.fields[fieldKey]

                                        if(field.apiReturns !== false)
                                            return <Table.Row key={fieldKey}>
                                                <Table.Cell>{field.apiName}</Table.Cell>
                                                <Table.Cell>{field.type}</Table.Cell>
                                            </Table.Row>
                                    })}
                                </Table.Body>

                            </Table>
                        </>
                    :null}


                    {permissions[modelKey].put === true ?
                        <>
                            <Header as='h4'>PUT BODY Parameters</Header>

                            <Table celled compact='very' size='small' singleLine>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Parameter</Table.HeaderCell>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                        <Table.HeaderCell>Required</Table.HeaderCell>
                                        <Table.HeaderCell>Valid Values</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {Object.keys(model.fields).map(fieldKey=>{
                                        let field = model.fields[fieldKey]

                                        let fieldTypeLabel = field.type
                                        if(field.type=='relatedList'){
                                            fieldTypeLabel = 'integer array'
                                        }

                                        //if apiPut is undefined, use the value for post...
                                        if(field.apiPut === undefined || field.apiPut === null) field.apiPut = field.put 

                                        if(field.apiPut === true)
                                            return <Table.Row key={fieldKey}>
                                                <Table.Cell>{field.apiName}</Table.Cell>
                                                <Table.Cell>{fieldTypeLabel}{field.type=='enum'?<><br />({field.valueOptions.map(valueOption=>valueOption.value).join(', ')}</>:null}</Table.Cell>
                                                <Table.Cell>{field.required?<Label>Yes, in saved record</Label>:<Label>No</Label>}</Table.Cell>
                                                <Table.Cell>{field.valueOptions?field.valueOptions.map(o=>`"${o.value}"`).join(', '):null}</Table.Cell>
                                            </Table.Row>
                                    })}
                                </Table.Body>

                            </Table>
                        </>
                    :null}



                    {permissions[modelKey].post === true ?
                    <>
                        <Header as='h4'>POST BODY Parameters</Header>

                        <Table celled compact='very' size='small' singleLine>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Parameter</Table.HeaderCell>
                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                    <Table.HeaderCell>Required</Table.HeaderCell>
                                    <Table.HeaderCell>Valid Values</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {Object.keys(model.fields).map(fieldKey=>{
                                    let field = model.fields[fieldKey]

                                    let fieldTypeLabel = field.type
                                    if(field.type=='relatedList'){
                                        fieldTypeLabel = 'integer array'
                                    }

                                    //if apiPost is undefined, use the value for post...
                                    if(field.apiPost === undefined || field.apiPost === null) field.apiPost = field.post 

                                    if(field.apiPost === true)
                                        return <Table.Row key={fieldKey}>
                                            <Table.Cell>{field.apiName}</Table.Cell>
                                            <Table.Cell>{fieldTypeLabel}{field.type=='enum'?<><br />({field.valueOptions.map(valueOption=>valueOption.value).join(', ')}</>:null}</Table.Cell>
                                            <Table.Cell>{field.required?<Label color='red'>Yes</Label>:<Label>No</Label>}</Table.Cell>
                                            <Table.Cell>{field.valueOptions?field.valueOptions.map(o=>`"${o.value}"`).join(', '):null}</Table.Cell>
                                        </Table.Row>
                                })}
                            </Table.Body>

                        </Table>
                        <p>Client Version: {config.clientVersion}</p>
                    </>
                    :null}
                </React.Fragment>
            )
        })}
    </div>
}

export default Docs