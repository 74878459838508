import React, { useState, useContext } from 'react'
import { Popup, Input, Label, Search, Button, Icon } from 'semantic-ui-react'
import { ConfigContext } from "../../contexts/ConfigContext"
import useFetch from '../../hooks/useFetch'
import { useEffect } from 'react'

let currentTimeout

const FiltersLookupField = ({value, onChange, onChangeFullRecord, field, JSONFilters}) => {

    const { 0: config } = useContext(ConfigContext)
    //const [value, setValue] = useState('')
    const [searchInputValue, setSearchInputValue] = useState('')
    const [selectedSearchResultObject, setSelectedSearchResultObject] = useState({}) //used to detect changes when values are injected in to the form

    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)

    const { runFetch } = useFetch()

    //prevent null or undefined being passed (creating and uncontrolled component)
    if (!value) {
        value = ''
    }

    //empty search box if value has been removed
    useEffect(() => {
        if (!value) setSearchInputValue('')
    }, [value])

    //this first time we load, we might have an existing value to lookup the value for
    useEffect(() => {
        if (value && value != selectedSearchResultObject.id) {

            setLoading(true)

            //build the query required to get the item and prepare for API
            let query = {
                rules: [{
                    field: 'id',
                    value: value,
                    operator: '$e',
                }]
            }

            let fetchOptions = {
                queryString: {
                    q: query,
                    relatedRecords:false
                },
            }

            runFetch(`/${field.lookupModel}`, fetchOptions)
                .then(defaultRecord => {

                    if (defaultRecord && defaultRecord.records && defaultRecord.records[0]) {
                        defaultRecord = defaultRecord.records[0]

                        setSearchInputValue(config.models[field.lookupModel].referToByString(defaultRecord))
                        setSelectedSearchResultObject({ id: value, title: config.models[field.lookupModel].referToByString(defaultRecord) })
                    }
                    setLoading(false)
                })

        }
    }, [value])


    const onSearchChange = async (e) => {

        if(value){
            onChange(null)
            if(onChangeFullRecord) onChangeFullRecord(null)
        }

        if (e.target.value.length < 2) setResults([])

        let searchValue = e.target.value
        setSearchInputValue(searchValue)

        const runServerSearch = async () => {
            setLoading(true)
            let responseResults = []

            //console.log(JSONFilters)

            try {
                let fetchRes = await runFetch(`/${field.lookupModel}`, 
                    { 
                        queryString:{ 
                            search: searchValue,
                            relatedRecords:false,
                            q: JSONFilters
                        },
                    })

                if (fetchRes.records.length) {
                    responseResults = fetchRes.records.map(record => {
                        return { id: record.id, title: config.models[field.lookupModel].referToByString(record), record:record }
                    })
                }
            }
            catch (error) {
                console.log(error)
            }
            setLoading(false)

            setResults(responseResults)
        }

        //debounce
        clearTimeout(currentTimeout)
        currentTimeout = setTimeout(() => {
            runServerSearch(searchValue)
        }, 300)
    }

    const onResultSelect = (e, data) => {
        setSelectedSearchResultObject(data.result)
        if(onChange)onChange(data.result.id)
        if(onChangeFullRecord)onChangeFullRecord(data.result)
        setSearchInputValue(data.result.title)
    }
    
    // Build the field
    return (
        <>
            <div style={{ display: 'inline-block', marginRight:20 }}>
                <Search
                    value={searchInputValue}
                    placeholder={`Select ${field.niceName}`}
                    onSearchChange={onSearchChange}
                    onResultSelect={onResultSelect}
                    selectFirstResult={true}
                    //noResultsMessage='No Results...'
                    showNoResults={false}
                    scrolling
                    loading={loading}
                    results={results}
                    input={{ style: { width: 400 }, icon: 'search', iconPosition: 'left', onClick: (e) => { e.target.select() } }}
                />
            </div>
            
        </>
    );
}

export default FiltersLookupField