import React, { useState } from 'react'
import { useField, splitFormProps } from "react-form"
import { Dropdown, Label } from 'semantic-ui-react'
import { useEffect } from 'react';

const InputField = (props) => {
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    let [loading, setLoading] = useState(false)

    // Use the useField hook with a field and field options
    // to access field state
    let {
        form,
        meta: { error, isTouched, isBlured, isValidating, model, fieldConfig },
        getInputProps,
        value,
        setValue,
        setMeta
    } = useField(field, fieldOptions);

    //prevent null or undefined being passed (creating and uncontrolled component)
    if (value==undefined||value==null) value = ''

    let showError = ((isTouched && error && isBlured) || (isTouched && !isBlured && props.everSubmitted && error))

    const handleChange = async (e, data) => {

        setValue(data.value)

        setMeta(oldMeta => {
            let newMeta = Object.assign({}, oldMeta)
            newMeta.isBlured = true
            return newMeta
        })

        if(props.transformValues){
            setLoading(true)
            props.transformValues({values:Object.assign({},form.values,{[field]:data.value}), fieldName:field})
            .then(()=>{
                setLoading(false)
            })
        }

    }

    //add none if not required...
    let options = fieldConfig.valueOptions
    if(!fieldConfig.required) options = [{key:'null', value:null, text:'None'}].concat(fieldConfig.valueOptions)
    

    // Build the field
    return (
        <>
            <div style={{ float: 'left' }}>
                <Dropdown 
                    closeOnChange={true}
                    value={value} 
                    selection 
                    options={options} 
                    style={{ width: 400 }} 
                    onChange={handleChange} 
                    loading={loading||isValidating||props.formLoading}
                    disabled={props.formLoading}
                    search
                    selectOnBlur={false}
                />
            </div>
            <div style={{clear:'both'}}>
                {showError ? (
                    <Label pointing='above' color='red'>
                        {error}
                    </Label>
                ) : null}
            </div>

        </>
    );
}

export default InputField