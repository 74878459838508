import React, { useContext, useState, useLayoutEffect } from 'react';
import { Icon, Image, Label } from 'semantic-ui-react'
import MenuItem from '../../components/menuItem'
import { ConfigContext } from '../../contexts/ConfigContext'
import { UIContext } from '../../contexts/UIContext'
import { Link } from "react-router-dom"
import { useLocation } from 'react-router-dom'
import { LoginContext } from '../../contexts/LoginContext';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

const LeftNav = () => {
    const [config] = useContext(ConfigContext)
    const [login] = useContext(LoginContext)
    const { menuOpen, setMenuOpen } = useContext(UIContext)
    const location = useLocation()
    const [width, height] = useWindowSize()

    const dashboards = {}
    
    if(config.dashboards){
        Object.keys(config.dashboards).map(dashboardKey => {
            let dashboard = config.dashboards[dashboardKey]

            if (!dashboard.clientPermission || dashboard.clientPermission({ login }) === true){

                let dashModel = '_main'
                if(dashboard.model) dashModel = dashboard.model

                //create storage object if doesn't exist
                if(!dashboards[dashModel]) dashboards[dashModel] = []

                dashboards[dashModel].push({
                    label: dashboard.niceName,
                    to: `/dashboards/${dashboard.name}`,
                    active: location.pathname.indexOf(`/dashboards/${dashboard.name}`) > -1
                })
            }
        })
    }

    let staging = window.location.hostname.indexOf('staging.') > -1

    let orderedMenuModelKeys = Object.keys(config.models).sort((a, b)=>{
        let aOrder = !config.models[a].displayOrder?10000000:config.models[a].displayOrder
        let bOrder = !config.models[b].displayOrder?10000000:config.models[b].displayOrder
        return aOrder - bOrder;
    })

    return <div className={`leftNav ${menuOpen ? 'open' : 'closed'}`}>
        <div className='openCloseLeftNav'>
            <Icon onClick={() => { setMenuOpen(!menuOpen) }} circular name='hand point left outline' />
        </div>

        <div className='logoHolder' style={{backgroundImage: `url("https://api.xview.app/instances/custom/getLogo?clientHostName=${config.clientHostName}")`}} >
            {/* <img className='logo' src={`https://api.xview.app/instances/custom/getLogo?clientHostName=${config.clientHostName}`} /> */}
        </div>

        <div className='loginInfo'>
            <Label className='nameLabel' as={Link} to={`/users/${login.id}`} image>
                <img src='/avatar.svg' />
                {login.first_name} {login.last_name} - {login.roleName}
            </Label>
        </div>

        <div className='menu' style={{height:height-180}}>
            {dashboards['_main'] && dashboards['_main'].length ?
                <MenuItem
                    menuOpen={menuOpen}
                    icon='line graph'
                    active={location.pathname == `/dashboards`}
                    key="dashboards"
                    label="Dashboards"
                    iconOnly={menuOpen}
                    children={dashboards['_main']}
                />
                : null}
            {orderedMenuModelKeys.map(model => {
                
                model = config.models[model]

                //is this user allowed to see this model?
                let permission = true
                if (config.globalClientPermission) permission = config.globalClientPermission({ login, model:model.name, method:'GET' })
                if(permission === true){
                    if (model.clientGetPermission) permission = model.clientGetPermission({ login })
                }

                let active = location.pathname==`/${model.name}` || location.pathname.indexOf(`/${model.name}/`) == 0

                let mainMenuComputed = false
                if(typeof model.mainMenu === 'function'){
                    try{
                        mainMenuComputed = model.mainMenu({login})
                    }
                    catch(e){
                        console.log(`Failed to run mainMenu function for ${model.name}, defaulting to false`)
                        console.log(e)
                    }
                }
                else mainMenuComputed = model.mainMenu

                if (mainMenuComputed !== false && permission === true)
                    return <MenuItem
                        menuOpen={menuOpen}
                        icon={model.icon}
                        active={active}
                        key={model.name}
                        label={model.niceNamePlural}
                        to={`/${model.name}`}
                        iconOnly={menuOpen}
                        children={dashboards[model.name]||undefined}
                    />
                else return null
            })}
        </div>
        
        <div className='poweredBy'>
            <img src='https://api.xview.app/instances/custom/getLogo?clientHostName=admin.xview.app' />
        </div>
        
    </div>
}

export default LeftNav