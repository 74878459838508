import React, { useContext, useState } from 'react';
import { Modal, Button } from 'semantic-ui-react'
import { UIContext } from "../../contexts/UIContext"
import { ErrorsContext } from "../../contexts/ErrorsContext"
import useFetch from '../../hooks/useFetch'
import EditForm from "../editForm"

const BulkDeleteView = () => {

    const { currentBulkDeletion, completeBulkDeletion, refreshData } = useContext(UIContext)
    const [bulkDeletionState, setBulkDeletionState] = useState('confirmation')

    let { runFetch } = useFetch()

    const clearBulkDeletion = ()=>{
        completeBulkDeletion()
        setBulkDeletionState('confirmation')
        refreshData()
    }

    const runBulkDeletion = async () => {
        setBulkDeletionState('running')

        const options = {
            method: 'DELETE',
            body: JSON.stringify({inputs:Array.from(currentBulkDeletion.inputs)})
        }
        
        let fetchRes = await runFetch(`/${currentBulkDeletion.model.name}/bulkDelete`, options)
        
        //run callback
        if(fetchRes && currentBulkDeletion.onDelete) currentBulkDeletion.onDelete()

        clearBulkDeletion()
        return

    }

    return <>
        {currentBulkDeletion ?
            <Modal open={true} color='red'>
                {bulkDeletionState == 'confirmation' ?
                    <>
                        <Modal.Header>
                            BULK DELETE {currentBulkDeletion.inputs.size} {currentBulkDeletion.model.niceNamePlural}?
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                Are you sure you want to delete {currentBulkDeletion.inputs.size} {currentBulkDeletion.model.niceNamePlural}? This action is irreversible.
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={completeBulkDeletion}>Cancel</Button>
                            <Button negative onClick={runBulkDeletion}>Continue</Button>
                        </Modal.Actions>
                    </>
                    : null}
                {bulkDeletionState == 'running' ?
                    <>
                        <Modal.Header>
                            {currentBulkDeletion.model.niceName} - Deleting...
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                Processing...
                            </Modal.Description>
                        </Modal.Content>
                    </>
                    : null}
                
            </Modal>
            : null}
    </>
}

export default BulkDeleteView