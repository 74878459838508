import React, { useState } from 'react'
import { useField, splitFormProps } from "react-form"
import { Input, Label, TextArea, Icon } from 'semantic-ui-react'
import { useEffect } from 'react';
//import ReactSummernote from 'react-summernote';
//import 'react-summernote/dist/react-summernote.css'; // import styles


//https://github.com/summernote/react-summernote
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/tooltip';
// import './htmlField.scss';


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

let inputDebounce = null


const HtmlField = (props) => {
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    let [loading, setLoading] = useState(false)
    //store a local form value for performance (debonce update actual value)
    let [localInputValue, setLocalInputValue] = useState('')

    // Use the useField hook with a field and field options
    // to access field state
    let {
        form,
        meta: { error, isTouched, isBlured, isValidating, fieldConfig, model },
        getInputProps,
        value,
        setValue,
        setMeta
    } = useField(field, fieldOptions);

    //prevent null or undefined being passed (creating and uncontrolled component)
    if (!value) value = ''


    const onBlur = () => {
        setMeta(oldMeta => {
            let newMeta = Object.assign({}, oldMeta)
            newMeta.isBlured = true
            return newMeta
        })

        if(props.transformValues){
            setLoading(true)
            props.transformValues({values:form.values, fieldName:field})
            .then(()=>{
                setLoading(false)
            })
        }
    }

    let showError = ((isTouched && error && isBlured) || (isTouched && !isBlured && props.everSubmitted && error))

    const handleChange = ( event, editor ) => {
        let inputValue = editor.getData();
        
        if (inputValue == '') inputValue = null

        setLocalInputValue(inputValue)

        if(inputDebounce) {
            clearTimeout(inputDebounce)
        }
        inputDebounce = setTimeout(()=>{      
            setValue(inputValue)
        },300)
    }

    //update the local value with form value if changed
    useEffect(()=>{
        if(value != localInputValue)
            setLocalInputValue(value)
    },[value])

    // Build the field
    return (
        <>
            <div style={{ float: 'left', width:'100%' }} className='use-bootstrap' >
                <CKEditor
                    config= {{
                        //plugins: [ 'MediaEmbed' ],
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList', 'indent', 'outdent', '|', 'link',  'mediaEmbed' ],
                        mediaEmbed: {
                            previewsInData:true
                        }
                    }}
                    editor={ ClassicEditor }
                    data={value}
                    onReady={ editor => {
                    } }
                    onChange={handleChange}
                    onBlur={onBlur}
                    disabled={props.formLoading}
                />
                {" "}
            </div>
            {isValidating||loading ?
                <div style={{ float: 'left', width: 30, position:'relative', top:2, left:10 }}>
                    <Icon loading color='grey' name='circle notched' />
                </div>
                : null}
            <div>
                {showError ? (
                    <Label pointing='above' color='red'>
                        {error}
                    </Label>
                ) : null}
            </div>

        </>
    );
}

export default HtmlField