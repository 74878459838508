import React, { useState } from 'react'
import { useField, splitFormProps } from "react-form"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { Input, Label, Icon } from 'semantic-ui-react'
import Moment from 'moment'
import useTransformValues from '../../hooks/useTransformValues'

const TimeField = (props) => {
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    let [loading, setLoading] = useState(false)

    // Use the useField hook with a field and field options
    // to access field state
    const {
        form,
        meta: { error, isTouched, isValidating, fieldConfig, model },
        getInputProps,
        value,
        setValue
    } = useField(field, fieldOptions);

    const handleChange = (newValue) => {

        let time = newValue.toLocaleTimeString();
        let splitTime = time.split(':')
        time = `${splitTime[0]}:${splitTime[1]}`

        setValue(time||'')

        if (props.transformValues) {
            setLoading(true)
            props.transformValues({values:{ ...form.values, [field]: time }, fieldName:field})
                .then(() => {
                    setLoading(false)
                })
        }
    }

    //just need any old date forthe picker we don't use
    let datePickerValue = Moment().toDate()
    let inputValue = value

    //value might from db and we need to remove seconds
    if(value && value.split(':').length == 3) {
        let splitTime = value?value.split(':'):['00','00']
        let inputValue = `${splitTime[0]}:${splitTime[1]}`
        setValue(`${splitTime[0]}:${splitTime[1]}`)
    }

    if(!value) setValue('00:00')
    


    const CustomInput = React.forwardRef(({ value, onClick }, refs) => (
        <Input 
            value={inputValue} 
            error={error && isTouched} 
            loading={isValidating || loading || props.formLoading} 
            onClick={onClick} 
            onKeyPress={(e)=>{
                if(e.key == 'Enter')e.preventDefault()
            }}
        />
    ));

    // Build the field
    return (
        <>
            <div style={{ float: 'left', clear: 'both' }}>
                <DatePicker
                    isClearable
                    selected={datePickerValue}
                    onChange={handleChange}
                    customInput={<CustomInput />}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    disabled={props.formLoading}
                    timeCaption="Time"
                    dateFormat="hh:mm"
                />
            </div>
            <div style={{ clear: 'both' }}>
                {isTouched && error ? (
                    <Label color='red' pointing='above'>
                        {error}
                    </Label>
                ) : null}
            </div>
        </>
    );
}

export default TimeField