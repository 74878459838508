import React, { useContext, useState } from 'react';
import { Modal, Button } from 'semantic-ui-react'
import { UIContext } from "../../contexts/UIContext"
import { ConfigContext } from "../../contexts/ConfigContext"
import { LoginContext } from "../../contexts/LoginContext"
import { ErrorsContext } from "../../contexts/ErrorsContext"
import useFetch from '../../hooks/useFetch'
import EditForm from "../editForm"

const ActionView = () => {

    const { currentAction, completeAction, refreshData } = useContext(UIContext)
    const { 0: config } = useContext(ConfigContext)
    const { 0: login } = useContext(LoginContext)

    const [actionState, setActionState] = useState('confirmation')
    const [result, setResult] = useState({})

    let { runFetch } = useFetch()

    const clearAction = ()=>{
        completeAction()
        setActionState('confirmation')
        setResult({})
        refreshData()
    }

    const runAction = async () => {
        setActionState('running')

        

        const options = {
            method: 'PUT',
            body: JSON.stringify({inputs:Array.from(currentAction.inputs), parentModelName:currentAction.parentModelName, parentRecordId: currentAction.parentRecordId})
        }
        
        let fetchRes = await runFetch(`/${currentAction.model.name}/actions/${currentAction.action.name}`, options)
        
        if(!fetchRes){
            clearAction()
            return
        }

        setResult(fetchRes)

        //if success and action doesn't want a confirmation
        if(fetchRes.type=='success'&&currentAction.action.confirmationDialog === false){
            clearAction()
            return
        }
        //else show confirmation/error dialog
        else setActionState('result')
    }

    return <>
        {currentAction ?
            <Modal open={true} closeOnDimmerClick={false}>
                {actionState == 'confirmation' ?
                    <>
                        <Modal.Header>
                            {currentAction.action.niceName} - Are you sure?
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                {currentAction.action.areYouSureMessage(currentAction.inputs)}
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={completeAction}>Cancel</Button>
                            <Button positive onClick={runAction}>Continue</Button>
                        </Modal.Actions>
                    </>
                    : null}
                {actionState == 'running' ?
                    <>
                        <Modal.Header>
                            {currentAction.action.niceName} - Processing...
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                LOADING...
                            </Modal.Description>
                        </Modal.Content>
                    </>
                    : null}
                {actionState == 'result' ?
                    <>
                        <Modal.Header>
                            {currentAction.action.niceName} - {result&&result.type=='success'?'Complete':'ERROR'}
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                {result&&result.message}
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            {result.type=='success' && result.links?
                                    result.links.map(link=>{
                                        return <Button href={link.to} target='_blank'>{link.text}</Button>
                                    })
                            :null}    
                            <Button onClick={clearAction}>Done</Button>
                        </Modal.Actions>
                    </>
                    : null}
            </Modal>
            : null}
    </>
}

export default ActionView