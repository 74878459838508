import React, { useContext, useState, useEffect, useRef } from 'react';
import { Breadcrumb, Icon, Statistic, Label, Message, Segment, Header, Grid, Input, Dropdown } from 'semantic-ui-react'
import { useParams, Link, useHistory } from "react-router-dom"
import MenuItem from '../../components/menuItem'
import { ConfigContext } from '../../contexts/ConfigContext'
import { UIContext } from '../../contexts/UIContext'
import { LoginContext } from '../../contexts/LoginContext'
import useFetch from '../../hooks/useFetch'
import Widget from './Widget'
import Moment from 'moment';


const Dashboard = () => {
    const [config] = useContext(ConfigContext)
    const [login] = useContext(LoginContext)

    const { dashboardName } = useParams()
    const dashboard = config.dashboards[dashboardName]

    const [startDate,setStartDate] = useState(null)
    const [endDate,setEndDate] = useState(null)
    const [dateFilter,setDateFilter] = useState(null)

    if(dashboard.niceName) document.title = `${dashboard.niceName}`

    //get the item from the database
    let { runFetch } = useFetch()

    let tempWidgetData = {}
    const [widgetData, setWidgetData] = useState({})

    useEffect(() => {
        if(dashboard.datePicker && (!startDate || !endDate)) return;
        Object.keys(dashboard.widgets).map(widgetKey => {
            let widget = dashboard.widgets[widgetKey]

            if(widget.clientPermission && widget.clientPermission({login}) !== true) return null

            let data = runFetch(`/dashboards/${dashboardName}/${widgetKey}?startDate=${startDate}&endDate=${endDate}`).then(res => {
                tempWidgetData[widgetKey] = res.result

                tempWidgetData = Object.assign({}, tempWidgetData)

                setWidgetData(tempWidgetData)
            })
        })
    }, [startDate, endDate])

    useEffect(()=>{
        if(dashboard.datePresets){
            //find the default filter...
            for(let dp of dashboard.datePresets){
                if(dp.default){
                    setDateFilter(dp)
                    setStartDate(dp.start)
                    setEndDate(dp.end)
                }
                break;
            }
        }
    },[])

    return <div>
        <Breadcrumb>
            <Breadcrumb.Section><Link to='/'>Home</Link></Breadcrumb.Section>
            <Breadcrumb.Divider icon='right arrow' />
            <Breadcrumb.Section active>{dashboard.niceName}</Breadcrumb.Section>
        </Breadcrumb>
        
        <Header as='h1'>{dashboard.niceName}</Header>

        {dashboard.datePicker&&<Segment>
            Date Range {"  "}
            <Input type="date" value={startDate} onChange={e=>{setStartDate(e.currentTarget.value); setDateFilter(null);}}/>
            {" - "}
            <Input type="date" value={endDate} onChange={e=>{setEndDate(e.currentTarget.value); setDateFilter(null);}}/>

            <div style={{ display: 'inline-block', marginLeft: 20 }}>
            Currently Showing: {' '}
                <Dropdown
                    value={dateFilter}
                    text={dateFilter?dateFilter.name:"Custom Range"}
                    inline
                    header='Preset Filters'
                    options={dashboard.datePresets.map(dp=>{
                        return {text: dp.name, value: dp}
                    })}
                    //defaultValue={'all'}
                    onChange={(e,data)=>{
                        setDateFilter(data.value)
                        setStartDate(data.value.start)
                        setEndDate(data.value.end)
                    }}
                />
            </div>
        </Segment>}

        <Grid stackable columns={3} className='dashboardGrid'>

            {Object.keys(dashboard.widgets).map((widgetKey, i) => {

                let widget = dashboard.widgets[widgetKey]

                if(widget.clientPermission && widget.clientPermission({login}) !== true) return null

                let width = widget.width || 5

                return <Grid.Column key={i} textAlign='center' width={width}>
                    <Segment>
                        <Widget widget={widget} data={widgetData[widgetKey]||null} startDate={startDate} endDate={endDate}/>
                    </Segment>

                </Grid.Column>
            })}

        </Grid>

    </div>
}

export default Dashboard