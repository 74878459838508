import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useHistory, useLocation } from "react-router-dom"
import { Breadcrumb, Header, Icon } from 'semantic-ui-react'
import { ConfigContext } from "../../contexts/ConfigContext"
import { ErrorsContext } from "../../contexts/ErrorsContext"
import { LoginContext } from "../../contexts/LoginContext"
import ListViewComponentSelector from '../listViewComponentSelector'


const ListView = () => {

    const { 0: config } = useContext(ConfigContext)
    const { 0: login } = useContext(LoginContext)
    const { 1: addError } = useContext(ErrorsContext)

    const { modelName, filterFromUrl } = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const filter = searchParams.get('filter') || filterFromUrl;

    const model = config.models[modelName]

    const [permission,setPermission] = useState(false)

    //check that we can view this model
    let history = useHistory()

    useEffect(()=>{
        if(model.clientGetPermission){
            let permissionCheckResult = model.clientGetPermission({login})
            if(permissionCheckResult !== true){
                addError(permissionCheckResult)
                return history.push("/")
            }
        }
        setPermission(true)
    },[])

    history.listen(()=>{
        window.scrollTo(0, 0)  
    })

    if(model.niceNamePlural) document.title = model.niceNamePlural

    return <>

        <Breadcrumb>
            <Breadcrumb.Section><Link to='/'>Home</Link></Breadcrumb.Section>
            <Breadcrumb.Divider icon='right arrow' />
            <Breadcrumb.Section active>{model.niceNamePlural}</Breadcrumb.Section>
        </Breadcrumb>

        <Header as='h1'>
            <Icon name={model.icon} />
            <Header.Content>{model.niceNamePlural}</Header.Content>
        </Header>

        {permission?<ListViewComponentSelector model={model} key={model.name} filterFromUrl={filter}/>:null}
    </>
}

export default ListView