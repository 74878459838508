import React, { useContext } from 'react'
import { Icon, Button } from 'semantic-ui-react'
import { ConfigContext } from '../../contexts/ConfigContext'
import { LoginContext } from '../../contexts/LoginContext'
import { Link } from "react-router-dom"
import GlobalSearch from '../globalSearch'

const TopNav = () => {

    const [config] = useContext(ConfigContext)
    const {2:logout} = useContext(LoginContext)

    let staging = window.location.hostname.indexOf('staging.') > -1

    return <div className='topNav' style={{backgroundColor:staging?'#FF0000':config.primaryColour}}>
        {staging?
            <span>STAGING ENVIRONMENT - </span>
            :null
        }
        {config.niceName}

        <div style={{position:'absolute', top:1, left:252}}>
            <GlobalSearch />
        </div>

        <div className='topNavButtons'>
        <Button icon size='mini' style={{background:'#FFF', color:'#000'}} className='topNavButton' as={Link} to='/docs'>
            <Icon name='connectdevelop' />
        </Button>
        <Button icon size='mini' style={{background:'#FFF', color:'#000'}} className='topNavButton' onClick={()=>{logout()}}>
            <Icon name='log out' />
        </Button>
        </div>
    </div>
}

export default TopNav