import React, { useContext, useState } from 'react';
import { Modal, Button } from 'semantic-ui-react'
import { UIContext } from "../../contexts/UIContext"
import { ErrorsContext } from "../../contexts/ErrorsContext"
import useFetch from '../../hooks/useFetch'
import EditForm from "../editForm"

const DeletionView = () => {

    const { currentDeletion, completeDeletion, refreshData } = useContext(UIContext)
    const [deletionState, setDeletionState] = useState('confirmation')

    let { runFetch } = useFetch()

    const clearDeletion = ()=>{
        completeDeletion()
        setDeletionState('confirmation')
        refreshData()
    }

    const runDeletion = async () => {
        setDeletionState('running')

        const options = {
            method: 'DELETE',
        }
        
        let fetchRes = await runFetch(`/${currentDeletion.model.name}/${currentDeletion.id}`, options)
        
        //run callback
        if(fetchRes && currentDeletion.onDelete) currentDeletion.onDelete()

        clearDeletion()
        return

    }

    return <>
        {currentDeletion ?
            <Modal open={true} color='red'>
                {deletionState == 'confirmation' ?
                    <>
                        <Modal.Header>
                            DELETING {currentDeletion.model.niceName}?
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                Are you sure you want to delete this {currentDeletion.model.niceName}? This action is irreversible.
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={completeDeletion}>Cancel</Button>
                            <Button negative onClick={runDeletion}>Continue</Button>
                        </Modal.Actions>
                    </>
                    : null}
                {deletionState == 'running' ?
                    <>
                        <Modal.Header>
                            {currentDeletion.model.niceName} - Deleting...
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                Processing...
                            </Modal.Description>
                        </Modal.Content>
                    </>
                    : null}
                
            </Modal>
            : null}
    </>
}

export default DeletionView