import React, { useContext } from 'react';
import { Grid, Segment, Button, Input, Form as SForm } from 'semantic-ui-react'
import { ConfigContext } from '../../contexts/ConfigContext'
import { LoginContext } from '../../contexts/LoginContext'
import { useForm, useField, splitFormProps } from "react-form"
import useFetch from '../../hooks/useFetch'


const LoginInputField = (props) => {
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    // Use the useField hook with a field and field options
    // to access field state
    let {
        form,
        meta: { error, isTouched, isBlured, isValidating },
        getInputProps,
        value,
        setMeta
    } = useField(field, fieldOptions);

    //prevent null or undefined being passed (creating and uncontrolled component)
    if (!value) value = ''

    const onBlur = () => {
        setMeta(oldMeta => {
            let newMeta = Object.assign({}, oldMeta)
            newMeta.isBlured = true
            return newMeta
        })
    }

    let showError = ((isTouched && error && isBlured) || (isTouched && !isBlured && form.meta.everSubmitted && error))

    // Build the field
    return (
        <>
            <Input type={field == 'password' ? 'password' : 'text'} input={{ onBlur }} style={{ width: '100%' }} {...getInputProps({ ...rest })} loading={isValidating} error={showError ? true : false} value={value} />
        </>
    );
}


const LoginForm = () => {

    let { runFetch } = useFetch()
    const {1:setLogin} = useContext(LoginContext)

    // Use the useForm hook to create a form instance
    const {
        Form,
        handleSubmit,
        setMeta,
        meta: { isSubmitting, canSubmit }
    } = useForm({
        onSubmit: async (values, instance) => {

            values.email = values.email.trim()
            values.password = values.password.trim()

            const options = {
                method: 'POST',
                body: JSON.stringify(values),
            }
    
            let fetchRes = await runFetch(`/auth/login`, options)
    
            if(fetchRes){
                setLogin(fetchRes)
            }
            
        },
        debugForm: false
    });

    return <Form>
        <SForm as='div'>
            <SForm.Field>
                <LoginInputField placeholder='Email Address' defaultValue='' field='email' style={{ width: '100%' }} />
            </SForm.Field>
            <SForm.Field>
                <LoginInputField placeholder='Password' defaultValue='' field='password' style={{ width: '100%' }} />
            </SForm.Field>

            <Button fluid size='large' >Login</Button>
        </SForm>
    </Form>
}

const Login = () => {

    const [config] = useContext(ConfigContext)

    return <>
            <div className='loginForm'>
                {/*
          Heads up! The styles below are necessary for the correct render of this example.
          You can do same with CSS, the main idea is that all the elements up to the `Grid`
          below must have a height of 100%.
        */}

                <Grid
                    textAlign='center'
                    style={{ paddingTop: '100px' }}
                    verticalAlign='middle'
                >
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Segment basic>
                            <img className='logo' src={`https://api.xview.app/instances/custom/getLogo?clientHostName=${config.clientHostName}`} />
                        </Segment>

                        <Segment style={{borderTop:`2px solid ${config.primaryColour}`}}>

                            <LoginForm />

                        </Segment>

                    </Grid.Column>
                </Grid>
            </div>
        </>
}

export default Login