import React, { useState, useContext } from 'react'
import { useField, splitFormProps } from "react-form"
import { Popup, Input, Label, Search, Button, Icon } from 'semantic-ui-react'
import { ConfigContext } from "../../contexts/ConfigContext"
import useFetch from '../../hooks/useFetch'
import { UIContext } from '../../contexts/UIContext'
import { useEffect } from 'react'

let currentTimeout
const searches = []

const LookupField = (props) => {

    const { 0: config } = useContext(ConfigContext)
    const { addToEditModalQueue } = useContext(UIContext)
    const [searchInputValue, setSearchInputValue] = useState('')
    const [selectedSearchResultObject, setSelectedSearchResultObject] = useState({}) //used to detect changes when values are injected in to the form

    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)
    

    const { runFetch } = useFetch()

    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    // Use the useField hook with a field and field options
    // to access field state
    let {
        form,
        meta: { error, isTouched, isValidating, isBlured, fieldConfig, model, hidden, parentModel, editRecord, parentRecord },
        getInputProps,
        value,
        setValue,
        setMeta
    } = useField(field, fieldOptions);

    //prevent null or undefined being passed (creating and uncontrolled component)
    if (!value) {
        value = ''
    }

    //empty search box if value has been removed
    useEffect(() => {
        if (!value && searchInputValue.length > 1) setSearchInputValue('')
    }, [value])

    const lookupModel = config.models[fieldConfig.lookupModel]

    //this first time we load, we might have an existing value to lookup the value for
    useEffect(() => {
        if (value && value != selectedSearchResultObject.id) {

            setLoading(true)

            //build the query required to get the item and prepare for API
            let query = {
                rules: [{
                    field: 'id',
                    value: value,
                    operator: '$e',
                }]
            }

            let trimmedFormValuesForServerSideFilterQuery = {}
            //remove long fields from the serverside filter query, too long for get
            //AND if array of objects, just shrink down to list of IDs
            for(let f of Object.keys(form.values)){
                let v = form.values[f]
                if(typeof v == "string" && v.length>100){
                    //do nothing
                }
                //if array and has an object with id param
                else if(Array.isArray(v) && v[0]?.id){
                    trimmedFormValuesForServerSideFilterQuery[f] = v.map(d=>d.id)
                }
                else trimmedFormValuesForServerSideFilterQuery[f] = v
            }

            let fetchOptions = {
                queryString: {
                    q: query,
                    relatedRecords:false,
                    filterQuery: fieldConfig.serverFilterQuery?JSON.stringify({field, model:model.name, recordId: editRecord?.id, values:trimmedFormValuesForServerSideFilterQuery, parentModel:parentModel?parentModel.name:null, parentRecordId:parentRecord?parentRecord.id:null}):undefined
                },
            }

            runFetch(`/${fieldConfig.lookupModel}`, fetchOptions)
                .then(defaultRecord => {

                    if (defaultRecord && defaultRecord.records && defaultRecord.records[0]) {
                        defaultRecord = defaultRecord.records[0]

                        setSearchInputValue(lookupModel.referToByString(defaultRecord))
                        setSelectedSearchResultObject({ id: value, title: lookupModel.referToByString(defaultRecord) })
                    }
                    setLoading(false)
                })

        }
    }, [value])

    //copmlie the filter for the lookup
    const compiledFilter = fieldConfig.filterQuery?fieldConfig.filterQuery({values:form.values, parentModel, editRecord, parentRecord}):undefined
    
    //has the filter changed?
    useEffect(()=>{
        if(value && fieldConfig.filterQuery){
            //is our current selection still valid?
            //add the selected id into the compiledFilter
            let filterWithSelectedId = {
                rules: [
                    compiledFilter,
                    {
                        field: 'id',
                        value: value,
                        operator: '$e',
                    }
                ],
                combinator: "and"
            }

            let trimmedFormValuesForServerSideFilterQuery = {}
            //remove long fields from the serverside filter query, too long for get
            //AND if array of objects, just shrink down to list of IDs
            for(let f of Object.keys(form.values)){
                let v = form.values[f]
                if(typeof v == "string" && v.length>100){
                    //do nothing
                }
                //if array and has an object with id param
                else if(Array.isArray(v) && v[0]?.id){
                    trimmedFormValuesForServerSideFilterQuery[f] = v.map(d=>d.id)
                }
                else trimmedFormValuesForServerSideFilterQuery[f] = v
            }
            
            //query the lookup with the current value to check it's still valid
            runFetch(`/${fieldConfig.lookupModel}`, {
                queryString:{
                    q:filterWithSelectedId,
                    relatedRecords:false,
                    filterQuery: fieldConfig.serverFilterQuery?JSON.stringify({field, model:model.name, recordId: editRecord?.id, values:trimmedFormValuesForServerSideFilterQuery, parentModel:parentModel?parentModel.name:null, parentRecordId:parentRecord?parentRecord.id:null}):undefined
                }})
            .then(res=>{
                //if there are no results set value to null
                if(res.records.length === 0) setValue(null)
            })
        }

    },[JSON.stringify(compiledFilter)])



    const onSearchChange = async (e) => {

        if (e.target.value.length < 2) setResults([])

        let searchValue = e.target.value
        setSearchInputValue(searchValue)

        if(value){
            setValue(null)
            //run any form transformations
            if (props.transformValues) {
                setLoading(true)
                props.transformValues({values:{ ...form.values, [field]: null }, fieldName:field})
                    .then(() => {
                        setLoading(false)
                    })
            }
        }

        const runServerSearch = async () => {
            const searchId = Date.now()
            searches.push(searchId)

            setLoading(true)
            let responseResults = []

            let trimmedFormValuesForServerSideFilterQuery = {}
            //remove long fields from the serverside filter query, too long for get
            //AND if array of objects, just shrink down to list of IDs
            for(let f of Object.keys(form.values)){
                let v = form.values[f]
                if(typeof v == "string" && v.length>100){
                    //do nothing
                }
                //if array and has an object with id param
                else if(Array.isArray(v) && v[0]?.id){
                    trimmedFormValuesForServerSideFilterQuery[f] = v.map(d=>d.id)
                }
                else trimmedFormValuesForServerSideFilterQuery[f] = v
            }

            try {
                let fetchRes = await runFetch(`/${fieldConfig.lookupModel}`, 
                    { 
                        queryString:{ 
                            search: searchValue,
                            q: compiledFilter,
                            relatedRecords:false,
                            filterQuery: fieldConfig.serverFilterQuery?JSON.stringify({field, model:model.name, recordId: editRecord?.id, values:trimmedFormValuesForServerSideFilterQuery, parentModel:parentModel?parentModel.name:null, parentRecordId:parentRecord?parentRecord.id:null}):undefined,
                            perPage:lookupModel.maxResultsPerPage||lookupModel.resultsPerPage,
                        },
                    })

                if (fetchRes.records.length) {
                    responseResults = fetchRes.records.map(record => {
                        return { id: record.id, title: fieldConfig.lookupReferToByString?fieldConfig.lookupReferToByString(record):lookupModel.referToByString(record) }
                    })
                }
            }
            catch (error) {

            }
            //only update results if this is the latest search...
            if(searchId == searches[searches.length-1]){
                
                setLoading(false)
                setResults(responseResults)
            }
        }

        //debounce
        clearTimeout(currentTimeout)
        currentTimeout = setTimeout(() => {
            runServerSearch(searchValue)
        }, 300)
    }

    const onResultSelect = (e, data) => {
        setSelectedSearchResultObject(data.result)
        setValue(data.result.id)
        setSearchInputValue(data.result.title)

        //run any  form transformations
        if (props.transformValues) {
            setLoading(true)
            props.transformValues({values:{ ...form.values, [field]: data.result.id }, fieldName:field})
                .then(() => {
                    setLoading(false)
                })
        }

        setMeta(oldMeta => {
            let newMeta = Object.assign({}, oldMeta)
            newMeta.isBlured = true
            return newMeta
        })
    }

    const onBlur = () => {

    }

    const handleCreateNew = async (e) => {
        e.preventDefault()

        addToEditModalQueue({
            model: lookupModel,
            createFromFormModel: model,
            createFromFormRecord: form.values,
            onSuccess: (newObject) => {
                setValue(newObject.id)
                setSearchInputValue(lookupModel.referToByString(newObject))

                //run any  form transformations
                if (props.transformValues) {
                    setLoading(true)
                    props.transformValues({values:{ ...form.values, [field]: newObject.id }, fieldName:field})
                        .then(() => {
                            setLoading(false)
                        })
                }
            }
        })
    }

    let showError = ((isTouched && error && isBlured) || (isTouched && !isBlured && props.everSubmitted && error))

    // Build the field
    return (
        <>
            <div style={{ float: 'left' }}>
                <Search
                    value={searchInputValue}
                    placeholder={`Select ${fieldConfig.niceName}`}
                    onSearchChange={onSearchChange}
                    onResultSelect={onResultSelect}
                    selectFirstResult={true}
                    //noResultsMessage='No Results...'
                    showNoResults={false}
                    loading={loading || isValidating || props.formLoading}
                    disabled={props.formLoading}
                    results={results}
                    onBlur={onBlur}
                    input={{ 
                        style: { width: 400 }, 
                        icon: 'search', 
                        iconPosition: 'left', 
                        onClick: (e) => { e.target.select() },
                        onKeyPress:
                            (e)=>{
                                if(e.key == 'Enter') e.preventDefault()
                            }   
                    }}
                />
            </div>
            {fieldConfig.lookupAddNew === true?
                <div style={{ float: 'left' }}>
                    <Popup
                        content={`Can't find what you're looking for? Create a new ${lookupModel.niceName}...`}
                        position='top center'
                        popperModifiers={[
                            {
                            name: 'zIndex',
                            enabled: true,
                            phase: 'write',
                            fn: ({state}) => {
                                state.styles.popper.zIndex = 100000
                            },
                            },
                        ]}
                        trigger={
                            <Button style={{ position: 'relative', top: 4, left: 10 }} size='mini' color='green' icon onClick={handleCreateNew}>
                                <Icon name='plus' />
                            </Button>
                        }
                    />
                </div>
            :null}
            <div style={{ clear: 'both' }}>
                {showError ? (
                    <Label pointing='above' color='red'>
                        {error}
                    </Label>
                ) : null}
            </div>
        </>
    );
}

export default LookupField