import React, { useState } from 'react'
import { useField, splitFormProps } from "react-form"
import { Input, Label } from 'semantic-ui-react'
import { useEffect } from 'react';

let inputDebounce = null

const DurationField = (props) => {
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    //store a local form value for performance (debonce update actual value)
    let [localHoursInputValue, setLocalHoursInputValue] = useState(0)
    let [localMinutesInputValue, setLocalMinutesInputValue] = useState(0)
    let [loading, setLoading] = useState(false)


    // Use the useField hook with a field and field options
    // to access field state
    let {
        form,
        meta: { error, isTouched, isBlured, isValidating, model, fieldConfig },
        getInputProps,
        value,
        setValue,
        setMeta
    } = useField(field, fieldOptions);


    //prevent null or undefined being passed (creating and uncontrolled component)
    if (value===null||value===undefined) value = ''

    const onBlur = () => {
        setMeta(oldMeta => {
            let newMeta = Object.assign({}, oldMeta)
            newMeta.isBlured = true
            return newMeta
        })

        if(props.transformValues){

            let durationDecimal = localHoursInputValue + localMinutesInputValue/60

            setLoading(true)
            props.transformValues({values:Object.assign(form.values,{[field]:durationDecimal}), fieldName:field})
            .then(()=>{
                setLoading(false)
            })
        }
    }

    let showError = ((isTouched && error && isBlured) || (isTouched && !isBlured && props.everSubmitted && error))

    const handleHoursChange = (e) => {
        let inputValue = e.target.value
        if (inputValue == '') inputValue = 0
        if (isNaN(inputValue)) inputValue = 0

        inputValue = parseInt(inputValue)

        setLocalHoursInputValue(inputValue)


        let durationDecimal = inputValue + (localMinutesInputValue/60)
        if(isNaN(durationDecimal)) durationDecimal = 0


        if(inputDebounce) {
            clearTimeout(inputDebounce)
        }
        inputDebounce = setTimeout(()=>{     
            setValue(durationDecimal)
        },300)
    }

    const handleMinutesChange = (e) => {
        let inputValue = e.target.value
        if (inputValue == '') inputValue = 0
        inputValue = parseInt(inputValue)
        if (isNaN(inputValue)) inputValue = 0

        setLocalMinutesInputValue(inputValue)

        let durationDecimal = localHoursInputValue + inputValue/60
        if(isNaN(durationDecimal)) durationDecimal = 0

        if(inputDebounce) {
            clearTimeout(inputDebounce)
        }
        inputDebounce = setTimeout(()=>{    
            setValue(durationDecimal)
        },300)
    }

    //update the local value with form value if changed
    useEffect(()=>{
        let hours = Math.floor(value)
        let minutes = Math.round( ((value-hours) *60 *100) /100)

        if(hours != localHoursInputValue || minutes != localMinutesInputValue){
            setLocalHoursInputValue(hours)
            setLocalMinutesInputValue(minutes)
        }
    },[value])

    // //update the local value with form value if changed
    // useEffect(()=>{
    //     let hours = Math.floor(value)
    //     let minutes = Math.round( ((value-hours) *60 *100) /100)

    //     if(hours != localHoursInputValue && minutes != localMinutesInputValue){
    //         setLocalHoursInputValue(hours)
    //         setLocalMinutesInputValue(minutes)
    //     }
    // },[])

    // Build the field
    return (
        <>
            <div style={{ float: 'left' }}>
                H: <Input 
                    type={props.type || 'text'} 
                    input={{ onBlur }} 
                    style={{ width: 80 }} 
                    onChange={handleHoursChange} 
                    loading={loading||isValidating||props.formLoading} 
                    disabled={props.formLoading} 
                    error={showError ? true : false} 
                    value={localHoursInputValue} 
                    onKeyPress={(e)=>{
                        if(e.key == 'Enter')e.preventDefault()
                    }}
                />{" "}
                &nbsp;&nbsp;&nbsp; 
                M: <Input 
                    type={props.type || 'text'} 
                    input={{ onBlur }} style={{ width: 80 }} 
                    onChange={handleMinutesChange} 
                    loading={loading||isValidating||props.formLoading} 
                    disabled={props.formLoading} 
                    error={showError ? true : false} 
                    value={localMinutesInputValue} 
                    onKeyPress={(e)=>{
                        if(e.key == 'Enter')e.preventDefault()
                    }}
                />{" "}
            </div>
            <div style={{clear:'both'}}>
                {showError ? (
                    <Label pointing='above' color='red'>
                        {error}
                    </Label>
                ) : null}
            </div>

        </>
    );
}

export default DurationField