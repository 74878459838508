import React, { useState, useContext } from 'react'
import { useField, splitFormProps } from "react-form"
import { Dropdown, Label, Popup, Button, Icon } from 'semantic-ui-react'
import { ConfigContext } from "../../contexts/ConfigContext"
import { useEffect } from 'react';
import { UIContext } from '../../contexts/UIContext'
import useFetch from '../../hooks/useFetch'

const RemoteSelectField = (props) => {
    const { 0: config } = useContext(ConfigContext)
    const { addToEditModalQueue } = useContext(UIContext)
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);
    let [loading, setLoading] = useState(false)
    let [valueOptions, setValueOptions] = useState([])

    // Use the useField hook with a field and field options
    // to access field state
    let {
        form,
        meta: { error, isTouched, isBlured, isValidating, model, fieldConfig, parentModel, editRecord, parentRecord  },
        getInputProps,
        value,
        setValue,
        setMeta
    } = useField(field, fieldOptions);


    //prevent null or undefined being passed (creating and uncontrolled component)
    if (!value) value = []

    // //change initial value from object to ids
    // value = value.map(datum=>{
    //     return datum.id || datum
    // })


    const lookupModel = config.models[fieldConfig.lookupModel]
    const { runFetch } = useFetch()

    const fetchSelectOptions = async ()=>{
        setLoading(true)

        let trimmedFormValuesForServerSideFilterQuery = {}
        //remove long fields from the serverside filter query, too long for get
        //AND if array of objects, just shrink down to list of IDs
        for(let f of Object.keys(form.values)){
            let v = form.values[f]
            if(typeof v == "string" && v.length>100){
                //do nothing
            }
            //if array and has an object with id param
            else if(Array.isArray(v) && v[0]?.id){
                trimmedFormValuesForServerSideFilterQuery[f] = v.map(d=>d.id)
            }
            else trimmedFormValuesForServerSideFilterQuery[f] = v
        }

        let fetchOptions = {
            queryString: {
                q: fieldConfig.filterQuery?fieldConfig.filterQuery({values:form.values, parentModel, editRecord, parentRecord }):undefined,
                relatedRecords:false,
                filterQuery: fieldConfig.serverFilterQuery?JSON.stringify({field, model:model.name, recordId: editRecord?.id, values:trimmedFormValuesForServerSideFilterQuery, parentModel:parentModel?parentModel.name:null, parentRecordId:parentRecord?parentRecord.id:null}):undefined,
                perPage:lookupModel.maxResultsPerPage||lookupModel.resultsPerPage,
            },
        }

        //load options
        runFetch(`/${fieldConfig.lookupModel}`, fetchOptions)
            .then(valueOptionsResponse => {
                if (valueOptionsResponse && valueOptionsResponse.records) {
                    setValueOptions(valueOptionsResponse.records.map(record=>{
                        return {key:record.id, value:record.id, text:fieldConfig.lookupReferToByString?fieldConfig.lookupReferToByString(record):lookupModel.referToByString(record)}
                    }))
                }
                setLoading(false)

                //check the current values are available in the returned response
                let newValues = []
                for(let val of value){
                    for(let option of valueOptionsResponse.records){
                        if(val == option.id){
                            newValues.push(val)
                            break
                        }
                    }
                }

                if(JSON.stringify(newValues) != JSON.stringify(value)) setValue(newValues)

            })
    }

    useEffect(()=>{
        
        fetchSelectOptions()

        //only run once if no filterQuery, otherwise run whenever for data changes (which is expensive)
    },[fieldConfig.filterQuery||fieldConfig.serverFilterQuery?JSON.stringify(form.values):null])



    let showError = ((isTouched && error && isBlured) || (isTouched && !isBlured && props.everSubmitted && error))

    const handleChange = async (e, data) => {

        setValue(data.value)

        setMeta(oldMeta => {
            let newMeta = Object.assign({}, oldMeta)
            newMeta.isBlured = true
            return newMeta
        })

        if(props.transformValues){
            setLoading(true)
            props.transformValues({values:Object.assign({},form.values,{[field]:data.value}), fieldName:field})
            .then(()=>{
                setLoading(false)
            })
        }

    }

    const handleCreateNew = async (e) => {
        e.preventDefault()

        addToEditModalQueue({
            model: lookupModel,
            createFromFormModel: model,
            createFromFormRecord: form.values,
            onSuccess: (newObject) => {
                setLoading(true)
                fetchSelectOptions()
                .then(()=>{
                    let currentValues = [...value, newObject.id]

                    setValue(currentValues)

                    setMeta(oldMeta => {
                        let newMeta = Object.assign({}, oldMeta)
                        newMeta.isBlured = true
                        return newMeta
                    })

                    //run any  form transformations
                    if (props.transformValues) {
                        setLoading(true)
                        props.transformValues({values:{ ...form.values, [field]: newObject.id }, fieldName:field})
                            .then(() => {
                                setLoading(false)
                            })
                    }
                    else {
                        setLoading(false)
                    }
                })

                
            }
        })
    }

    // Build the field
    return (
        <>
            <div style={{ float: 'left' }}>
                <Dropdown 
                    multiple 
                    value={value} 
                    placeholder='' 
                    selection 
                    options={valueOptions} 
                    style={{ width: 400 }} 
                    onChange={handleChange} 
                    loading={loading||isValidating||props.formLoading}
                    disabled={props.formLoading}
                    search
                />
            </div>

            {fieldConfig.lookupAddNew === true?
                <div style={{ float: 'left' }}>
                    <Popup
                        content={`Can't find what you're looking for? Create a new ${lookupModel.niceName}...`}
                        position='top center'
                        trigger={
                            <Button style={{ position: 'relative', top: 4, left: 10 }} size='mini' color='green' icon onClick={handleCreateNew}>
                                <Icon name='plus' />
                            </Button>
                        }
                    />
                </div>
            :null}

            <div style={{clear:'both'}}>
                {showError ? (
                    <Label pointing='above' color='red'>
                        {error}
                    </Label>
                ) : null}
            </div>

        </>
    );
}

export default RemoteSelectField