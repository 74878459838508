import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import moment from 'moment-timezone'

import FileIcon, { defaultStyles } from 'react-file-icon';

export default ({model, lookupModel, field, item, config, login}) => {

    if (!item) return null

    let value = item[field.apiName]
    let displayRenderValue = false

    //if there is a render function
    if (field.displayRender){
        displayRenderValue = field.displayRender(item, config, login)
    }

    if(displayRenderValue){
        value = displayRenderValue
    }

    //boolean
    else if (field.type === 'boolean'){
        if(value===true) value = <Icon name='check' size='large' color='green' />
        else if(value===false) value = <Icon name='close' size='large' color='black' />
        else value = <Icon size='large' name='question' />
    }

    //numbers
    else if (field.type === 'integer' || field.type === 'float'){
        //if(!value) value = 0
    }
    
    //DATES
    else if (field.type === 'date'){
        let dateMoment = moment(value)
        if(dateMoment.isValid())
            value = moment(value).format('DD/MM/YYYY')
    }

    //TIME
    else if (field.type === 'time'){
        if(value){
            let splitTime = value.split(':')
            value = `${splitTime[0]}:${splitTime[1]}`
        }
    }

    //DATE TIMES
    else if (field.type === 'datetime'){
        let dateMoment = moment(value)
        if(dateMoment.isValid())
            value = moment(value).tz('Europe/London').format('DD/MM/YYYY HH:mm')
    }

    //DURATIONS
    else if (field.type === 'duration'){
        let negative = false
        let absValue = Math.abs(value)

        let hours = Math.floor(absValue)
        let minutes = Math.round( ((absValue-hours) *60 *100) /100)

        value = `${value < 0?'-':''}${hours}h ${minutes}m`
    }

    //CURRENCY
    else if (field.type === 'currency'){
        if(!value) value=0
        value = `£${value.toLocaleString(undefined,
            {'minimumFractionDigits':2,'maximumFractionDigits':2})
        }`
    }

    //relatedLists
    else if (field.type === 'relatedList'){

        if(Array.isArray(value)){
            let items = value.map(record=>{
                let text = ''
                if(field.itemDisplayRender) text = field.itemDisplayRender(record)
                else text = lookupModel.referToByString(record)

                if(text==="" || text===null || text===undefined) return null

                let linkTo = field.link?field.link(record, config, login):lookupModel.linkTo(record)
                return <Link key={record.id} to={linkTo}><span dangerouslySetInnerHTML={{ __html: text }} /></Link>
            })

            items = items.filter(i=>i!==null)

            value = <ul style={{margin:'2px 0px', paddingLeft:25}}>
                {items.map((item, i)=><li key={i}>{item}</li>)}
            </ul>
        }

    }

    else if (field.type === 'directRelatedList'){

        let lookupModel = config.models[model.relationships[field.relationship].model]

        if(Array.isArray(value)){
            let items = value.map(record=>{
                let text = ''
                if(field.itemDisplayRender) text = field.itemDisplayRender(record)
                else text = lookupModel.referToByString(record)

                let linkTo = field.link?field.link(record, config, login):lookupModel.linkTo(record)
                return <Link key={record.id} to={linkTo}><span dangerouslySetInnerHTML={{ __html: text }} /></Link>
            })
            value = <ul style={{margin:'2px 0px', paddingLeft:25}}>
                {items.map((item, i)=><li key={i}>{item}</li>)}
            </ul>
        }

    }


    //files
    else if (field.type === 'file'){

        let valueExt = value?value.split('.').pop():''

        let typeExt = item.type&&item.type.split('/')[1]?item.type.split('/')[1]:''

        let ext = typeExt || valueExt

        value = <>
                    <div style={{float:'left', paddingTop:2, paddingRight:10}}>
                            {item.type.indexOf('image')>=0?
                                <img 
                                    style={{width:50}}
                                    src={`${item.thumbUrl}`}
                                />
                            :
                                <FileIcon size={30} extension={ext} {...defaultStyles[ext]}/>
                            }
                        
                    </div>
                    <div>
                        <div style={{paddingTop:8, paddingLeft:10, paddingRight:40}}>{value}</div>
                    </div>
                </>
    }

    //images and pdfs
    else if (field.type === 'image'){
        if(value){
            //pdfs
            if(value.type === 'application/pdf') value = <iframe style={{width:'100%', height:800, maxWidth:'100%'}} src={value.url} />
            //images
            else value = <img style={{maxWidth:'100%'}} src={value.url} />
        }
        else value = ''
    }

    //code areas need a pre tag when rendered
    else if (field.type === 'code'){
        value = <pre>{value}</pre>
    }


    //replace new lines with br's if it's a string and not html
    if (typeof value === 'string') {

        //don't insert brs if html
        if(value.indexOf('<html')>-1||value.indexOf('<p')>-1||value.indexOf('<span')>-1||value.indexOf('<div')>-1){
            //must be an html value so don't replace newlines with brs
            value = <iframe height="500px" srcDoc={value}/>
        }
        else{
            value = value.replace(/\n/g, "<br />");
            value = <span dangerouslySetInnerHTML={{ __html: value }}></span>
        }
    }

    //set a link if there is one
    if (field.link && field.type !== 'relatedList' && field.type !== 'directRelatedList'){
        let linkString = field.link(item, config, login)

        if(linkString){

            //is the link to another model?
            let linkStringSplit = linkString.split('/')
            //find which bit is probably the model (depends on slash at start or not)
            let modelPartOfLink = linkStringSplit[0]||linkStringSplit[1]

            //is that a valid model?
            let model = config.models[modelPartOfLink]
            
            //if valid model, check permimssions
            let permission = true
            if(model){
                //is this user allowed to see this model?
                if (config.globalClientPermission) permission = config.globalClientPermission({ login, model:model.name, method:'GET' })
                if(permission === true){
                    if (model.clientGetPermission) permission = model.clientGetPermission({ login })
                }
            }

            //external?
            if(( linkString.indexOf('http')===0 || linkString.indexOf('mailto')===0 || linkString.indexOf('tel')===0 )) value = <a target='_blank' href={linkString}>{value}</a>
            else if(permission === true) value = <Link to={linkString}>{value}</Link>
        }
    }


    return value
}
