import React, { useContext } from 'react'
import { ErrorsContext } from '../contexts/ErrorsContext'
import { Modal, Button } from 'semantic-ui-react'

const Error = () => {

    const { 0: errors, 2: removeError } = useContext(ErrorsContext)

    return <>
        <Modal className='errorModal' open={errors.length?true:false}>
            <Modal.Header>Error</Modal.Header>
            <Modal.Content>
                <p>{errors[0]}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button content='Close' onClick={removeError} />
            </Modal.Actions>
        </Modal>
    </>


}

export default Error