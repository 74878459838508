import React, { useState } from 'react'
import { useField, splitFormProps } from "react-form"
import { Input, Label } from 'semantic-ui-react'
import { useEffect } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';

let inputDebounce = null

const InputField = (props) => {

    //console.log(props)

    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    //store a local form value for performance (debonce update actual value)
    let [localInputValue, setLocalInputValue] = useState('')
    let [loading, setLoading] = useState(false)


    // Use the useField hook with a field and field options
    // to access field state
    let {
        form,
        meta: { error, isTouched, isBlured, isValidating, model, fieldConfig },
        getInputProps,
        value,
        setValue,
        setMeta
    } = useField(field, fieldOptions);


    //prevent null or undefined being passed (creating and uncontrolled component)
    if (value===null||value===undefined) value = ''

    const onBlur = () => {
        setMeta(oldMeta => {
            let newMeta = Object.assign({}, oldMeta)
            newMeta.isBlured = true
            return newMeta
        })

        if(props.transformValues){
            setLoading(true)
            props.transformValues({values:Object.assign(form.values,{[field]:localInputValue}), fieldName:field})
            .then(()=>{
                setLoading(false)
            })
        }
    }

    let showError = ((isTouched && error && isBlured) || (isTouched && !isBlured && props.everSubmitted && error))

    const handleChange = (e) => {
        let inputValue = e.target.value
        if (inputValue == '') inputValue = null

        setLocalInputValue(inputValue)

        if(inputDebounce) {
            clearTimeout(inputDebounce)
        }
        inputDebounce = setTimeout(()=>{       
            setValue(inputValue)
        },300)
    }

    //update the local value with form value if changed
    useEffect(()=>{
        if(value !== localInputValue)
        setLocalInputValue(value)
    },[value])

    // Build the field
    return (
        <>
            <div style={{ float: 'left' }}>
                <Input 
                    name={props.defaultMeta.fieldConfig.apiName} 
                    autoComplete={props.defaultMeta.fieldConfig.autoComplete?'on':'off'} 
                    type={props.type || 'text'} 
                    input={{ onBlur }} 
                    style={{ width: 400 }} 
                    onChange={handleChange} 
                    loading={loading||isValidating||props.formLoading} 
                    disabled={props.formLoading} 
                    error={showError ? true : false} 
                    value={localInputValue}
                    onKeyPress={(e)=>{
                        if(e.key == 'Enter')e.preventDefault()
                    }}
                />{" "}
                {props.defaultMeta.fieldConfig.showStrengthMeter?
                    <PasswordStrengthBar password={localInputValue||''} minLength={8} />
                    :null
                }
                    
            </div>
            <div style={{clear:'both'}}>
                {showError ? (
                    <Label pointing='above' color='red'>
                        {error}
                    </Label>
                ) : null}
            </div>

        </>
    );
}

export default InputField