import React, { useState, useContext } from 'react'
import { useField, splitFormProps } from "react-form"
import { Dropdown, Label } from 'semantic-ui-react'
import { ConfigContext } from "../../contexts/ConfigContext"
import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch'

const FiltersRemoteSelectField = ({value, onChange, field}) => {
    const { 0: config } = useContext(ConfigContext)
    let [loading, setLoading] = useState(false)
    let [valueOptions, setValueOptions] = useState([])

    const { runFetch } = useFetch()

    useEffect(()=>{
        setLoading(true)

        let fetchOptions = {
            queryString: {
                relatedRecords:false,
                perPage: config.models[field.lookupModel].maxResultsPerPage || 100
            },
        }

        runFetch(`/${field.lookupModel}`, fetchOptions)
            .then(valueOptionsResponse => {
                if (valueOptionsResponse && valueOptionsResponse.records) {
                    let currentValueAvailableInOptionsResponse = false

                    setValueOptions(
                        [{key:'null', value:null, text:'None'}]
                        .concat(valueOptionsResponse.records.map(record=>{

                        //if one of the options is the current value set this flag
                        if(value == record.id) currentValueAvailableInOptionsResponse = true

                        return {key:record.id, value:record.id, text:config.models[field.lookupModel].referToByString(record)}
                        }))
                    )

                    //if the current set value is not an available option and it's not null
                    if(value && !currentValueAvailableInOptionsResponse) onChange('')
                }
                setLoading(false)
            })
    },[])

    //prevent null or undefined being passed (creating and uncontrolled component)
    if (!value) value = ''

    const handleChange = async (e, data) => {

        onChange(data.value)

    }

    // Build the field
    return (
        <>
            <div style={{ display: 'inline-block', marginRight:20 }}>
                <Dropdown search value={value} placeholder='' selection options={valueOptions} style={{ width: 400 }} onChange={handleChange} loading={loading}/>
            </div>
        </>
    );
}

export default FiltersRemoteSelectField