import React, { useContext } from 'react'
import { Icon, Button } from 'semantic-ui-react'
import { ConfigContext } from '../../contexts/ConfigContext'
import { LoginContext } from '../../contexts/LoginContext'
import { Link } from "react-router-dom"

const RefreshWarning = () => {

    const [config] = useContext(ConfigContext)

    const refresh = ()=>{
        window.location.reload(true)
    }

    return <div className='refreshWarning'>
        XView has been updated since your browser was last refreshed, please refresh to access any new features and avoid any unexpected behaviour.
        <div className='buttonHolder'><Button onClick={refresh} color='green'>Refresh Now</Button></div>
    </div>
}

export default RefreshWarning