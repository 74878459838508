import React, { useContext, useState, useEffect, useRef } from 'react';
import { Loader, Statistic } from 'semantic-ui-react'
import { useParams, Link, useHistory } from "react-router-dom"
import { Bar, Radar, Pie, getElementAtEvent, getElementsAtEvent } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    LineElement,
    BarElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';

  const Widget = ({widget, data, endDate, startDate}) => {
    
    const history = useHistory()

    ChartJS.register(
        CategoryScale,
        LinearScale,
        RadialLinearScale,
        BarElement,
        LineElement,
        PointElement,
        ArcElement,
        Title,
        Tooltip,
        Legend
    )

    const chartRef = useRef(null)

    return <div>
        {!data &&
            <>
                {/* <h2>Loading...</h2> */}
                <Loader active inline='centered'/>
            </>
        }
        {widget.type == 'stat' && data?
            <>
                <h2>{widget.niceName}</h2>
                {data.map(d=>
                    <Statistic size='small' key={d.label}>
                            <Statistic.Value style={{color:d.colour}}>
                                {d.link?<Link style={{color:'inherit'}} to={d.link}>{d.value}</Link>:d.value}
                            </Statistic.Value>
                        <Statistic.Label>{d.label}</Statistic.Label>
                    </Statistic>
                )}
            </>
        : null}

        {widget.type == 'bar' && data?
            <>
                <h2>{widget.niceName}</h2>
                <div>
                    <Bar
                        data={data}
                        height={500}
                        ref={chartRef}
                        options={{
                            maintainAspectRatio: false,
                            // scales: {
                            //     yAxes: [{
                            //         ticks: {
                            //             beginAtZero: true,
                            //         }
                            //     }]
                            // }
                            // onClick: function(evt, element) {
                            //     if(element.length > 0) {
                            //         console.log(element,element[0]._datasetInde)
                            //         // you can also get dataset of your selected element
                            //         console.log(data.datasets[element[0]._datasetIndex])
                            //     }
                            // }
                            
                        }}
                        onClick={(event) => {
                            let clickedElement = getElementAtEvent(chartRef.current, event)[0]
                            if(widget.link && clickedElement){
                                history.push(widget.link({dataSetIndex: clickedElement.datasetIndex, dataIndex:clickedElement.index, data, start:startDate, end:endDate}))
                            }
                            
                        }}
                        

                    />
                </div>
            </>
        : null}

        {widget.type == 'stackedBar' && data ?
            <>
                <h2>{widget.niceName}</h2>
                <div>
                    <Bar
                        data={data}
                        height={500}
                        ref={chartRef}
                        options={{
                            maintainAspectRatio: false,
                            // scales: {
                            //     yAxes: [{
                            //         ticks: {
                            //             beginAtZero: true,
                            //         }
                            //     }]
                            // }
                            stacked: true,
                            scales: {
                                xAxes: {
                                    stacked: true,
                                },
                                yAxes: {
                                    stacked: true,
                                }
                            },
                            
                        }}
                        onClick={(event) => {
                            let clickedElement = getElementAtEvent(chartRef.current, event)[0]
                            if(widget.link && clickedElement){
                                // console.log(widget.link({dataSetIndex: clickedElement.datasetIndex, dataIndex:clickedElement.index}))
                                history.push(widget.link({dataSetIndex: clickedElement.datasetIndex, dataIndex:clickedElement.index, data, start:startDate, end:endDate}))
                            }
                            
                        }}

                    />
                </div>
            </>
        : null}

        {widget.type == 'radar' && data ?
            <>
                <h2>{widget.niceName}</h2>
                <div>
                    <Radar
                        data={data}
                        height={500}
                        ref={chartRef}
                        options={{
                            maintainAspectRatio: false,
                            // scales: {
                            //     yAxes: [{
                            //         ticks: {
                            //             beginAtZero: true,
                            //         }
                            //     }]
                            // }
                            
                        }}
                        onClick={(event) => {
                            let clickedElement = getElementAtEvent(chartRef.current, event)[0]
                            if(widget.link && clickedElement){
                                // console.log(widget.link({dataSetIndex: clickedElement.datasetIndex, dataIndex:clickedElement.index}))
                                history.push(widget.link({dataSetIndex: clickedElement.datasetIndex, dataIndex:clickedElement.index, data, start:startDate, end:endDate}))
                            }
                            
                        }}
                    />
                </div>
            </>
        : null}

        {widget.type == 'pie' && data?
            <>
                <h2>{widget.niceName}</h2>
                <div>
                    <Pie
                        data={data}
                        height={500}
                        ref={chartRef}
                        options={{
                            maintainAspectRatio: false,
                            // scales: {
                            //     yAxes: [{
                            //         ticks: {
                            //             beginAtZero: true,
                            //         }
                            //     }]
                            // }
                            
                        }}
                        onClick={(event) => {
                            let clickedElement = getElementAtEvent(chartRef.current, event)[0]
                            if(widget.link && clickedElement){
                                // console.log(widget.link({dataSetIndex: clickedElement.datasetIndex, dataIndex:clickedElement.index}))
                                history.push(widget.link({dataSetIndex: clickedElement.datasetIndex, dataIndex:clickedElement.index, data, start:startDate, end:endDate}))
                            }
                            
                        }}
                    />
                </div>
            </>
        : null}

             

    </div>
}

export default Widget