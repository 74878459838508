import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import { Breadcrumb, Button, Dimmer, Dropdown, Grid, Header, Icon, Loader, Popup, Segment, Table as STable, Tab } from 'semantic-ui-react';
import { ConfigContext } from "../../contexts/ConfigContext";
import { ErrorsContext } from "../../contexts/ErrorsContext";
import { LoginContext } from "../../contexts/LoginContext";
import { UIContext } from "../../contexts/UIContext";
import useFetch from '../../hooks/useFetch';
import formatValue from '../../utils/formatFieldValue.js';
import ListViewComponentSelector from '../listViewComponentSelector';

const ItemView = () => {

    //get the config
    const { 0: config } = useContext(ConfigContext)
    const { 0: login } = useContext(LoginContext)
    const { 1: addError } = useContext(ErrorsContext)
    const { refreshDataKey, addToEditModalQueue, runDeletion, runAction, runMerge } = useContext(UIContext)

    //get the model and item from the URL
    const { modelName, itemId } = useParams()

    //get the model
    const model = config.models[modelName]

    const [permission, setPermission] = useState(false)

    const [groupClosedIndexes, setGroupClosedIndexes] = useState({})


    //check that we can view this model
    let history = useHistory()
    useEffect(() => {
        if (model.clientGetPermission) {
            let permissionCheckResult = model.clientGetPermission({ login })
            if (permissionCheckResult !== true) {
                addError(permissionCheckResult)
                return history.push("/")
            }
        }
        setPermission(true)
    }, [])

    history.listen(() => {
        window.scrollTo(0, 0)
    })

    const [response, setResponse] = useState()
    const [loading, setLoading] = useState(true)

    //build the query required to get the item and prepare for API
    let query = {
        rules: [{
            field: 'id',
            value: itemId,
            operator: '$e',
        }]
    }

    let fetchOptions = {
        queryString: {
            q: query
        }
    }

    //get the item from the database
    let { runFetch } = useFetch()

    useEffect(() => {
        setLoading(true)
        setResponse(null)
        if (permission) {
            runFetch(`/${model.name}`, fetchOptions).then(res => {
                setResponse(res)
                setLoading(false)
            })
                .catch(err => {
                    setResponse(null)
                })
        }
    }, [permission, model, itemId, refreshDataKey])

    let item = {}
    if (response) item = response.records[0]

    //relationship panes
    const [relationshipPanes, setRelationshipPanes] = useState([])

    useEffect(() => {
        let panes = []

        //if there are any relationships and the item has loaded
        if (response && model.relationships) {
            for (let relationshipKey of Object.keys(model.relationships)) {

                let relationship = model.relationships[relationshipKey]
                let relationshipModel = config.models[relationship.model]

                //do we have model level permission to view this object?
                if (
                    (!config.globalClientPermission || config.globalClientPermission({ login, method:'GET', model:relationshipModel.name }) === true)
                    &&
                    (!relationshipModel.clientGetPermission || relationshipModel.clientGetPermission({ login }) === true)
                )
                    //if relationship.display isn't false or a function that return false
                    if (relationship.display === undefined || relationship.display === null || relationship.display === true || (relationship.display !== false && relationship.display && relationship.display({ values: item, login }))) {
                        panes.push({
                            menuItem: { key: relationshipModel.name, icon: relationship.icon ? relationship.icon : relationshipModel.icon, content: relationship.niceName },
                            render: () => item.id ? <ListViewComponentSelector key={`${model.name}-${relationshipModel.name}-${item.id}-${relationship.foreignKeyAPIName}`} relationship={relationship} parentModel={model} model={relationshipModel} parentRecord={item} /> : null
                        })
                    }
            }
        }

        setRelationshipPanes(panes)

        //set the page title
        if(model.niceNamePlural && item) document.title = `${model.niceNamePlural}: ${model.referToByString(item)}`
    }, [model, response])


    //Save the last used tab!
    //if no defaultTabs Object in local storage, create one...
    if(!localStorage.defaultTabIndexes) localStorage.defaultTabIndexes = "{}"
    let defaultTabIndexes = JSON.parse(localStorage.defaultTabIndexes)

    //save the most recent tab in local storage
    const saveTab = (e, tab)=>{
        defaultTabIndexes[modelName] = tab.activeIndex
        localStorage.defaultTabIndexes = JSON.stringify(defaultTabIndexes)
    }



    //store save which groups are open
    useEffect(()=>{
        //if no groupClosedIndexes Object in local storage, create one...
        if(!localStorage.groupClosedIndexes) localStorage.groupClosedIndexes = "{}"
        let groupClosedIndexesFromLS = JSON.parse(localStorage.groupClosedIndexes)
        
        //do we have a index for this model?
        if(!groupClosedIndexesFromLS[modelName]) groupClosedIndexesFromLS[modelName] = {}

        setGroupClosedIndexes(groupClosedIndexesFromLS)
    },[])

    //save the most recent tab in local storage
    const saveGroupClosedIndexes = (group, state)=>{
        let newGroupClosedIndexes = Object.assign({},groupClosedIndexes)
        newGroupClosedIndexes[modelName][group] = state
        setGroupClosedIndexes(newGroupClosedIndexes)
        localStorage.groupClosedIndexes = JSON.stringify(newGroupClosedIndexes)
    }

    //toggleGroupClosed
    const toggleGroupClosed = (group)=>{
        if(groupClosedIndexes[modelName][group] === true) saveGroupClosedIndexes(group, false)
        else saveGroupClosedIndexes(group, true)
    }


    let editButtons = []
    if(
        (!config.globalClientPermission || config.globalClientPermission({ login, method:'PUT', model:model.name }) === true)
        &&
        (!model.clientPutPermission || model.clientPutPermission({ login, currentRecord: item, config }) === true)  
    ){
        editButtons.push(<Dropdown.Item
            onClick={() => {
                addToEditModalQueue({
                    model,
                    editRecord: item
                })
            }}
        >
                <Icon name='pencil' />Edit {model.referToByString(item)}
        </Dropdown.Item>)
    }

    let actionButtons = []
    if(model.actions){
        for(let actionKey of Object.keys(model.actions)){
            let action = model.actions[actionKey]

            let computedActionDisplay = typeof action.display === 'function'?action.display({ login, records: [item], config }):action.display
            //default to true
            if(computedActionDisplay !== false) computedActionDisplay = true

            if(!action.global && computedActionDisplay){
                actionButtons.push(<Dropdown.Item
                    key={actionKey}
                    disabled={action.clientPermission && action.clientPermission({ login, records: [item], config }) !== true}
                    onClick={() => {
                        runAction({ model, action, inputs: new Set([item.id])})
                    }}
                >
                    <Icon color={action.colour} name={action.icon} />{action.niceName}
                </Dropdown.Item>)
            }
        }
    }

    let linkButtons = []
    if(model.links){
        for(let link of model.links){
            if(!link.global && (!link.display || link.display({ login, record: item, config }) === true)){
                linkButtons.push(<Dropdown.Item
                    key={link}
                    text={link.text(item)}
                    icon={link.icon}
                    as='a'
                    target='_blank'
                    href={link.to(item, config, login.token, login)}
                />)
            }
        }
    }

    let otherButtons = []
    if(model.mergeable===true){
        otherButtons.push(
            <Dropdown.Item color='blue'
                onClick={() => {
                    runMerge({
                        model,
                        record:item,
                        //onMerge: () => {  }
                    })
                }}
            >
                <Icon color='blue' name='object group' />Merge {model.referToByString(item)}
            </Dropdown.Item>
        )
    }
    if(
        (!config.globalClientPermission || config.globalClientPermission({ login, method:'DELETE', model:model.name }) === true)
        &&
        (!model.clientDeletePermission || model.clientDeletePermission({ login, currentRecord: item, config }) === true)
    )
    {
        otherButtons.push(<Dropdown.Item color='red'
            onClick={() => {
                runDeletion({
                    model,
                    id: item.id,
                    onDelete: () => { history.push(`/${model.name}`) }
                })
            }}
        >
            <Icon color='red' name='trash alternate' />Delete {model.referToByString(item)}
        </Dropdown.Item>)
    }

    let createOtherModelButtons = []

    if(response && model.createOtherModels){
        
        for(let createOtherModel of model.createOtherModels){

            let {foreignKey, label} = createOtherModel
            let parentModel = config.models[createOtherModel.parentModel]
            createOtherModel = config.models[createOtherModel.createModel]

            createOtherModelButtons.push(
                <Dropdown.Item key={'new'+modelName+'-'+createOtherModel.name} 
                    onClick={() => {
                        addToEditModalQueue({
                            model:createOtherModel,
                            parentModel,
                            parentRecord:{id:item[foreignKey]}
                        })
                    }}
                >
                    <Icon name='plus' color='green' />{label}
                </Dropdown.Item>
            )
        }
            
    }

    let createRelationshipModelButtons = []
    if(response && model.relationships){
        for(let relationshipKey of Object.keys(model.relationships)){

            let relationship = model.relationships[relationshipKey]
            let relationshipModel = config.models[relationship.model]

            //do we have model level permission to view create object?
            if (
                //if relationship.display isn't false or a function that return false
                (relationship.display === undefined || relationship.display === null || relationship.display === true || (relationship.display !== false && relationship.display && relationship.display({ login, values: item })))
                &&
                //not a file
                !relationshipModel.fileHandler
                &&
                //if we have permissions
                (!config.globalClientPermission || config.globalClientPermission({ login, method:'POST', model:relationshipModel.name }) === true)
                &&
                (!relationshipModel.clientPostPermission || relationshipModel.clientPostPermission({ login, config }) === true)
                &&
                relationship.newable !== false
            ){
                createRelationshipModelButtons.push(
                    <Dropdown.Item key={relationshipKey}
                        onClick={() => {
                            addToEditModalQueue({
                                model:relationshipModel,
                                parentModel:model,
                                parentRecord:item
                            })
                        }}
                    >
                        <Icon name='plus' color='green' />New {relationshipModel.niceName}
                    </Dropdown.Item>
                )
            }
        }
    }
                

    
     
    return <>

        <Breadcrumb>
            <Breadcrumb.Section><Link to='/'>Home</Link></Breadcrumb.Section>
            <Breadcrumb.Divider icon='right arrow' />
            <Breadcrumb.Section><Link to={`/${model.name}`}>{model.niceNamePlural}</Link></Breadcrumb.Section>
            <Breadcrumb.Divider icon='right arrow' />
            <Breadcrumb.Section active>{!loading && response ? model.referToByString(item) : ''}</Breadcrumb.Section>
        </Breadcrumb>

        <Dimmer.Dimmable>
            <Dimmer active={loading} inverted>
                <Loader>Loading</Loader>
            </Dimmer>

            <Grid style={{ marginTop: 20 }}>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Header as='h1'>
                            <Icon name={model.icon} />
                            <Header.Content>{!loading && response ? model.referToByString(item) : ''}
                            
                            </Header.Content>
                            
                            {!loading&&response&&item.defaultImage?
                                <span style={{
                                    display:'table-cell',
                                    backgroundImage:`url(${item.defaultImage.thumbUrl})`,
                                    width:80, height:80,
                                    backgroundPosition:'50% 50%',
                                    backgroundSize:'cover',
                                    backgroundRepeat:'no-repeat',
                                    borderRadius:40,
                                    position:'relative',
                                    left:10,
                                    border:`5px solid ${config.primaryColour}`
                                }}
                                />
                            :null}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4} textAlign='right'>

                        {model.actions ? Object.keys(model.actions).map(actionKey => {
                            let action = model.actions[actionKey]

                            let computedActionDisplay = typeof action.display === 'function'?action.display({ login, records: [item], config }):action.display
                            //default to true
                            if(computedActionDisplay !== false) computedActionDisplay = true

                            if(!action.global && action.pin === true && computedActionDisplay){

                                return <Popup
                                    key={actionKey}
                                    content={action.niceName}
                                    position='top center'
                                    trigger={
                                        <Button 
                                            // size='mini' 
                                            icon
                                            color={action.colour||null}
                                            onClick={() => {
                                                runAction({ model, action, inputs: new Set([item.id]) }) 
                                            }}
                                            disabled={action.clientPermission && action.clientPermission({ login, records: [item], config }) !== true}
                                        >
                                            <Icon name={action.icon} />
                                        </Button>
                                    }
                                />
                
                            }

                        }) : null}

                        {
                            editButtons.length ||
                            actionButtons.length ||
                            linkButtons.length ||
                            otherButtons.length ||
                            createOtherModelButtons.length ||
                            createRelationshipModelButtons.length
                        ?
                            <Dropdown
                                button
                                //disabled={!selected.size}
                                className='icon'
                                icon='down arrow'
                                labeled
                                text='Actions...'
                                direction='left'
                            >
                                <Dropdown.Menu>
                                    {editButtons}
                                    {actionButtons}
                                    {linkButtons}
                                    {otherButtons}
                                    {createOtherModelButtons.length?<Dropdown.Divider />:null}
                                    {createOtherModelButtons}
                                    {createRelationshipModelButtons.length?<Dropdown.Divider />:null}
                                    {createRelationshipModelButtons}

                                </Dropdown.Menu>
                            </Dropdown>
                        :null}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {Object.keys(model.fieldGroups).map(group => { 

                group = model.fieldGroups[group]
                let groupFields = []
                for (let field of Object.keys(model.fields)) {
                    field = model.fields[field]

                    let enumeratedDisplay = field.display
                    //if display is a function, run it
                    if(typeof field.display === 'function'){
                        enumeratedDisplay = field.display({values:item,config,login})
                    }

                    //if field is not part of this group, or shouldn't be displayed
                    if (group.name !== field.groupName || enumeratedDisplay === false) continue;
                    groupFields.push(field)
                }


                return groupFields.length>0?<div key={group.name} className={`groupHolder ${groupClosedIndexes[modelName] && groupClosedIndexes[modelName][group.name] === true?'collapsed':''}`}>
                    <Header as='h3' attached='top' style={{ borderTop: `2px solid ${config.primaryColour}` }} onClick={()=>toggleGroupClosed(group.name)}>
                        <Icon 
                            name={groupClosedIndexes[modelName] && groupClosedIndexes[modelName][group.name] === true?'angle down':'angle up'}
                            style={{float:'right', position:'relative', top:'2px', cursor:'pointer'}} 
                        />
                        <Icon name={group.icon} />
                        <Header.Content>{group.niceName}</Header.Content>
                        {group.description ? <Header.Subheader>{group.description}</Header.Subheader> : null}
                    </Header>
                    
                    <STable definition attached compact>
                        <STable.Body>
                            {groupFields.map(field => {
                                let style = {}
                                if (field.conditionalStyle) style = field.conditionalStyle(item)

                                //get the lookup model if this is a related list
                                let lookupModel
                                if (field.lookupModel) lookupModel = config.models[field.lookupModel]

                                return <STable.Row key={field.apiName}>
                                    <STable.Cell verticalAlign='top' width={3}>
                                        {field.niceName}
                                        {field.description ?
                                            <Popup
                                                offset={[-8,0]}
                                                trigger={<Icon style={{ marginLeft: 5 }} name='help circle' />}
                                                content={<span dangerouslySetInnerHTML={{ __html: field.description }} />}
                                                size='mini'
                                            />
                                            : null}
                                    </STable.Cell>
                                    <STable.Cell style={style}>{response ? formatValue({ model, lookupModel, field, item, config, login }) : null}</STable.Cell>
                                </STable.Row>
                            })}
                        </STable.Body>
                    </STable>
                    
                </div>:null
            })}

        </Dimmer.Dimmable>

        {model.preview ?
            <>
                <Header as='h3' attached='top'>
                    <Icon name='video' />
                    <Header.Content>Preview</Header.Content>
                </Header>
                <Segment attached>
                    <div dangerouslySetInnerHTML={{ __html: model.preview() }} />
                </Segment>
            </>
            : null}

        <br />

        {model.relationships && relationshipPanes.length
            ? <Tab defaultActiveIndex={defaultTabIndexes[modelName]||0} className='tabs' menu={{ size: 'huge', as: ({ className, children }) => {
                return <div className={className} style={{ overflowX: 'auto' }}>{children}</div>
            } }} panes={relationshipPanes} onTabChange={saveTab}/>
            : null}



    </>
}

export default ItemView