import React, { useContext } from 'react';
import { Modal } from 'semantic-ui-react'
import { UIContext } from "../../contexts/UIContext"
import { ErrorsContext } from "../../contexts/ErrorsContext"
import EditForm from "../editForm"

const NewView = () => {

    const { editModalQueue, removeFromEditModalQueue, removeFromEditModalQueueAndAddAnother } = useContext(UIContext)

    return <>
        {editModalQueue.map(({ model, onSuccess, editRecord, parentModel, parentRecord, createFromFormRecord, createFromFormModel, key, setData}, i) =>
            <Modal key={i} open={true} closeIcon onClose={removeFromEditModalQueue} closeOnDimmerClick={false}>
                <Modal.Header>
                    {editRecord?"Edit":"New"} {model.niceName}{editRecord?` - ${model.referToByString(editRecord)}`:null}
                </Modal.Header>
                <EditForm
                    key={key}
                    model={model}
                    setData={setData}
                    onSuccess={({newObject, addAnother=false, addAnotherModel=null}) => {

                        if(!addAnother) removeFromEditModalQueue()

                        if(addAnother){

                            if(addAnotherModel){

                                removeFromEditModalQueueAndAddAnother({
                                    model: addAnotherModel,
                                    parentModel:model,
                                    parentRecord:newObject
                                })
                            }

                            else {

                                removeFromEditModalQueueAndAddAnother({
                                    model,
                                    parentModel,
                                    parentRecord
                                })
                            }
                        }
                        if (onSuccess) onSuccess(newObject)
                    }}
                    parentModel={parentModel}
                    parentRecord={parentRecord}
                    createFromFormRecord={createFromFormRecord}
                    createFromFormModel={createFromFormModel}
                    editRecord={editRecord}
                />
            </Modal>
        )}
    </>
}

export default NewView