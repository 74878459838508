import React, { useState } from 'react'

export const UIContext = React.createContext();

export const UIProvider = (props) => {
    
    //Menu
    const [menuOpen, setMenuOpen] = useState(true)
    
    //New/Edit Modals
    const [editModalQueue, setEditModalQueue] = useState([])

    //Add a modal to the queue...
    const addToEditModalQueue = ({model, parentRecord, parentModel, createFromFormModel, createFromFormRecord, onSuccess, editRecord, setData})=>{

        let newEditModalQueue = editModalQueue.slice()

        let queueConfig = {
            model,
            parentRecord,
            parentModel,
            createFromFormModel,
            createFromFormRecord,
            onSuccess,
            editRecord,
            key:Date.now(),
            setData
        }

        newEditModalQueue.push(queueConfig)

        setEditModalQueue(newEditModalQueue)
    }

    //Remove from the modal queue
    const removeFromEditModalQueue = ()=>{

        let newEditModalQueue = editModalQueue.slice()

        let lastItem = newEditModalQueue[newEditModalQueue.length-1]

        newEditModalQueue.splice(-1,1)

        setEditModalQueue(newEditModalQueue)
    }

    const removeFromEditModalQueueAndAddAnother = ({model, parentRecord, parentModel, createFromFormModel, createFromFormRecord, onSuccess, editRecord})=>{
        let newEditModalQueue = editModalQueue.slice()

        newEditModalQueue.splice(-1,1)

        let queueConfig = {
            model,
            parentRecord,
            parentModel,
            createFromFormModel,
            createFromFormRecord,
            onSuccess,
            editRecord,
            key:Date.now()
        }

        newEditModalQueue.push(queueConfig)

        setEditModalQueue(newEditModalQueue)
    }


    //Run action
    const [currentAction, setCurrentAction] = useState(null)

    const runAction = ({model,action,inputs=new Set(),parentModelName,parentRecordId})=>{
        //only if there isn't already an action running
        if(currentAction === null) setCurrentAction({model, action, inputs, parentModelName, parentRecordId})
    }

    const completeAction = ()=>{
        setCurrentAction(null)
    }


    //Delete action
    const [currentDeletion, setCurrentDeletion] = useState(null)
    const runDeletion = ({model,id,onDelete})=>{
        //only if there isn't already an action running
        if(currentDeletion === null) setCurrentDeletion({model,id,onDelete})
    }

    const completeDeletion = ()=>{
        setCurrentDeletion(null)
    }

     //Bulk Delete action
     const [currentBulkDeletion, setCurrentBulkDeletion] = useState(null)
     const runBulkDeletion = ({model,inputs,onDelete})=>{
         //only if there isn't already an action running
         if(currentBulkDeletion === null) setCurrentBulkDeletion({model,inputs,onDelete})
     }
 
     const completeBulkDeletion = ()=>{
        setCurrentBulkDeletion(null)
     }

    //Merge action
    const [currentMerge, setCurrentMerge] = useState(null)
    const runMerge = ({model,record,onMerge})=>{
        //only if there isn't already an action running
        if(currentMerge === null) setCurrentMerge({model,record,onMerge})
    }

    const completeMerge = ()=>{
        setCurrentMerge(null)
    }



    //Refersh data key - used to reload all server data after PUT/POST/DELETE
    const [refreshDataKey, setRefreshDataKey] = useState(Date.now())

    const refreshData = ()=>setRefreshDataKey(Date.now())

    return (
        <UIContext.Provider value={{
            menuOpen,setMenuOpen,
            editModalQueue,
            addToEditModalQueue,
            removeFromEditModalQueue,
            removeFromEditModalQueueAndAddAnother,
            runAction,
            completeAction,
            currentAction,
            completeDeletion,
            runDeletion,
            currentDeletion,
            completeBulkDeletion,
            runBulkDeletion,
            currentBulkDeletion,
            completeMerge,
            runMerge,
            currentMerge,
            refreshDataKey,
            refreshData
        }} >
            {props.children}
        </UIContext.Provider>
    )

}