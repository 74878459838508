import { useState, useContext } from 'react'
import useFetch from './useFetch'
import { LoginContext } from "../contexts/LoginContext"
import { ConfigContext } from "../contexts/ConfigContext"

const useTransformValues = ({model, form, parentModel, parentRecord, setHiddenFields, originalRecord, createFromFormModel, createFromFormRecord})=>{

    const { 0: login } = useContext(LoginContext)
    const { 0: config } = useContext(ConfigContext)

    let newValues = {}
    let { runFetch } = useFetch()
    let [loading, setLoading] = useState(false)

    const processNewValues = async (newValues, fieldName)=>{
        //set the new form values adn validate
        for(let newValueKey of Object.keys(newValues.values)){
            let hasChanged = false
            if(form.getFieldValue(newValueKey) !== newValues.values[newValueKey]) hasChanged=true

            if(hasChanged){
                //set the value
                await form.setFieldValue(newValueKey, newValues.values[newValueKey])   
            }
        }


        //Hide Fields
        if(newValues.fieldsToHide){
            //set the editform hidden fields state
            setHiddenFields(newValues.fieldsToHide)
        }

        //revalidate fields... if this wasn't a transform on load
        if(newValues.fieldsToRevalidate && fieldName){
            newValues.fieldsToRevalidate.map(async fieldToRevalidate=>{
                //validate
                let fieldInstance = form.__getFieldInstances(fieldToRevalidate)
                
                //check field exists, we might be running transform before the field has been registered to the form?
                if (fieldInstance.length){
                    await fieldInstance[0].current.runValidation()
                    await fieldInstance[0].current.setMeta({isBlured:true, isTouched:true})
                }
            })
        }
    }

    const transform = async ({values, fieldName})=>{
        values = Object.assign({}, values)
        let newValues = {values:Object.assign({}, values), fieldsToHide:[], fieldsToRevalidate:[]}

        //convert any related lists to just IDs
        for(let key of Object.keys(values)){
            let value = values[key]

            if(Array.isArray(value)){
                if(value[0] && value[0].id){
                    //OBJECT
                    values[key] = value.map(d=>d.id)
                }
                //else mustt be an id already
            }
        }

        //run any client side form transformations
        if(model.clientFormValuesTransform){
            if((!fieldName && model.triggerClientSideFormValuesTransformationOnLoad) || 
                (model.triggerClientSideFormValuesTransformationFields && model.triggerClientSideFormValuesTransformationFields.indexOf(fieldName) > -1 )){

                let clientTransformResult = model.clientFormValuesTransform({
                    fieldName,
                    values,
                    originalRecord,
                    parentModelName:parentModel?parentModel.name:null,
                    parentRecord:parentRecord?parentRecord:null,
                    login,
                    createFromFormModelName:createFromFormModel?createFromFormModel.name:null,
                    createFromFormRecord:createFromFormRecord?createFromFormRecord:null,
                    config
                })

                newValues.values = clientTransformResult.values
                newValues.fieldsToHide = newValues.fieldsToHide.concat(clientTransformResult.fieldsToHide||[])
                newValues.fieldsToRevalidate = newValues.fieldsToRevalidate.concat(clientTransformResult.fieldsToRevalidate||[])

                await processNewValues(newValues, fieldName)
            }
        }

        //run any server side form transformations
        if(model.serverFormValuesTransform){
            if((!fieldName && model.triggerServerSideFormValuesTransformationOnLoad) || 
                (model.triggerServerSideFormValuesTransformationFields && model.triggerServerSideFormValuesTransformationFields.indexOf(fieldName) > -1 )){

                setLoading(true)

                let serverTransfromResult

                try {
                    serverTransfromResult = await runFetch(`/${model.name}/transform`, { method:'POST', body: JSON.stringify({ 
                        fieldName,
                        values: JSON.stringify(values),
                        originalRecord: JSON.stringify(originalRecord),
                        parentModelName: parentModel?parentModel.name:null,
                        parentId:parentRecord?parentRecord.id:null,
                        createFromFormModelName:createFromFormModel?createFromFormModel.name:null,
                        createFromFormRecord:createFromFormRecord?JSON.stringify(createFromFormRecord):null,
                    })})
                }
                catch (err) {
                    
                }
                
                //merge with any client side values
                if(serverTransfromResult){
                    newValues.values = serverTransfromResult.values
                    newValues.fieldsToHide = newValues.fieldsToHide.concat(serverTransfromResult.fieldsToHide||[])
                    newValues.fieldsToRevalidate = newValues.fieldsToRevalidate.concat(serverTransfromResult.fieldsToRevalidate||[])
                }
                
                await processNewValues(newValues, fieldName)

                setLoading(false)
            }
        }

        
        
    }

    return {transform,loading}
}

export default useTransformValues