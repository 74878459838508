import React, { useContext } from 'react';
import { UIContext } from '../../contexts/UIContext'
import { ConfigContext } from '../../contexts/ConfigContext'
import { LoginContext } from '../../contexts/LoginContext'
import { Link } from "react-router-dom"
import formatValue from '../../utils/formatFieldValue.js'
import { Card, Image, Dropdown, Icon, Segment, Checkbox, Popup, Button } from 'semantic-ui-react'

const OptionsCell = ({ record, model, relationship, parentModel, parentRecord }) => {
    const { 0: login } = useContext(LoginContext)
    const { 0: config } = useContext(ConfigContext)
    const { addToEditModalQueue, refreshDataKey, refreshData, runAction, runDeletion } = useContext(UIContext)

    //build delete buttons
    let deleteButtons = []

    //add the standard delete button
    if(
        (!config.globalClientPermission || config.globalClientPermission({ login, method:'DELETE', model:model.name }) === true)
        && (!relationship || relationship.deletable)
        && (!model.clientDeletePermission || model.clientDeletePermission({ login, currentRecord: record, config }) === true) 
    ){
        deleteButtons.push(<Popup
                key={`${model.name}_delete`}
                content={relationship&&relationship.deleteText?relationship.deleteText:`Delete ${model.niceName}`}
                position='top center'
                trigger={
                    <Button 
                        size='mini'
                        onClick={() => {
                            runDeletion({ model, id: record.id })
                        }}
                        icon
                    >
                        <Icon color='red' name='trash alternate' />
                    </Button>
                }
            />
        )
    }

    //add the alternative delete buttons
    if(relationship){
        Object.keys(relationship.editModels).map(editModelKey=>{
            let editModel = relationship.editModels[editModelKey]
            let model = config.models[editModel.model]
            let editRecord = editModel.editRecord({parentRecord, parentModel, record})

            //alt model delete button
            if(
                (!config.globalClientPermission || config.globalClientPermission({ login, method:'DELETE', model:model.name }) === true)
                && (editModel.deletable)
                && (!model.clientDeletePermission || model.clientDeletePermission({ login, currentRecord: editRecord, config }) === true) 
            ){
            
                deleteButtons.push(<Popup
                        key={`${model.name}_delete`}
                        content={editModel.deleteText?editModel.deleteText:`Delete ${model.niceName}`}
                        position='top center'
                        trigger={
                            <Button 
                                size='mini'
                                onClick={() => {
                                    runDeletion({ model, id: record.id })
                                }}
                                icon
                            >
                                <Icon color='red' name='trash alternate' />
                            </Button>
                        }
                    />
                )
            }
            
        })
    } 

    return <div collapsing>
        <Popup
            content={relationship && relationship.viewText ? relationship.viewText(record) : `View ${model.niceName}`}
            position='top center'
            trigger={
                <Button size='mini' as={Link} to={relationship ? relationship.linkTo(record) : model.linkTo(record)} icon>
                    <Icon name='search' />
                </Button>
            }
        />

        {!model.clientPutPermission || model.clientPutPermission({ login, currentRecord: record, config }) === true ?
            <Popup
                content={`Edit ${model.niceName}`}
                position='top center'
                trigger={
                    <Button size='mini' icon onClick={() => {
                        addToEditModalQueue({
                            model,
                            editRecord: record,
                            parentModel: parentModel,
                            parentRecord: parentRecord
                        })
                    }}>
                        <Icon name='pencil' />
                    </Button>
                }
            />
            : null}

        {model.actions ? Object.keys(model.actions).map(actionKey => {
                let action = model.actions[actionKey]

                let computedActionDisplay = typeof action.display === 'function'?action.display({ login, records: [record], config }):action.display
                //default to true
                if(computedActionDisplay !== false) computedActionDisplay = true

                if(!action.global && computedActionDisplay){

                return <Popup
                    key={actionKey}
                    content={action.niceName}
                    position='top center'
                    trigger={
                        <Button size='mini' icon
                            onClick={() => {
                                runAction({ model, action, inputs: new Set([record.id]), parentModelName:parentModel?.name, parentRecordId:parentRecord?.id })
                            }}
                            disabled={action.clientPermission && action.clientPermission({ login, records: [record], config }) !== true}
                        >
                            <Icon name={action.icon} color={action.colour||null} />
                        </Button>
                    }
                />
            }
        }) : null}


        {model.links ? model.links.map(link => {

            if(!link.global && (!link.display || link.display({ login, record, config }) === true)){
                return <Popup
                    key={link}
                    content={link.text(record)}
                    position='top center'
                    trigger={
                        <Button
                            size='mini'
                            as='a'
                            target='_blank'
                            href={link.to(record, config, login.token, login)}
                            icon
                        >
                            <Icon name={link.icon} />
                        </Button>
                    }
                />
            }

        }) : null}

        {deleteButtons}

    </div>
}

const Cards = ({
    tableColumnGroups,
    stats,
    setSortField,
    setSortDirection,
    sortDirection,
    records,
    selected,
    handleSelection,
    model,
    relationship,
    parentModel,
    parentRecord
}) => {

    const { 0: config } = useContext(ConfigContext)
    const { 0: login } = useContext(LoginContext)

    return <div className='cardHolder'>

        <Card.Group>
            {records.map((record, i) => {
                return <Card style={{ padding: 0, borderTop: `2px solid ${config.primaryColour}` }}>
                    <Card.Content>
                        {/* <Image
                            floated='right'
                            size='mini'
                            src='/images/avatar/large/steve.jpg'
                        /> */}
                        <Card.Header as={Link} to={`${model.name}/${record.id}`}>{model.referToByString(record)}</Card.Header>

                        {tableColumnGroups.map(group => {

                            return <div style={{ marginTop: 10 }}>
                                {group.tableColumns.length > 0 ?
                                    <div style={{ fontWeight: 'bold', backgroundColor: config.primaryColour, color: '#FFF', padding: '2px 5px', borderRadius: '3px 3px 0px 0px' }}>{group.niceName}</div>
                                    : null
                                }

                                <div style={{ padding: '5px 0px 0px 10px' }}>
                                    {group.tableColumns.map(field => {

                                        let style = {}
                                        if (field.conditionalStyle) style = field.conditionalStyle(record)

                                        //get the lookup model if this is a related list
                                        let lookupModel
                                        if (field.lookupModel) lookupModel = config.models[field.lookupModel]

                                        return <div key={field.apiName}>
                                            <b>{field.niceName}</b>:{' '}
                                            <span style={style}>{formatValue({ model, lookupModel, field, item: record, config, login })}</span>
                                        </div>
                                    })}
                                </div>
                            </div>
                        })}
                    </Card.Content>
                    <Card.Content extra>
                        <OptionsCell
                            record={record}
                            model={model}
                            relationship={relationship}
                            parentModel={parentModel}
                            parentRecord={parentRecord}
                        />
                    </Card.Content>
                </Card>
            })}
        </Card.Group>

        {/* <STable.Body>
                        {records.map((record, i) => {
                            return <STable.Row style={{ background: selected.has(record.id) ? '#CFC' : '' }} key={i}>
                                <STable.Cell collapsing>
                                    <Checkbox onClick={handleSelection(record.id)} checked={selected.has(record.id)} />
                                </STable.Cell>

                                {tableColumnGroups.map(group => {
                                    return group.tableColumns.map(field => {

                                        let style = {}
                                        if (field.conditionalStyle) style = field.conditionalStyle(record)

                                        //get the lookup model if this is a related list
                                        let lookupModel
                                        if (field.lookupModel) lookupModel = config.models[field.lookupModel]

                                        return <STable.Cell key={field.apiName} style={style}>
                                            {formatValue({ model, lookupModel, field, item: record, config, login })}
                                        </STable.Cell>
                                    })
                                })}
                            </STable.Row>
                        })}
                    </STable.Body> */}
    </div>
}

export default Cards