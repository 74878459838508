import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { ConfigContext } from '../../contexts/ConfigContext'

const InnerMenuItem = ({label, icon, iconOnly, active, menuOpen, to})=>{

    const { 0: config } = useContext(ConfigContext)

    return <div className={`menuItem ${active?'active':''} ${menuOpen?'menuOpen':'menuClosed'} ${to?'link':''}`} 
        style={{borderLeft:active?`4px solid ${config.primaryColour}`:null}}
    >
        <Icon name={icon} style={{color:active?`${config.primaryColour}`:null}}/>
        <span>{(label.length > 20) ? label.substr(0, 20-1) + '...' : label}</span>
    </div>
}


const MenuItem = ({ label, icon, to, iconOnly = false, active = false, menuOpen, children=[] }) => {

    const { 0: config } = useContext(ConfigContext)

    return <>
        {to?<Link to={to}><InnerMenuItem to={to} label={label} icon={icon} iconOnly={iconOnly} active={active} menuOpen={menuOpen}/></Link>:<InnerMenuItem label={label} icon={icon} iconOnly={iconOnly} active={active} menuOpen={menuOpen}/>}
        
        {children.map(childItem=>{
            return <Link key={childItem.to} to={childItem.to}>
                <div className={`childMenuItem ${childItem.active?'active':''} ${menuOpen?'menuOpen':'menuClosed'}`}
                    style={{borderLeft:childItem.active?`4px solid ${config.primaryColour}`:null}}
                >{childItem.label}</div>
            </Link>
        })}
    </>
}

export default MenuItem