import React, { useState } from 'react'

export const ConfigContext = React.createContext();

const evalFunctions = (obj) => {
    Object.keys(obj).forEach(key => {

        if (typeof obj[key] === 'object' && obj[key] !== null && obj[key] !== undefined) {
            evalFunctions(obj[key])
        }

        else if(typeof obj[key] == 'string' && (obj[key].indexOf('=>') > -1 || obj[key].indexOf('function') > -1)){
			obj[key] = eval(obj[key])
		}
    })

    return obj
}

export const ConfigProvider = (props) => {
    //is there a config in localStorage?
    let initialConfig = {models:{}}
    if(localStorage.config) initialConfig = evalFunctions(JSON.parse(localStorage.config))

    const [config, setConfig] = useState(initialConfig)

    return (
        <ConfigContext.Provider value={[config, setConfig]} >
            {props.children}
        </ConfigContext.Provider>
    )
}