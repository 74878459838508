import React, { useState, useEffect } from 'react'

export const LoginContext = React.createContext();

export const LoginProvider = (props) => {
    let currentLogin = window.localStorage.getItem('login')

    const [login, realSetLogin] = useState(currentLogin?JSON.parse(currentLogin):false)

    const logout = ()=>{
        window.localStorage.removeItem('login')
        realSetLogin(false)
    }

    const setLogin = (loginRes)=>{
        window.localStorage.setItem('login',JSON.stringify(loginRes))
        realSetLogin(loginRes)
    }

    useEffect(()=>{
        if(currentLogin){
            currentLogin = JSON.parse(currentLogin)

            let expired = window.Moment(currentLogin.tokenExpiry).isBefore(window.Moment())

            if(!expired) realSetLogin(currentLogin)
        }
    },[])

    return (
        <LoginContext.Provider value={[login, setLogin, logout]} >
            {props.children}
        </LoginContext.Provider>
    )

}