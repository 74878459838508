import React, {useState } from 'react'
import { useField, splitFormProps } from "react-form"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { Input, Label, Icon } from 'semantic-ui-react'
import Moment from 'moment-timezone'
import useTransformValues from '../../hooks/useTransformValues'

const DateField = (props) => {
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    let [loading, setLoading] = useState(false)

    // Use the useField hook with a field and field options
    // to access field state
    const {
        form,
        meta: { error, isTouched, isValidating, fieldConfig, model },
        getInputProps,
        value,
        setValue
    } = useField(field, fieldOptions);

    const handleChange = (e) => {

        const newValue = e.target.value
        
        // console.log(newValue)

        

        setValue(newValue?Moment(newValue).tz('Europe/London',true).toDate():null)

        // console.log(88, newValue?Moment(newValue).tz('Europe/London',true).toDate():null)

        if(props.transformValues){
            setLoading(true)
            props.transformValues({values:{...form.values, [field]:newValue}, fieldName:field})
            .then(()=>{
                setLoading(false)
            })
        }
    }
    
    let inputValue = value
    let datePickerValue = window.Moment(value).format('YYYY-MM-DD HH:mm')

    // //value might be a string (probably from database)
    // if(value){
    //     let valueAsMoment = Moment(value).tz('Europe/London')
    //     inputValue = valueAsMoment.format('DD/MM/YYYY HH:mm:ss')
    //     datePickerValue = Date.parse(valueAsMoment.format('DD MMM YYYY HH:mm:ss'))
        
    // }
    
    const handleOnBlur = ({target}) => {
        let date = Moment(target.value, 'DD/MM/YYYY')
        
        if(date.isValid()){
            setValue(date)
        }
        else {
            setValue(null)
        }
    };

    // const CustomInput = React.forwardRef(({ value, onClick }, refs) => (
    //     <Input 
    //         onBlur={handleOnBlur} 
    //         value={inputValue} 
    //         error={error && isTouched} 
    //         loading={isValidating||loading||props.formLoading} 
    //         onClick={onClick} 
    //         onKeyPress={(e)=>{
    //             if(e.key == 'Enter')e.preventDefault()
    //         }}
    //     />
    // ));

    // Build the field
    return (
        <>
            <div style={{float:'left', clear:'both'}}>
            <input
                    type="datetime-local"
                    value={datePickerValue}
                    onChange={handleChange}
                />{" "}
            </div>
            <div style={{clear:'both'}}>
                {isTouched && error ? (
                    <Label color='red' pointing='above'>
                        {error}
                    </Label>
                ) : null}
            </div>
        </>
    );
}

export default DateField