import { useContext } from 'react'
import { ErrorsContext } from '../contexts/ErrorsContext'
import { LoginContext } from '../contexts/LoginContext'

const useFetch = () => {

    const { 0: errors, 1: addError } = useContext(ErrorsContext)
    const { 0: login, 2:logout } = useContext(LoginContext)

    const defaultOptions = {
        queryString: {},
        method: 'GET',
        //credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Token': login.token
        }
    }

    const runFetch = async (url, options) => {

        const mergedOptions = Object.assign({}, defaultOptions, options)

        //if there's a q filter, base 64 it
        if (mergedOptions.queryString.q) mergedOptions.queryString.q = btoa(JSON.stringify(mergedOptions.queryString.q))

        let queryString = ''
        Object.keys(mergedOptions.queryString).map(key => {
            if (mergedOptions.queryString[key] !== undefined && mergedOptions.queryString[key] !== null)
                queryString += key + '=' + encodeURIComponent(mergedOptions.queryString[key]) + '&'
        })

        let fetchRes

        try {
            let host = `http://localhost:3333`
            if (process.env.NODE_ENV == 'production'){
                host = `https://thegenerator.herokuapp.com`

                //staging
                if(window.location.hostname.indexOf('staging.') === 0) host = `https://thegeneratorstaging.herokuapp.com`
            }

            if(window.location.hostname.indexOf('ngrok.io') > -1) host = `https://ngrokapi.xview.app.eu.ngrok.io`

            fetchRes = await fetch(`${host}${url}?${queryString}`, mergedOptions)

            let jsonRes = await fetchRes.json()

            //log out on 401 (not 403 as this means logged in but unauthorised to access the resource)
            if(login && (fetchRes.status === 401)) logout()

            //if fetching the config, don't show an error...
            
            else if(url.indexOf('client-config' == -1)){
                if (fetchRes.status === 429) throw new Error("You've hit the rate limit for your application")
                else if (fetchRes.status != 200) throw new Error(jsonRes.message)
            }

            return jsonRes
        }
        catch (err) {
            //if not config, show error
            if(url.indexOf('client-config') == -1){
                addError(err.message || "Could not communicate with server")
            }
        }


    }

    return { runFetch }
}

export default useFetch

