import React, { useEffect, useState, useContext, useCallback, useRef } from 'react'
import { Label, Grid, Table as STable, Menu, Dimmer, Loader, Input, Dropdown, Button, Icon, Segment, Checkbox, Popup } from 'semantic-ui-react'
import useFetch from '../../hooks/useFetch'
import { UIContext } from '../../contexts/UIContext'
import { LoginContext } from '../../contexts/LoginContext'
import { ConfigContext } from '../../contexts/ConfigContext'
import DatePicker from 'react-datepicker'
import FileUploader from '../../components/fileUploader'
import Table from '../../containers/table'
import Cards from '../../containers/cards'
import Calendar from '../../containers/calendar'
import Moment from 'moment'
import FiltersLookupField from '../../components/formFields/FiltersLookupField'
import FiltersRemoteSelectField from '../../components/formFields/FiltersRemoteSelectField'
import { useHistory } from "react-router-dom"
//https://www.npmjs.com/package/react-querybuilder#QueryBuilder
import QueryBuilder, { formatQuery } from 'react-querybuilder';


const defaultEmptyFilters = {
    combinator: 'and',
    rules: [],
    not: false
}

const CombinatorInput = ({ options, value, handleOnChange }) => {

    const onClick = value => () => {
        handleOnChange(value)
    }

    return <Button.Group size='mini' className='filtersCombinatorMargin'>
        {options.map((option, i) => {
            return <React.Fragment key={i}>
                <Button key={i} color={option.name === value ? 'green' : 'grey'} onClick={onClick(option.name)}>{option.label}</Button>
                {i + 1 !== options.length ? <Button.Or key={i+'or'} /> : null}
            </React.Fragment>
        })}
    </Button.Group>
}





const FilterSelect = ({ options, value, handleOnChange}) => {
    // {
    //     field: React.PropTypes.string, // Field name corresponding to this Rule
    //     fieldData: React.PropTypes.object, // The entire object from the fields array for this field
    //     options: React.PropTypes.array.isRequired, // Return value of getOperators(field)
    //     value: React.PropTypes.string, // Selected operator from the existing query representation, if any
    //     className: React.PropTypes.string, // CSS classNames to be applied
    //     handleOnChange: React.PropTypes.func, // Callback function to update query representation
    //     level: React.PropTypes.number // The level the group this rule belongs to
    //   }


    options = options.map(option => {
        return {
            key: option.name,
            text: option.label,
            value: option.name
        }
    })

    const onChange = (e, { value }) => {
        handleOnChange(value)
    }

    return <Dropdown search className='filtersRightMargin' selection options={options} value={value} onChange={onChange} />
}


const SearchAndFilters = ({ 
    saveSearchToLocalStorage, 
    saveFilterSelectionToLocalStorage, 
    defaultFilter, filterInput, model, parentModel, 
    onSearch, search, searchRef,
    applyFilters, setSelectedFilterPreset, currentTableFilters, setSortField, setSortDirection,
    relationship }) => {

    const { 0: login } = useContext(LoginContext)
    const history = useHistory()

    let [searchValue, setSearchValue] = useState()
    let [filters, setFilters] = useState(defaultFilter ? defaultFilter.query : defaultEmptyFilters)
    //custom (not a preset)
    let [customFilter, setCustomFilter] = useState(false)
    let [presetFilterValue, setPresetFilterValue] = useState(defaultFilter ? defaultFilter.name : 'all')
    let [showFilters, setShowFilters] = useState(false)
    let [searchDebounce, setSearchDebounce] = useState()

    const { 0: config } = useContext(ConfigContext)

    //set the default search value (from local storage)
    useEffect(()=>{
        if(search) setSearchValue(search)
    },[search])

    const searchChangeHandler = (e) => {

        let value = e.target.value

        setSearchValue(value)

        clearTimeout(searchDebounce)

        setSearchDebounce(setTimeout(() => {
            onSearch(value)
            saveSearchToLocalStorage(value)
        }, 300))
    }

    const filterChangeHandler = query => {
        setFilters(query)

        if (JSON.stringify(currentTableFilters) !== formatQuery(query, 'json_without_ids')) {
            setCustomFilter(true)
            setPresetFilterValue(null)
        }
    }

    const runFilters = () => {
        saveFilterSelectionToLocalStorage(filters)
        applyFilters(filters)
    }

    const fields = [];

    for (let fieldKey of Object.keys(model.fields)) {
        let field = model.fields[fieldKey]
        //if allowed to be filtered by...
        if (field.get) fields.push({ name: fieldKey, label: field.filterName||field.niceName })
    }

    let filterOptions = []
    let compiledFilterPresets = {}
    if (model.filterPresets) {

        //if the prsets are generated by a function, run it
        if(typeof model.filterPresets === 'function'){
            compiledFilterPresets = model.filterPresets({parentModel, login, relationship})
        }
        else compiledFilterPresets = model.filterPresets

        filterOptions = Object.keys(compiledFilterPresets).map((filterPresetKey, i) => {
            let filterPreset = compiledFilterPresets[filterPresetKey]

            return {
                key: i,
                text: filterPreset.niceName,
                value: filterPresetKey,
                content: filterPreset.niceName,
            }
        })
    }

    //if not disabled, add the show all filter to top of list
    if(model.filterPresetDisplayAll !== false) filterOptions = [{ key: 'all', text: `All ${model.niceNamePlural}`, value: 'all', content: `All ${model.niceNamePlural}` }].concat(filterOptions)
    
    //work out what filter we're starting on
    useEffect(()=>{
        let filterFound = false

        //check we're not displaying all 
        if(!defaultFilter || !defaultFilter.query || (defaultFilter.query && defaultFilter.query.rules.length == 0)){
            filterFound = true
            setPresetFilterValue('all')
        }

        else {
            for(let filterKey of Object.keys(compiledFilterPresets)){

                let filter = compiledFilterPresets[filterKey]

                //if the query is a function, run it
                if(typeof filter.query === 'function'){
                    filter.query = filter.query({login, relationship})
                }

                if(JSON.stringify(defaultFilter.query) == JSON.stringify(filter.query)){
                    setPresetFilterValue(filterKey)
                    filterFound = true
                    break
                }
            }
        }

        //mark as custom if none found
        if(!filterFound){
            setCustomFilter(true)
            // setShowFilters(true)
        }
    },[])

    const handleFilterChoice = (e, data) => {
        if (data.value == 'all') {
            setFilters({ "rules": [], "combinator": "and", "not": false })
            applyFilters({ "rules": [], "combinator": "and", "not": false })
            setSortField(model.defaultSortField)
            setSortDirection(model.defaultSortDirection)
            setCustomFilter(false)
            setPresetFilterValue('all')
            saveFilterSelectionToLocalStorage({ "rules": [], "combinator": "and", "not": false })
            return
        }

        //get the filter...
        let filter = compiledFilterPresets[data.value]

        setSelectedFilterPreset(filter)

        //if the query is a function, run it
        if(typeof filter.query === 'function'){
            filter.query = filter.query({login, relationship})
        }

        saveFilterSelectionToLocalStorage(filter.query,data.value)
        setFilters(filter.query)
        applyFilters(filter.query)
        setSortField(filter.sortField)
        setSortDirection(filter.sortDirection)
        setCustomFilter(false)
        setPresetFilterValue(data.value)

        if(!relationship) history.push(`/${model.name}/filter/${filter.name}`)
    }

    let filterHasChangedSinceLastRun = (formatQuery(currentTableFilters, 'json_without_ids') !== formatQuery(filters, 'json_without_ids'))

    return <Segment style={{ borderTop: `2px solid ${config.primaryColour}` }}>
        <Input ref={searchRef} icon='search' value={searchValue} className='filtersRightMargin' placeholder='Type to search...' onChange={searchChangeHandler} />
        <Popup
                content={`Clear Search`}
                position='top center'
                trigger={
                    <Button icon color='red' disabled={!search} onClick={()=>{
                        setSearchValue('')
                        onSearch('')
                        saveSearchToLocalStorage('')
                    }}>
                        <Icon name='delete' />
                    </Button>
                }
        />
        <Button style={{ position: 'relative', bottom: 1, marginLeft: 20 }} onClick={() => setShowFilters(!showFilters)}>
            <Icon name={`${showFilters ? 'up' : 'down'} arrow`} />
            {showFilters ? 'Hide' : 'Show'} Filters
        </Button>
        <div style={{ display: 'inline-block', marginLeft: 20 }}>
            Currently Showing: {' '}
            <Dropdown
                value={presetFilterValue}
                text={customFilter ? 'Custom' : null}
                inline
                header='Preset Filters'
                options={filterOptions}
                //defaultValue={'all'}
                onChange={handleFilterChoice}
            />
        </div>
        {showFilters ?
            <>
                <QueryBuilder
                    query={filters}
                    fields={fields}
                    controlElements={{
                        valueEditor: filterInput,
                        operatorSelector: FilterSelect,
                        fieldSelector: FilterSelect,
                        combinatorSelector: CombinatorInput,
                        removeRuleAction: ({ handleOnClick }) => <Button className='filtersRightMargin' icon='delete' color='red' onClick={handleOnClick} />,
                        removeGroupAction: ({ handleOnClick }) => <Button className='filtersLeftMargin' icon='delete' size='mini' color='red' onClick={handleOnClick} />,
                        addRuleAction: ({ handleOnClick }) => <Button size='mini' icon onClick={handleOnClick}><Icon name='plus' />Add Filter</Button>,
                        addGroupAction: ({ handleOnClick }) => <Button className='filtersLeftMargin' size='mini' icon onClick={handleOnClick}><Icon name='plus' />Add Filter Group</Button>,
                    }}
                    controlClassnames={{
                        ruleGroup: 'ruleGroup',
                        rule: 'rule',
                    }}
                    onQueryChange={filterChangeHandler}
                    getValueEditorType={(field, operator) => {
                        let { type } = model.fields[field]

                        if (operator === 'null' || operator === 'notNull') return 'null'

                        else if (type === 'date') return 'date'
                        else if (type === 'datetime') return 'datetime'
                        else if (type === 'enum') return 'enum'
                        else if (type === 'boolean') return 'boolean'
                        else if (type === 'integer' || type === 'float' || type === 'currency') return 'number'
                        else return 'text'
                    }}
                    // getInputType={(field, operator) => {
                    //     let { type } = model.fields[field]

                    //     if (type === 'integer' || type === 'float' || type === 'currency') return 'number' 
                    //     else return 'text'
                    // }}
                    getOperators={(field) => {
                        let { type, fieldType } = model.fields[field]

                        if (fieldType == 'remoteSelect' || fieldType == 'select' || fieldType == 'lookup' && type != 'directRelatedList' && type != 'relatedList') return [
                            { name: '$e', label: 'equals' },  
                            { name: '$ne', label: 'does not equal' },
                            { name: 'null', label: 'is not set' },
                            { name: 'notNull', label: 'is set' }
                        ]

                        else if (type === 'integer' || type === 'float' || type === 'currency' || type ==='duration') return [
                            { name: '$e', label: 'equals' },
                            { name: '$ne', label: 'does not equal' },
                            { name: '$gte', label: '>=' },
                            { name: '$gt', label: '>' },
                            { name: '$lte', label: '<=' },
                            { name: '$lt', label: '<' },
                            { name: 'null', label: 'is not set' },
                            { name: 'notNull', label: 'is set' }
                        ]

                        else if (type === 'date' || type === 'datetime') return [
                            { name: '$e', label: 'equals' },
                            { name: '$ne', label: 'does not equal' },
                            { name: '$gte', label: 'is greater than or equal to' },
                            { name: '$gt', label: 'is greater than' },
                            { name: '$lte', label: 'is less than or equal to' },
                            { name: '$lt', label: 'is less than' },
                            { name: 'null', label: 'is not set' },
                            { name: 'notNull', label: 'is set' }
                        ]

                        else if (type === 'boolean' || type === 'enum') return [
                            { name: '$e', label: 'equals' },
                            { name: '$ne', label: 'does not equal' },
                            { name: 'null', label: 'is not set' },
                            { name: 'notNull', label: 'is set' }
                        ]

                        else if (type === 'relatedList' || type === 'directRelatedList') return [
                            { name: '$e', label: 'includes' },  
                            { name: '$ne', label: 'does not include' }
                        ]

                        //assume sring
                        else return [
                            { name: '$e', label: 'equals' },
                            { name: '$ne', label: 'does not equal' },
                            { name: 'contains', label: 'contains' },
                            { name: 'doesNotContain', label: 'does not contain' },
                            { name: 'null', label: 'is not set' },
                            { name: 'notNull', label: 'is set' }
                        ]
                    }}
                    showCombinatorsBetweenRules={true}
                />
                <Button
                    style={{ marginTop: 14 }}
                    disabled={!filterHasChangedSinceLastRun}
                    onClick={runFilters}
                    color={filterHasChangedSinceLastRun?'green':null}
                >
                    Run Filters
                </Button>
                {filterHasChangedSinceLastRun?
                    <Label color={'red'} size={'small'}>Your filter has changed since it was last run, the resutls you are seeing might not be up to date.</Label>
                    :null
                }   
            </>
            : null}
    </Segment>
}


const ListViewComponentSelector = ({ model, parentModel, parentRecord, relationship=null, filterFromUrl=null }) => {

    const { addToEditModalQueue, refreshDataKey, refreshData, runAction, runDeletion, runBulkDeletion } = useContext(UIContext)
    const { 0: login } = useContext(LoginContext)
    const { 0: config } = useContext(ConfigContext)

    
    const searchRef = useRef('');

    //set some relationship defaults
    if(relationship){
        if(relationship.viewable !== false) relationship.viewable = true
        if(relationship.newable !== false) relationship.newable = true
        if(relationship.editable !== false) relationship.editable = true
        if(relationship.deletable !== false) relationship.deletable = true
        if(!relationship.editModels) relationship.editModels = []
    }


    //Save the last used filter!
    //if no defaultFilters Object in local storage, create one...
    if(!localStorage.defaultFilters) localStorage.defaultFilters = "{}"
    if(!localStorage.defaultPresetFilters) localStorage.defaultPresetFilters = "{}"
    let defaultPresetFilters = JSON.parse(localStorage.defaultPresetFilters)
    let defaultFilters = JSON.parse(localStorage.defaultFilters)

    //save the most recent filter in local storage
    const saveFilterSelectionToLocalStorage = (query, name)=>{
        defaultFilters[model.name+(parentModel?parentModel.name:'')] = JSON.parse(formatQuery(query, 'json_without_ids'))
        localStorage.defaultFilters = JSON.stringify(defaultFilters)
        
        if(name){
            defaultPresetFilters[model.name+(parentModel?parentModel.name:'')] = name
            localStorage.defaultPresetFilters = JSON.stringify(defaultPresetFilters)
        }
        //else remove filter preset
        else {
            defaultPresetFilters[model.name+(parentModel?parentModel.name:'')] = undefined
            localStorage.defaultPresetFilters = JSON.stringify(defaultPresetFilters)
        }
    }

    //Save the last used view!
    //if no defaultView Object in local storage, create one...
    if(!localStorage.defaultViews) localStorage.defaultViews = "{}"
    let defaultViews = JSON.parse(localStorage.defaultViews)
    let defaultView = defaultViews[model.name+(parentModel?parentModel.name:'')]

    //function save the most recent view in local storage
    const saveViewToLocalStorage = (view)=>{
        defaultViews[model.name+(parentModel?parentModel.name:'')] = view
        localStorage.defaultViews = JSON.stringify(defaultViews)
    }

    //Save the last used calendar date
    //if no defaultView Object in local storage, create one...
    if(!localStorage.defaultCalendarDates) localStorage.defaultCalendarDates = "{}"
    let defaultCalendarDates = JSON.parse(localStorage.defaultCalendarDates)
    let defaultCalendarDate = defaultCalendarDates[model.name+(parentModel?parentModel.name:'')]

    //function save the most recent date in local storage
    const saveCalendarDateToLocalStorage = (date)=>{
        defaultCalendarDates[model.name+(parentModel?parentModel.name:'')] = date
        localStorage.defaultCalendarDates = JSON.stringify(defaultCalendarDates)
    }


    //Save the last calendar view
    //if no defaultView Object in local storage, create one...
    if(!localStorage.defaultCalendarViews) localStorage.defaultCalendarViews = "{}"
    let defaultCalendarViews = JSON.parse(localStorage.defaultCalendarViews)
    let defaultCalendarView = defaultCalendarViews[model.name+(parentModel?parentModel.name:'')]

    //function save the most recent view in local storage
    const saveCalendarViewToLocalStorage = (date)=>{
        defaultCalendarViews[model.name+(parentModel?parentModel.name:'')] = date
        localStorage.defaultCalendarViews = JSON.stringify(defaultCalendarViews)
    }


    //Save the last used search!
    //if no defaultSearch Object in local storage, create one...
    if(!localStorage.defaultSearches) localStorage.defaultSearches = "{}"
    let defaultSearches = JSON.parse(localStorage.defaultSearches)
    let defaultSearch = defaultSearches[model.name+(parentModel?parentModel.name:'')]

    //function save the most recent tab in local storage
    const saveSearchToLocalStorage = (search)=>{
        defaultSearches[model.name+(parentModel?parentModel.name:'')] = search
        localStorage.defaultSearches = JSON.stringify(defaultSearches)
    }

    //Save the last used sort column!
    //if no defaultSortFields Object in local storage, create one...
    if(!localStorage.defaultSortFields) localStorage.defaultSortFields = "{}"
    let defaultSortFields = JSON.parse(localStorage.defaultSortFields)
    let defaultSortField = defaultSortFields[model.name+(parentModel?parentModel.name:'')]

    //function save the most recent tab in local storage
    const saveSortFieldToLocalStorage = (sortField)=>{
        defaultSortFields[model.name+(parentModel?parentModel.name:'')] = sortField
        localStorage.defaultSortFields = JSON.stringify(defaultSortFields)
    }

    //Save the last used sort direction!
    //if no defaultSortDirections Object in local storage, create one...
    if(!localStorage.defaultSortDirections) localStorage.defaultSortDirections = "{}"
    let defaultSortDirections = JSON.parse(localStorage.defaultSortDirections)
    let defaultSortDirection = defaultSortDirections[model.name+(parentModel?parentModel.name:'')]

    //function save the most recent tab in local storage
    const saveSortDirectionToLocalStorage = (sortDirection)=>{
        defaultSortDirections[model.name+(parentModel?parentModel.name:'')] = sortDirection
        localStorage.defaultSortDirections = JSON.stringify(defaultSortDirections)
    }



    const FilterInput = ({ field, value, handleOnChange, type }) => {
        // {
        //     field: React.PropTypes.string, // Field name corresponding to this Rule
        //     fieldData: React.PropTypes.object, // The entire object from the fields array for this field
        //     operator: React.PropTypes.string, // Operator name corresponding to this Rule
        //     value: React.PropTypes.string, // Value from the existing query representation, if any
        //     handleOnChange: React.PropTypes.func, // Callback function to update the query representation
        //     type: React.PropTypes.oneOf(['text', 'select', 'checkbox', 'radio']), // Type of editor to be displayed
        //     inputType: React.PropTypes.string, // Type of <input> if `type` is "text"
        //     values: React.PropTypes.arrayOf(React.PropTypes.object), //
        //     level: React.PropTypes.number, // The level the group this rule belongs to
        //     className: React.PropTypes.string, // CSS classNames to be applied
        //   }

        let fieldDefinition = model.fields[field]


        const onInputFieldChange = e => {
            let value = e.target.value

            handleOnChange(value)
        }

        const onInputFieldBlur = () => {
            if (type === 'number' && typeof value !== 'number') {
                handleOnChange(fixNumbers(value))
            }
        }

        const onSelectFieldChange = (e, { value }) => {
            handleOnChange(value)
        }

        const onDateFieldChange = (newValue) => {
            handleOnChange(Moment(newValue).format('YYYY-MM-DD'))
        }

        const onDateTimeFieldChange = (newValue) => {
            handleOnChange(newValue)
        }

        const onLookupFieldChange = (newValue) => {
            handleOnChange(newValue)
        }

        const fixNumbers = (numberToFix) => {
            //make sure number fields are numbers
            if (!numberToFix) numberToFix = 0
            else if (numberToFix.indexOf('.') > -1) numberToFix = parseFloat(value)
            else numberToFix = parseInt(numberToFix)
            return numberToFix
        }

        useEffect(() => {
            if (type === 'number' && typeof value !== 'number') {
                handleOnChange(fixNumbers(value))
            }
        }, [])

        //if no field required
        if (type === 'null') return null

        //if a date field...
        if (type === 'date') {
            const CustomInput = React.forwardRef(({ value, onClick }, refs) => (
                <Input value={value} onClick={onClick} className='filtersRightMargin'  />
            ))

            if(value){
                if(Moment.isMoment(value)){
                    value = value.toDate()
                }
                else{
                    value = Moment(value||null).toDate()
                }
            }
            else{
                value = null
            }

            return <DatePicker 
                //isClearable 
                dateFormat="dd/MM/yyyy" 
                selected={value} 
                onChange={onDateFieldChange} 
                customInput={<CustomInput />} 
            />
        }

        //if a datetime field...
        if (type === 'datetime') {
            const CustomInput = React.forwardRef(({ value, onClick }, refs) => (
                <Input value={value} onClick={onClick} className='filtersRightMargin' />
            ));

            if(value){
                if(Moment.isMoment(value)){
                    value = value.toDate()
                }
                else{
                    value = Moment(value||null).toDate()
                }
            }
            else{
                value = null
            }

            return <DatePicker 
                //isClearable 
                //dateFormat="dd/MM/yyyy hh:mm:ss"
                dateFormat="dd/MM/yyyy HH:mm:ss"
                showTimeSelect 
                selected={value}
                onChange={onDateTimeFieldChange} 
                timeIntervals={5}
                customInput={<CustomInput />}
            />
        }

        //if a bool field
        if (type === 'boolean') {
            return <Dropdown style={{verticalAlign:'bottom'}} selection value={value} className='filtersRightMargin' onChange={onSelectFieldChange} options={[{
                key: 'true',
                text: 'Yes',
                value: true
            }, {
                key: 'false',
                text: 'No',
                value: false
            }]} />
        }

        //if a we have defined valueOptions field
        if (fieldDefinition.valueOptions) {
            return <Dropdown style={{verticalAlign:'bottom'}} selection value={value} className='filtersRightMargin' onChange={onSelectFieldChange} options={fieldDefinition.valueOptions} />
        }

        //if we have a lookup or multiselect lookup
        if (fieldDefinition.fieldType == 'lookup' || fieldDefinition.fieldType == 'multiLookup') {
            return <FiltersLookupField value={value} onChange={onLookupFieldChange} field={fieldDefinition}/>
        }

        //if we have a remoteSelect or remoteMultiSelect
        if (fieldDefinition.fieldType == 'remoteMultiSelect' || fieldDefinition.fieldType == 'remoteSelect') {
            return <FiltersRemoteSelectField value={value} onChange={onLookupFieldChange} field={fieldDefinition}/>
        }


        else return <Input input={{ onBlur: onInputFieldBlur }} className='filtersRightMargin' value={value} type={type} onChange={onInputFieldChange} />
    }

    //do we have a default filter?
    let defaultFilter = {}

    let compiledFilterPresets
    //if the prsets are generated by a function, run it
    if(typeof model.filterPresets === 'function'){
        compiledFilterPresets = model.filterPresets({parentModel, login, relationship})
    }
    else compiledFilterPresets = model.filterPresets || []

    //if relationship and has specified a default filter
    if (relationship && relationship.defaultFilter) defaultFilter = compiledFilterPresets[relationship.defaultFilter]
    //else set the default defined in the filter presets
    else if (compiledFilterPresets) {
        for (let filterPresetKey of Object.keys(compiledFilterPresets)) {
            let filterPreset = compiledFilterPresets[filterPresetKey]

            //use the default filter
            if (filterPreset.default === true) {
                defaultFilter = filterPreset
            }
        }
    }

    //is there a filter in the url?
    if(filterFromUrl){
        if(compiledFilterPresets[filterFromUrl]){
            defaultFilter = compiledFilterPresets[filterFromUrl]
        }
        else {
            defaultFilter = {query:JSON.parse(atob(filterFromUrl))}
        }
        
    }

    //is there a filter preset in local storage?
    else if(JSON.parse(localStorage.defaultPresetFilters)[model.name+(parentModel?parentModel.name:'')]){
        defaultFilter = compiledFilterPresets[JSON.parse(localStorage.defaultPresetFilters)[model.name+(parentModel?parentModel.name:'')]]
    }
    //if there is a raw filter in Localstorage
    else if(JSON.parse(localStorage.defaultFilters)[model.name+(parentModel?parentModel.name:'')]){
        defaultFilter = {query:JSON.parse(localStorage.defaultFilters)[model.name+(parentModel?parentModel.name:'')]}
    }

    if(defaultFilter && typeof defaultFilter.query === 'function'){
        defaultFilter.query = defaultFilter.query({login, relationship})
    }

    if(!defaultSortField){
        if(defaultFilter && defaultFilter.sortField) defaultSortField = defaultFilter.sortField
        else defaultSortField = model.defaultSortField
    }

    if(defaultSortDirection === null || defaultSortDirection === undefined){
        if(defaultFilter && (defaultFilter.sortDirection === 1 || defaultFilter.sortDirection === 0)) defaultSortDirection = defaultFilter.sortDirection
        else defaultSortDirection = model.defaultSortDirection
    }

    let [page, setPage] = useState(1)
    let [sortDirection, setSortDirection] = useState(defaultSortDirection)
    let [sortField, setSortField] = useState(defaultSortField)
    let [layout, setLayout] = useState(defaultView || model.defaultLayout || 'table')
    let [search, setSearch] = useState(defaultSearch||'')
    let [filters, setFilters] = useState(defaultFilter ? defaultFilter.query : null)
    let [filterPreset, setFilterPreset] = useState(defaultFilter ? defaultFilter : null)
    let [selected, setSelected] = useState(new Set()) //array of selected ids
    let [response, setResponse] = useState({})
    let [loading, setLoading] = useState(false)
    let [localRefreshDataKey, setLocalRefreshDataKey] = useState('')    

    //default to current month (back to previous sunday, forward to next sat)
    let [calendarView, setCalendarView] = useState(defaultCalendarView)
    let [calendarViewFromDate, setCalendarViewFromDate] = useState(Moment(defaultCalendarDate).startOf('month').startOf('week'))
    let [calendarViewToDate, setCalendarViewToDate] = useState(Moment(defaultCalendarDate).endOf('month').endOf('week'))

    useEffect(()=>{
        //apply the default search from localstorage... if there is one
        if(defaultSearches[model.name+(parentModel?parentModel.name:'')]){
            setSearch(defaultSearches[model.name+(parentModel?parentModel.name:'')])
        }

        //apply the default view from localstorage... if there is one
        if(defaultViews[model.name+(parentModel?parentModel.name:'')]){
            setLayout(defaultViews[model.name+(parentModel?parentModel.name:'')])
        }
    },[])

    //set the localstorage sort if it's changed
    useEffect(()=>{
        saveSortFieldToLocalStorage(sortField)
        saveSortDirectionToLocalStorage(sortDirection)
    },[sortField,sortDirection])
    


    let apiPath = `/${model.name}`


    let { runFetch } = useFetch()

    useEffect(() => {
        setLoading(true)

        //filter for the relationship...
        let relationshipRule
        if (parentModel) {
            relationshipRule = { "field": relationship.foreignKeyAPIName, "operator": "$e", value: relationship.localKeyAPIName?parentRecord[relationship.localKeyAPIName]:parentRecord.id }
        }

        //filter for the calendarView...
        let calendarRule
        if (layout == 'calendar') {
            
            calendarRule = {
                "rules": [{ 
                    "field": model.calendarEndField,
                    "operator": "$gte", 
                    value: calendarViewFromDate
                },{ 
                    "field": model.calendarStartField,
                    "operator": "$lte", 
                    value: calendarViewToDate
                }],
                "combinator": "and",
            }

        }
            
        let fetchOptions = {
            queryString: {
                page,
                sortField,
                sortDirection,
                search: search,
                q: { "rules": [calendarRule, relationshipRule, filters], "combinator": "and", "not": false }
            }
        }

        runFetch(apiPath, fetchOptions).then(res => {
            console.log(123, )

            //if this is an error...
            if(res===undefined || res===null){
                //delete locally stored filters, etc so when the user refreshes, and pad sorting/filter/searching gets cleared
                let defaultSortDirections = JSON.parse(localStorage.defaultSortDirections)
                delete defaultSortDirections[model.name+(parentModel?parentModel.name:'')]
                localStorage.defaultSortDirections = JSON.stringify(defaultSortDirections)

                let defaultSortFields = JSON.parse(localStorage.defaultSortFields)
                delete defaultSortFields[model.name+(parentModel?parentModel.name:'')]
                localStorage.defaultSortFields = JSON.stringify(defaultSortFields)

                let defaultFilters = JSON.parse(localStorage.defaultFilters)
                delete defaultFilters[model.name+(parentModel?parentModel.name:'')]
                localStorage.defaultFilters = JSON.stringify(defaultFilters)

                let defaultSearches = JSON.parse(localStorage.defaultSearches)
                delete defaultSearches[model.name+(parentModel?parentModel.name:'')]
                localStorage.defaultSearches = JSON.stringify(defaultSearches)
            }

            //if search isn't the same as the most recent search typed in (don't jump to old results that took longer to load)
            let mostRecentSearch = searchRef.current ? searchRef.current.props.value : null
            if(!mostRecentSearch || res.stats.search==mostRecentSearch) setResponse(res)
            setLoading(false)
        })
    }, [page, sortDirection, sortField, search, filters, layout, refreshDataKey, localRefreshDataKey, calendarViewFromDate, calendarViewToDate])

    let records, stats
    if (!response || !response.records) records = []
    else records = response.records

    if (!response || !response.stats) stats = {}
    else stats = response.stats

    //get the table columns as specified by the model
    let tableColumnGroups = Object.keys(model.fieldGroups).map(group => {
        group = model.fieldGroups[group]
        let groupFields = []
        for (let field of Object.keys(model.fields)) {
            field = model.fields[field]
            //if field is not part of this group, or shouldn't be displayed
            if (
                group.name !== field.groupName ||
                field.tableColumn === false ||
                //if field omitted by relationship
                (relationship && relationship.omitFields && relationship.omitFields.indexOf(field.apiName) !== -1) ||
                //if field omitted by filter
                (filterPreset && filterPreset.omitFields && filterPreset.omitFields.indexOf(field.apiName) !== -1)
            ) continue;
            groupFields.push(field)
        }

        group.tableColumns = groupFields

        return group
    })

    const handleSelection = id => () => {
        if (selected.has(id)) {
            selected.delete(id)
            setSelected(new Set([...selected]))
        } else {
            setSelected(new Set(selected.add(id)))
        }
    }

    //have we selected every record on this page?
    let allSelected = true
    for (let record of records) {
        if (!selected.has(record.id)) allSelected = false
    }

    const handleSelectAll = () => {
        //if all already selected, remove all (on this page)
        if (allSelected) {
            for (let record of records) {
                if (selected.has(record.id)) selected.delete(record.id)
            }
        }
        //else add them all
        else {
            for (let record of records) {
                selected.add(record.id)
            }
        }

        setSelected(new Set(selected))
    }

    const handleAction = (e, data) => {
        //handle deselect action
        if (data.value == 'Deselect All') setSelected(new Set())
    }


    // //any selected items not in records?
    // let anySelectedRemoved = false
    // for(let selectedRecord of selected){
    //     let selectedIsInData = false

    //     for(let record of records){
    //         if(record.id == selectedRecord){
    //             selectedIsInData = true
    //             break;
    //         }
    //     }

    //     if(!selectedIsInData){
    //         selected.delete(selectedRecord)
    //         anySelectedRemoved = true
    //     }
    // }

    // if(anySelectedRemoved) setSelected(new Set(selected))

    //calculate queryString to get selected as CSV
    let csvQ = { "rules": [], "combinator": "or", "not": false }
    for(let s of selected){
        csvQ.rules.push({ "field": "id", "operator": "$e", value:s})
    }
    csvQ = btoa(JSON.stringify(csvQ))


    //permission to bulk delete?
    let permissionToBulkDelete = true
    for(let s of selected){
        
        let recordToTest = (records.filter(r=>r.id==s)[0])

        if((!config.globalClientPermission || config.globalClientPermission({ login, method:'DELETE', model:model.name }) === true)
        && (!relationship || relationship.deletable)
        && (!model.clientDeletePermission || model.clientDeletePermission({ login, currentRecord: recordToTest, config }) === true)){
            //console.log('permision')
        } 
        else {
            permissionToBulkDelete = false
            break
        }
    }


    let newButtons = []
    if(
        (!config.globalClientPermission || config.globalClientPermission({ login, method:'POST', model:model.name }) === true)
        && (!relationship || (relationship.newable!==false))
        && !model.fileHandler && (!model.clientPostPermission || model.clientPostPermission({ login, config }) === true) 
    )
    {
        newButtons.push(
            <Dropdown.Item
                onClick={() => {
                    addToEditModalQueue({
                        model,
                        //presetValues: relationship ? { [relationship.foreignKeyFieldName]: id } : {}
                        parentModel,
                        parentRecord
                    })
                }}
            >
                <Icon name='plus' color='green' />New {model.niceName}
            </Dropdown.Item>
        )
    }

    let linkButtons = []
    if(model.links){
        for(let linkKey of Object.keys(model.links)){
            let link = model.links[linkKey]
            if(link.global){
                linkButtons.push(<Dropdown.Item
                    key={link.to}
                    text={link.text(null)}
                    icon={link.icon}
                    as='a'
                    target='_blank'
                    href={link.to(null, config, login.token, login)}
                />)
            }
        }
    }

    let globalActionButtons = []
    if(model.actions){
        for(let actionKey of Object.keys(model.actions)){
            let action = model.actions[actionKey]

            let computedActionDisplay = typeof action.display === 'function'?action.display({ login, records: [], config }):action.display
            //default to true
            if(computedActionDisplay !== false) computedActionDisplay = true

            if(action.global == true && computedActionDisplay){
                globalActionButtons.push(<Dropdown.Item key={actionKey} onClick={() => { runAction({ model, action, parentModelName:parentModel?.name, parentRecordId:parentRecord?.id  }) }} disabled={action.clientPermission && action.clientPermission({ login, records: [], config }) !== true}>
                    <Icon color={action.colour||null} name={action.icon} />{action.niceName}
                </Dropdown.Item>)
            }
        }
    }

    let actionButtons = []
    if(model.actions){
        for(let actionKey of Object.keys(model.actions)){
            let action = model.actions[actionKey]

            let computedActionDisplay = typeof action.display === 'function'?action.display({ login, records: [], config }):action.display
            //default to true
            if(computedActionDisplay !== false) computedActionDisplay = true

            if(action.global !== true){
                if (action.minInputs <= selected.size && action.maxInputs >= selected.size && computedActionDisplay)
                    actionButtons.push(<Dropdown.Item key={actionKey} onClick={() => { runAction({ model, action, inputs: selected, parentModelName:parentModel?.name, parentRecordId:parentRecord?.id  }) }} disabled={action.clientPermission && action.clientPermission({ login, records: [], config }) !== true}>
                        <Icon color={action.colour||null} name={action.icon} />{action.niceName}
                    </Dropdown.Item>)
            }
        }
    }

    let otherGlobalButtons = []
    let otherButtons = []

    if(model.csvResultsPerPage && (!model.clientExportPermission || model.clientExportPermission({ login }) === true)){
        //filter for the relationship...
        let relationshipRule
        if (parentModel) {
            relationshipRule = { "field": relationship.foreignKeyAPIName, "operator": "$e", value: relationship.localKeyAPIName?parentRecord[relationship.localKeyAPIName]:parentRecord.id }
        }

        otherGlobalButtons.push(<Dropdown.Item key='csv' as='a' href={`http${config.env != 'development' ? 's' : ''}://${config.env=='staging'?'staging.':''}${config.apiHostName}${config.env == 'development'?'.local:3333':''}/${model.name}?q=${filters||relationshipRule?btoa(JSON.stringify({ "rules": [relationshipRule, filters], "combinator": "and", "not": false })):''}&search=${search}&csv=true&token=${login.token}`}>
            <Icon name='download' />Export all results ({model.csvResultsPerPage} Max.)
        </Dropdown.Item>)
    }

    if(selected.size && permissionToBulkDelete){
        otherButtons.push(<Dropdown.Item key='bulkDelete' onClick={() => { runBulkDeletion({ model, inputs: selected, onDelete:()=>{setSelected(new Set())} }) }}>
                    <Icon name='trash' />Bulk delete selected {model.niceNamePlural}
            </Dropdown.Item>)
    }

    if(!model.fileHandler && selected.size && (!model.clientExportPermission || model.clientExportPermission({ login }) === true)){
        otherButtons.push(<Dropdown.Item as='a' href={`http${config.env != 'development' ? 's' : ''}://${config.env=='staging'?'staging.':''}${config.apiHostName}${config.env == 'development'?'.local:3333':''}/${model.name}?q=${csvQ}&csv=true&token=${login.token}`}>
            <Icon name='download' />Export selected {model.niceNamePlural}
        </Dropdown.Item>)
    }

    //show select all?
    let exportSelected = false
    if(!model.fileHandler && (!model.clientExportPermission || model.clientExportPermission({ login }) === true)){
        exportSelected = true
    }

    const Paging = () => (<Segment style={{ borderTop: `2px solid ${config.primaryColour}` }}>
        <Grid>
            <Grid.Row>
                <Grid.Column verticalAlign='middle' width={4}>
                    <p>
                        {layout != 'calendar'?<b>Records {records.length ? ((stats.page - 1) * stats.perPage) + 1 : 0}-{records.length ? (((stats.page - 1) * stats.perPage) + records.length) : 0} of {stats.totalAvailable}</b>:null}
                        {selected.size ? <> ({selected.size} Selected)</> : null}
                    </p>
                </Grid.Column>
                <Grid.Column textAlign='right' width={12} floated='right' >
                    {
                        //standard new button
                        (!config.globalClientPermission || config.globalClientPermission({ login, method:'POST', model:model.name }) === true)
                        && (!relationship || (relationship.newable!==false))
                        && (!model.fileHandler && (!model.clientPostPermission || model.clientPostPermission({ login, config }) === true))
                        ?
                        <Button color='green'
                            onClick={() => {

                                let editModel = model

                                //if the relationship specifies a different new model...
                                if(relationship && relationship.editModel){
                                    editModel = config.models[relationship.editModel]
                                }

                                addToEditModalQueue({
                                    model:editModel,
                                    parentModel,
                                    parentRecord
                                })
                            }}
                        >
                            <Icon name='plus'/>{relationship && relationship.newText?relationship.newText:`New ${model.niceName}`}
                        </Button>
                        : null}
                    {
                        relationship?
                            Object.keys(relationship.editModels).map(editModelKey=>{
                                let editModel = relationship.editModels[editModelKey]
                                let model = config.models[editModel.model]

                                //alt model new button
                                return (!config.globalClientPermission || config.globalClientPermission({ login, method:'POST', model }) === true)
                                && (editModel.newable !== false)
                                && (!model.fileHandler && (!model.clientPostPermission || model.clientPostPermission({ login, config }) === true))
                                ?
                                <Button color='green'
                                    key={model.name}
                                    onClick={() => {

                                        addToEditModalQueue({
                                            model,
                                            parentModel,
                                            parentRecord
                                        })
                                    }}
                                >
                                    <Icon name='plus'/>{editModel.newText?editModel.newText:`New ${model.niceName}`}
                                </Button>
                                : null
                            })
                        :null
                    }

                    {newButtons.length || linkButtons.length || actionButtons.length || otherButtons.length || otherGlobalButtons.length?
                        <Dropdown
                            button
                            //disabled={!selected.size}
                            className='icon'
                            icon='down arrow'
                            labeled
                            onChange={handleAction}
                            text='Actions...'
                        >
                            <Dropdown.Menu direction='left'>
                                {newButtons}

                                {linkButtons}

                                {globalActionButtons}

                                {otherGlobalButtons}

                                {selected.size?<Dropdown.Header content={`With ${selected.size} selected items...`} />:null}
                                
                                {selected.size?actionButtons:null}
                                {selected.size?otherButtons:null}
    
                            </Dropdown.Menu>
                        </Dropdown>
                        :null
                    }

                    {actionButtons.length || exportSelected?
                        <Popup
                            content="Select/deselect all displayed items"
                            position='bottom center'
                            trigger={
                                <Button icon onClick={handleSelectAll}>
                                    <Icon name='check square outline' />
                                </Button>
                            }
                        />
                        :null
                    }

                    <Button.Group>
                        <Button icon onClick={() => {saveViewToLocalStorage('table'); setLayout('table')}} active={layout === 'table'}>
                            <Icon name='table' />
                        </Button>
                        <Button icon onClick={() => {saveViewToLocalStorage('cards'); setLayout('cards')}} active={layout === 'cards'}>
                            <Icon name='grid layout' />
                        </Button>
                        {model.calendar === true?
                            <Button icon onClick={() => {saveViewToLocalStorage('calendar'); setLayout('calendar')}} active={layout === 'calendar'}>
                                <Icon name='calendar' />
                            </Button>
                        :null}
                    </Button.Group>

                    {' '}

                    {records.length && layout != 'calendar' ?
                        <Button.Group>

                            {/* show first page if not in main page sweep */}
                            {stats.page > 3 ?
                                <Button as='a' key='start' onClick={() => { setPage(1) }} >1</Button>
                                : null}
                            {stats.page > 4 ?
                                <Button as='a' key='spacer1' disabled>...</Button>
                                : null}
                            {
                                [...Array(5)].map((v, i) => {
                                    let offset = 2 - i
                                    let page = stats.page - offset

                                    if (page < 1 || page > stats.maxPage) return null
                                    else return <Button key={i} as='a' onClick={() => { setPage(page) }} active={stats.page === page}>{page}</Button>
                                })
                            }
                            {stats.page < stats.maxPage - 3 ?
                                <Button as='a' key='spacer2' disabled>...</Button>
                                : null}
                            {stats.page < stats.maxPage - 2 ?
                                <Button as='a' key='end' onClick={() => { setPage(stats.maxPage) }} >{stats.maxPage}</Button>
                                : null}
                        </Button.Group>
                        : null}



                </Grid.Column>
            </Grid.Row>
        </Grid>

    </Segment>)

    return <>
        <SearchAndFilters 
            saveSearchToLocalStorage={saveSearchToLocalStorage} 
            saveFilterSelectionToLocalStorage={saveFilterSelectionToLocalStorage} 
            defaultFilter={defaultFilter} 
            setSortField={setSortField} 
            setSortDirection={setSortDirection} 
            filterInput={FilterInput} 
            filters={filters} 
            parentModel={parentModel} 
            model={model} 
            onSearch={setSearch} 
            search={search} 
            searchRef={searchRef}
            applyFilters={setFilters} 
            setSelectedFilterPreset={setFilterPreset} 
            currentTableFilters={filters} 
            relationship={relationship}
        />

        <Dimmer.Dimmable>

            <Dimmer active={loading} inverted>
                <Loader>Loading</Loader>
            </Dimmer>

            <Paging />

            {model.fileHandler === true && parentRecord && (!model.clientPostPermission || model.clientPostPermission({ login, config }) === true) ?
                <FileUploader model={model} parentModel={parentModel} parentRecord={parentRecord} onComplete={() => { setLocalRefreshDataKey(Date.now()) }} />
                : null}

            {layout == 'table' ?
                <Table
                    tableColumnGroups={tableColumnGroups}
                    stats={stats}
                    setSortField={setSortField}
                    setSortDirection={setSortDirection}
                    sortDirection={sortDirection}
                    records={records}
                    selected={selected}
                    handleSelection={handleSelection}
                    model={model}
                    relationship={relationship}
                    addToEditModalQueue={addToEditModalQueue}
                    parentModel={parentModel}
                    parentRecord={parentRecord}
                />
                : null}

            {layout == 'cards' ?
                <Cards
                    tableColumnGroups={tableColumnGroups}
                    stats={stats}
                    setSortField={setSortField}
                    setSortDirection={setSortDirection}
                    sortDirection={sortDirection}
                    records={records}
                    selected={selected}
                    handleSelection={handleSelection}
                    model={model}
                    relationship={relationship}
                    addToEditModalQueue={addToEditModalQueue}
                    parentModel={parentModel}
                    parentRecord={parentRecord}
                />
                : null}

            {layout == 'calendar' ?
                <Calendar
                    tableColumnGroups={tableColumnGroups}
                    stats={stats}
                    setSortField={setSortField}
                    setSortDirection={setSortDirection}
                    sortDirection={sortDirection}
                    records={records}
                    selected={selected}
                    handleSelection={handleSelection}
                    model={model}
                    view={calendarView}
                    relationship={relationship}
                    addToEditModalQueue={addToEditModalQueue}
                    parentModel={parentModel}
                    parentRecord={parentRecord}
                    setCalendarViewFromDate={setCalendarViewFromDate}
                    setCalendarViewToDate={setCalendarViewToDate}
                    setCalendarView={setCalendarView}
                    saveCalendarDateToLocalStorage={saveCalendarDateToLocalStorage}
                    defaultCalendarDate={defaultCalendarDate}
                    saveCalendarViewToLocalStorage={saveCalendarViewToLocalStorage}
                    defaultCalendarView={defaultCalendarView}
                />
                : null}

            {records.length > 20 ? <Paging /> : null}
        </Dimmer.Dimmable>
    </>
}

export default ListViewComponentSelector