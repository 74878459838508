import React, { useRef, useState } from 'react'
import { useField, splitFormProps } from "react-form"
import { Input, Label, TextArea, Icon } from 'semantic-ui-react'
import { useEffect } from 'react';
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";

let inputDebounce = null

const CodeAreaField = (props) => {
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    let [loading, setLoading] = useState(false)

    const editorRef = useRef(null);

    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor; 
    }


    // Use the useField hook with a field and field options
    // to access field state
    let {
        form,
        meta: { error, isTouched, isBlured, isValidating, fieldConfig, model },
        getInputProps,
        value,
        setValue,
        setMeta
    } = useField(field, fieldOptions);

    //prevent null or undefined being passed (creating and uncontrolled component)
    if (!value) value = ''


    let showError = ((isTouched && error && isBlured) || (isTouched && !isBlured && props.everSubmitted && error))

    const handleChange = (v,e) => {
        if (v == '') v = null

        if(inputDebounce) {
            clearTimeout(inputDebounce)
        }
        inputDebounce = setTimeout(()=>{      
            setValue(v)

            if(props.transformValues){
                setLoading(true)
                
                let valuesMixed = Object.assign({},form.values)
                valuesMixed[field] = v

                props.transformValues({values:valuesMixed, fieldName:field})
                .then(()=>{
                    setLoading(false)
                })
            }
        },300)
    }


    //update the local value with form value if changed
    useEffect(()=>{
        if(editorRef.current && editorRef.current.getValue().toString() != value.toString()){
            editorRef.current.setValue(value)
        }
    },[value])

    // Build the field
    return (
        <>
            <div style={{ float: 'left', width:'90%' }}>
            <Editor
                height="50vh"
                defaultLanguage="javascript"
                defaultValue={value}
                className='codearea'
                onChange={handleChange}
                onMount={handleEditorDidMount}
                // options={

                // }
            />
                {/* <TextArea disabled={props.formLoading} type={props.type || 'text'} onBlur={ onBlur } style={{ width: '100%', minHeight:200 }} onInput={handleChange} onKeyDown={handleKeyPress} value={localInputValue} />{" "} */}
            </div>
            {isValidating||loading ?
                <div style={{ float: 'left', width: 30, position:'relative', top:2, left:10 }}>
                    <Icon loading color='grey' name='circle notched' />
                </div>
                : null}
            <div>
                {showError ? (
                    <Label pointing='above' color='red'>
                        {error}
                    </Label>
                ) : null}
            </div>

        </>
    );
}

export default CodeAreaField