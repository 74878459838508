import React, { useState, useContext } from 'react'
import { useField, splitFormProps } from "react-form"
import { Popup, Input, Label, Search, Button, Icon } from 'semantic-ui-react'
import { ConfigContext } from "../../contexts/ConfigContext"
import useFetch from '../../hooks/useFetch'
import { UIContext } from '../../contexts/UIContext'
import { useEffect } from 'react'

let currentTimeout

const LookupField = (props) => {

    const { 0: config } = useContext(ConfigContext)
    const { addToEditModalQueue } = useContext(UIContext)
    const [searchInputValue, setSearchInputValue] = useState('')
    const [selectedArray, setSelectedArray] = useState([])

    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)


    const { runFetch } = useFetch()

    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    // Use the useField hook with a field and field options
    // to access field state
    let {
        form,
        meta: { error, isTouched, isValidating, isBlured, fieldConfig, model, parentModel, editRecord, parentRecord  },
        getInputProps,
        value,
        setValue,
        setMeta
    } = useField(field, fieldOptions);

    //compile the filter for the lookup
    const compiledFilter = fieldConfig.filterQuery?fieldConfig.filterQuery({values:form.values, parentModel, editRecord, parentRecord }):undefined


    //prevent null or undefined being passed (creating and uncontrolled component)
    if (!value) value = ''

    const lookupModel = config.models[fieldConfig.lookupModel]

    let lookupModelRelationshipForeignKey = ''
    for (let lookupModelRelationshipKey of Object.keys(lookupModel.relationships)) {
        if (lookupModel.relationships[lookupModelRelationshipKey].model == fieldConfig.relationship) {
            lookupModelRelationshipForeignKey = lookupModel.relationships[lookupModelRelationshipKey].foreignKeyAPIName
        }
    }

    //this first time we load, we might have an existing value to lookup the value for
    useEffect(() => {

        //if we have objects, just use them as is, no need to see if they've changed...
        if(value.length > 0 && value[0].id){
            setSelectedArray(value.map(record => ({ id: record.id, title: fieldConfig.lookupReferToByString?fieldConfig.lookupReferToByString(record):lookupModel.referToByString(record) })))
            return
        }

        //if we've got an array of id's we need to check if they've changed (vs the selectt values) and if so, look them up
        let different = false
        value.map((v,i)=>{
            if(!selectedArray[i] || v != selectedArray[i].id) different = true
        })

        
        //is the value different to the selectedArray?
        if(different) {
            let rules = value.map(val => {
                return {
                    field: 'id',
                    value: val,
                    operator: '$e',
                }
            })

            //build the query required to get the item and prepare for API
            let query = {
                rules,
                combinator: 'or'
            }

            let trimmedFormValuesForServerSideFilterQuery = {}
            //remove long fields from the serverside filter query, too long for get
            //AND if array of objects, just shrink down to list of IDs
            for(let f of Object.keys(form.values)){
                let v = form.values[f]
                if(typeof v == "string" && v.length>100){
                    //do nothing
                }
                //if array and has an object with id param
                else if(Array.isArray(v) && v[0]?.id){
                    trimmedFormValuesForServerSideFilterQuery[f] = v.map(d=>d.id)
                }
                else trimmedFormValuesForServerSideFilterQuery[f] = v
            }

            let fetchOptions = {
                queryString: {
                    q: query,
                    relatedRecords:false,
                    filterQuery: fieldConfig.serverFilterQuery?JSON.stringify({field, model:model.name, recordId: editRecord?.id, values:trimmedFormValuesForServerSideFilterQuery, parentModel:parentModel?parentModel.name:null, parentRecordId:parentRecord?parentRecord.id:null}):undefined,
                    perPage:lookupModel.maxResultsPerPage||lookupModel.resultsPerPage,
                },
            }

            runFetch(`/${fieldConfig.lookupModel}`, fetchOptions)
                .then(response => {
                    if (response && response.records && response.records.length) {
                        //defaultRecord = defaultRecord.records[0]
                        //setSearchInputValue(lookupModel.referToByString(defaultRecord))
                        setSelectedArray(response.records.map(record => ({ id: record.id, title: fieldConfig.lookupReferToByString?fieldConfig.lookupReferToByString(record):lookupModel.referToByString(record) })))
                        //setValue(response.records.map(record => record.id))
                    }
                })

        }
        
    }, [value])

    //has the filter changed?
    useEffect(()=>{
        //do we actually have a filter at all and somem values to check?
        if(value?.length && fieldConfig.filterQuery){
            reFilter()
        }
    },[JSON.stringify(compiledFilter)])

    const reFilter = async ()=>{
        //compile the filter for the lookup
        let compiledFilter = fieldConfig.filterQuery?fieldConfig.filterQuery({values:form.values, parentModel, editRecord, parentRecord }):undefined

        //is our current selection still valid?
        //add the selected id into the compiledFilter
        let filterWithSelectedId = {
            rules: [
                compiledFilter,
                {
                    rules:value.map(val=>({
                        field: 'id',
                        value: val.id||val,
                        operator: '$e',
                    })),
                    combinator: "or"
                }
            ],
            combinator: "and"
        }

        let trimmedFormValuesForServerSideFilterQuery = {}
        //remove long fields from the serverside filter query, too long for get
        //AND if array of objects, just shrink down to list of IDs
        for(let f of Object.keys(form.values)){
            let v = form.values[f]
            if(typeof v == "string" && v.length>100){
                //do nothing
            }
            //if array and has an object with id param
            else if(Array.isArray(v) && v[0]?.id){
                trimmedFormValuesForServerSideFilterQuery[f] = v.map(d=>d.id)
            }
            else trimmedFormValuesForServerSideFilterQuery[f] = v
        }
        
        //query the lookup with the current value to check it's still valid
        runFetch(`/${fieldConfig.lookupModel}`, {
            queryString:{
                q:filterWithSelectedId,
                relatedRecords:false,
                filterQuery: fieldConfig.serverFilterQuery?JSON.stringify({field, model:model.name, recordId: editRecord?.id, values:trimmedFormValuesForServerSideFilterQuery, parentModel:parentModel?parentModel.name:null, parentRecordId:parentRecord?parentRecord.id:null}):undefined,
                perPage:lookupModel.maxResultsPerPage||lookupModel.resultsPerPage,
            }
        })
        .then(res=>{
            //map through the records and remove any that are now removed
            
            let newValue = []
            let newSelectedArray = []

            for(let val of value){
                for(let record of res.records){
                    if(record.id == val){
                        newValue.push(val)
                        newSelectedArray.push({id:val, title:fieldConfig.lookupReferToByString?fieldConfig.lookupReferToByString(record):lookupModel.referToByString(record)})
                        break
                    }
                }
            }

            setSelectedArray(newSelectedArray)
            //console.log(333, newValue)
            setValue(newValue)
            
        })
    }


    const addToSelection = async result => {
        let newSelection = selectedArray.slice()

        let alreadySelected = false
        for (let selectedResult of newSelection) {
            if (selectedResult.id === result.id) {
                alreadySelected = true
                break;
            }
        }
        if (!alreadySelected) {
            newSelection.push(result)
            setSelectedArray(newSelection)

            let idArray = newSelection.map(selectedResult => selectedResult.id)
            await setValue(idArray)

            if (props.transformValues) {
                setLoading(true)
                props.transformValues({values:{ ...form.values, [field]: idArray }, fieldName:field})
                    .then(() => {
                        setLoading(false)
                    })
            }

        }
    }

    const removeFromSelection = async result => {
        let newSelection = selectedArray.slice()

        newSelection = newSelection.filter(selectedResult => {
            if (selectedResult.id == result.id) return false
            else return true
        })

        setSelectedArray(newSelection)

        let idArray = newSelection.map(selectedResult => selectedResult.id)

        await setValue(idArray)

        if (props.transformValues) {
            setLoading(true)
            props.transformValues({values:{ ...form.values, [field]: idArray }, fieldName:field})
                .then(() => {
                    setLoading(false)
                })
        }
    }

    const onSearchChange = async (e) => {

        if (e.target.value.length < 2) setResults([])

        let searchValue = e.target.value
        setSearchInputValue(searchValue)

        const runServerSearch = async () => {
            setLoading(true)
            let responseResults = []

            let trimmedFormValuesForServerSideFilterQuery = {}
            //remove long fields from the serverside filter query, too long for get
            //AND if array of objects, just shrink down to list of IDs
            for(let f of Object.keys(form.values)){
                let v = form.values[f]
                if(typeof v == "string" && v.length>100){
                    //do nothing
                }
                //if array and has an object with id param
                else if(Array.isArray(v) && v[0]?.id){
                    trimmedFormValuesForServerSideFilterQuery[f] = v.map(d=>d.id)
                }
                else trimmedFormValuesForServerSideFilterQuery[f] = v
            }

            try {
                let fetchRes = await runFetch(`/${fieldConfig.lookupModel}`, 
                    {
                        queryString:{ 
                            search: searchValue,
                            q: compiledFilter,
                            relatedRecords:false,
                            filterQuery: fieldConfig.serverFilterQuery?JSON.stringify({field, model:model.name, recordId: editRecord?.id, values:trimmedFormValuesForServerSideFilterQuery, parentModel:parentModel?parentModel.name:null, parentRecordId:parentRecord?parentRecord.id:null}):undefined,
                            perPage:lookupModel.maxResultsPerPage||lookupModel.resultsPerPage,
                        }
                    }
                )

                if (fetchRes.records.length) {
                    responseResults = fetchRes.records.map(record => {
                        return { id: record.id, title: fieldConfig.lookupReferToByString?fieldConfig.lookupReferToByString(record):lookupModel.referToByString(record) }
                    })
                }
            }
            catch (error) {

            }
            setLoading(false)

            setResults(responseResults)
        }

        //debounce
        clearTimeout(currentTimeout)
        currentTimeout = setTimeout(() => {
            runServerSearch(searchValue)
        }, 300)
    }

    const onResultSelect = (e, data) => {
        addToSelection(data.result)

        setSearchInputValue('')
    }

    const onBlur = () => {
        setMeta(oldMeta => {
            let newMeta = Object.assign({}, oldMeta)
            newMeta.isBlured = true
            return newMeta
        })
    }

    const handleCreateNew = async (e) => {
        e.preventDefault()

        addToEditModalQueue({
            model: lookupModel,
            createFromFormModel: model,
            createFromFormRecord: form.values,
            onSuccess: (newObject) => {
                addToSelection({ id: newObject.id, title: fieldConfig.lookupReferToByString?fieldConfig.lookupReferToByString(newObject):lookupModel.referToByString(newObject) })
            }
        })
    }

    let showError = (isTouched && error)

    // Build the field
    return (
        <>
            <div style={{ float: 'left' }}>
                <Search
                    value={searchInputValue}
                    placeholder={`Select ${fieldConfig.niceName}`}
                    onSearchChange={onSearchChange}
                    onResultSelect={onResultSelect}
                    //noResultsMessage='No Results...'
                    showNoResults={false}
                    loading={loading || isValidating || props.formLoading}
                    disabled={props.formLoading}
                    results={results}
                    onBlur={onBlur}
                    input={{ style: { width: 400 }, icon: 'search', iconPosition: 'left' }}
                />
            </div>
            
            {fieldConfig.lookupAddNew === true?
                <div style={{ float: 'left' }}>
                    <Popup
                        content={`Can't find what you're looking for? Create a new ${lookupModel.niceName}...`}
                        position='top center'
                        trigger={
                            <Button style={{ position: 'relative', top: 4, left: 10 }} size='mini' color='green' icon onClick={handleCreateNew}>
                                <Icon name='plus' />
                            </Button>
                        }
                    />
                </div>
            :null}

            <div style={{ clear: 'both', paddingTop: 5 }}>
                {selectedArray.map(selectedResult =>
                    <Label key={selectedResult.id} style={{ marginRight: 5, marginBottom: 5, marginLeft: 0 }}>
                        {selectedResult.title}
                        <Icon name='delete' onClick={() => removeFromSelection(selectedResult)} />
                    </Label>
                )}
            </div>
            <div style={{ clear: 'both' }}>
                {showError ? (
                    <Label pointing='above' color='red'>
                        {error}
                    </Label>
                ) : null}
            </div>
        </>
    );
}

export default LookupField